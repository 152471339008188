import React from 'react';
import { Button, Form, Input, Modal, Collapse, Popconfirm, Dropdown, Menu, Tooltip } from 'antd';
import { useAtom } from 'jotai';
import { BsPlusCircle } from 'react-icons/bs';
import { GiBoltSpellCast } from 'react-icons/gi';

import {
  userAtom,
  selectedCampaignAtom,
  characterAtom,
  characterSpellsAtom,
  dmSelectedCharacterAtom,
} from '../../utils/atoms';
import { sendChat, rollAttack } from '../../utils/db';
import { formatSpellLevel, withSign, getSpellAttackBonus, getSpellSaveDC } from '../../utils/utils';
import { sortList, SPELL_SORT_OPTIONS } from '../../utils/sort';
import SortMenu from '../../components/SortMenu';
import './CharacterSpells.css';

const CharacterSpells = ({ rtdb }) => {
  const [user] = useAtom(userAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [character] = useAtom(characterAtom);
  const [spells] = useAtom(characterSpellsAtom);
  const [spellForm] = Form.useForm();
  const [spellModal, setSpellModal] = React.useState(null);
  const [sort, setSort] = React.useState(SPELL_SORT_OPTIONS[1]);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const selectedCharacter = dmSelectedCharacter || user.selectedCharacter;

  const onSubmit = (values) => {
    if (spellModal === 'new') {
      rtdb
        .ref(`character_spells/${selectedCharacter}/${spells.length}`)
        .set(values)
        .then(() => {
          spellForm.resetFields();
          setSpellModal(null);
        });
    } else if (spellModal < spells.length) {
      rtdb
        .ref(`character_spells/${selectedCharacter}/${spellModal}`)
        .set(values)
        .then(() => {
          spellForm.resetFields();
          setSpellModal(null);
        });
    }
  };

  const onCastSpell = (e, spell) => {
    e.stopPropagation();
    sendChat(rtdb, campaign, character, {
      type: 'spell',
      spell,
    });
  };

  const onEdit = (i) => {
    spellForm.setFieldsValue(spells[i]);
    setSpellModal(i);
  };

  const onDelete = (i) => {
    rtdb.ref(`character_spells/${selectedCharacter}/${i}`).remove();
  };

  const renderSpellStats = () => {
    const abilities = [
      { label: 'Strength', name: 'str' },
      { label: 'Dexterity', name: 'dex' },
      { label: 'Constitution', name: 'con' },
      { label: 'Intelligence', name: 'int' },
      { label: 'Wisdom', name: 'wis' },
      { label: 'Charisma', name: 'cha' },
    ];
    const spellAttackBonus = withSign(getSpellAttackBonus(character));
    return (
      <div className="SpellStats">
        <div className="SpellCastingAbility">
          <div className="Label">Spell casting ability:</div>
          <Dropdown
            overlay={
              <Menu>
                {abilities.map((o) => (
                  <Menu.Item
                    key={o.name}
                    onClick={() => {
                      if (character.spellCastingAbility !== o.name) {
                        rtdb.ref(`characters/${selectedCharacter}/spellCastingAbility`).set(o.name);
                      }
                    }}
                  >
                    {o.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button type="link">
              {character.spellCastingAbility
                ? abilities.find((a) => a.name === character.spellCastingAbility).label
                : 'Select'}
            </Button>
          </Dropdown>
        </div>
        <div className="SpellAttackBonus">
          <div className="Label">Spell attack bonus:</div>
          <Tooltip title="Roll" placement="bottom">
            <Button
              type="link"
              className="Value"
              onClick={() =>
                rollAttack(rtdb, campaign, character, { attackName: 'Spell attack', hitBonus: spellAttackBonus })
              }
            >
              {spellAttackBonus}
            </Button>
          </Tooltip>
        </div>
        <div className="SpellSaveDC">
          <div className="Label">Spell save DC:</div>
          <div className="Value">{getSpellSaveDC(character)}</div>
        </div>
      </div>
    );
  };

  const renderSpellsList = () => {
    const sorted = sortList(spells, sort);
    const getIndex = (spell) => spells.indexOf(spell);
    return (
      <Collapse>
        {sorted.map(
          (spell, i) =>
            spell && (
              <Collapse.Panel
                header={
                  <div className="SpellHeader">
                    <div className="SpellName">{spell.spellName}</div>
                    <div className="SpellLevel">{formatSpellLevel(spell.level)}</div>
                    <div className="SpellRange">{spell.range}</div>
                    <div className="SpellCastTime">{spell.castTime}</div>
                    <Button icon={<GiBoltSpellCast />} onClick={(e) => onCastSpell(e, spell)}>
                      Cast spell
                    </Button>
                  </div>
                }
                key={i}
              >
                <div className="SpellContent">
                  {spell.duration && <div className="SpellDuration">Duration: {spell.duration}</div>}
                  {spell.requirements && <div className="SpellRequirements">Requirements: {spell.requirements}</div>}
                  {spell.description && <div className="SpellDescription">{spell.description}</div>}
                  <Button type="link" onClick={() => onEdit(getIndex(spell))}>
                    Edit
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this spell?"
                    onConfirm={() => onDelete(getIndex(spell))}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <Button type="link">Delete</Button>
                  </Popconfirm>
                </div>
              </Collapse.Panel>
            )
        )}
      </Collapse>
    );
  };

  const renderSpellModal = () => {
    return (
      <Modal
        title={spellModal === 'new' ? 'New spell' : 'Edit spell'}
        footer={null}
        centered
        visible={spellModal !== null}
        onCancel={() => {
          spellForm.resetFields();
          setSpellModal(null);
        }}
      >
        <div className="SpellModalContent">
          <Form name="spell" form={spellForm} onFinish={onSubmit}>
            <Form.Item
              label="Spell name"
              name="spellName"
              initialValue=""
              rules={[{ required: true, message: 'Enter a name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Spell level"
              name="level"
              initialValue=""
              rules={[{ required: true, message: 'Enter a level' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Range"
              name="range"
              initialValue=""
              rules={[{ required: true, message: 'Enter a range' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Casting time"
              name="castTime"
              initialValue=""
              rules={[{ required: true, message: 'Enter a casting time' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Duration" name="duration" initialValue="">
              <Input />
            </Form.Item>
            <Form.Item label="Requirements" name="requirements" initialValue="">
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" initialValue="">
              <Input.TextArea rows={8} />
            </Form.Item>
            <Form.Item>
              <div className="ModalButtonWrapper">
                <Button size="large" htmlType="submit">
                  {spellModal === 'new' ? 'Create' : 'Save'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };

  return (
    <div className="Spells">
      {renderSpellStats()}
      <SortMenu options={SPELL_SORT_OPTIONS} sort={sort} setSort={setSort} />
      <div className="SpellsList">
        {spells.length ? renderSpellsList() : <div className="Empty">You don't have any spells yet</div>}
      </div>
      <div className="ButtonWrapper">
        <Button
          type="link"
          size="large"
          className="NewButton"
          icon={<BsPlusCircle />}
          onClick={() => setSpellModal('new')}
        >
          New spell
        </Button>
      </div>
      {renderSpellModal()}
    </div>
  );
};

export default CharacterSpells;
