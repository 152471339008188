import { Form, Checkbox, Button, Badge } from 'antd';
import { useAtom } from 'jotai';

import {
  withSign,
  getAbilityBonus,
  getProficiencyBonus,
  parseLevel,
  getStatWithModifier,
  getStatModifiers,
} from '../../utils/utils';
import { sendChat } from '../../utils/db';
import { parseDice, rollDice } from '../../utils/dice';
import { selectedCampaignAtom, characterAtom } from '../../utils/atoms';
import './CharacterSkills.css';

const CharacterSkills = ({ rtdb, saveField }) => {
  const [campaign] = useAtom(selectedCampaignAtom);
  const [character] = useAtom(characterAtom);

  const onRollStat = (bonus, stat) => {
    const dice = parseDice(`1d20 ${bonus === 0 ? '' : bonus}`);
    const result = rollDice(dice);
    sendChat(rtdb, campaign, character, {
      type: 'stat',
      stat,
      result,
      bonus: withSign(bonus),
    });
  };

  const getBonus = (ability, proficient) => {
    const stat = character[ability] || 0;
    const statWithModifiers = getStatWithModifier(character, ability, stat);
    let bonus = getStatWithModifier(character, `${ability}Bonus`, getAbilityBonus(statWithModifiers));
    if (proficient) bonus += getProficiencyBonus(parseLevel(character.classLevel));
    return bonus;
  };

  const renderSkill = (label, name, stat) => {
    const key = `${name}Proficient`;
    const modifierName = name.endsWith('Save') ? `${name}Bonus` : name;
    const bonus = getBonus(stat, character[key]);
    const bonusWithModifiers = getStatWithModifier(character, modifierName, bonus);
    const modifiers = getStatModifiers(character, modifierName);

    return (
      <div className="SkillRow">
        <Form.Item name={key} valuePropName="checked">
          <Checkbox onClick={(e) => saveField(key, e.target.checked)} />
        </Form.Item>
        <div className="Bonus">{withSign(bonusWithModifiers)}</div>
        <Badge
          count={modifiers ? modifiers.length : 0}
          title="Bonus is modified by a stat effect"
          color="#108ee9"
          dot
          offset={[6, 8]}
        >
          <div className="Label">
            <Button className="RollStatButton" onClick={() => onRollStat(bonusWithModifiers, label)}>
              {label}
            </Button>
          </div>
        </Badge>
      </div>
    );
  };

  return (
    <div className="SkillsAndSaves">
      <div className="SavingThrows">
        <div className="Heading">Saving Throws</div>
        {renderSkill('Strength save', 'strSave', 'str')}
        {renderSkill('Dexterity save', 'dexSave', 'dex')}
        {renderSkill('Constitution save', 'conSave', 'con')}
        {renderSkill('Intelligence save', 'intSave', 'int')}
        {renderSkill('Wisdom save', 'wisSave', 'wis')}
        {renderSkill('Charisma save', 'chaSave', 'cha')}
      </div>
      <div className="Skills">
        <div className="Heading">Skills</div>
        <div className="SkillsSection">
          {renderSkill('Acrobatics (dex)', 'acrobatics', 'dex')}
          {renderSkill('Animal Handling (wis)', 'animal', 'wis')}
          {renderSkill('Arcana (int)', 'arcana', 'int')}
          {renderSkill('Athletics (str)', 'athletics', 'str')}
          {renderSkill('Deception (cha)', 'deception', 'cha')}
          {renderSkill('History (int)', 'history', 'int')}
          {renderSkill('Insight (wis)', 'insight', 'wis')}
          {renderSkill('Intimidation (cha)', 'intimidation', 'cha')}
          {renderSkill('Investigation (int)', 'investigation', 'int')}
          {renderSkill('Medicine (wis)', 'medicine', 'wis')}
          {renderSkill('Nature (int)', 'nature', 'int')}
          {renderSkill('Perception (wis)', 'perception', 'wis')}
          {renderSkill('Performance (cha)', 'performance', 'cha')}
          {renderSkill('Persuasion (cha)', 'persuasion', 'cha')}
          {renderSkill('Religion (int)', 'religion', 'int')}
          {renderSkill('Sleight of hand (dex)', 'sleight', 'dex')}
          {renderSkill('Stealth (dex)', 'stealth', 'dex')}
          {renderSkill('Survival (wis)', 'survival', 'wis')}
        </div>
      </div>
    </div>
  );
};

export default CharacterSkills;
