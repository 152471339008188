import { isEmpty } from 'lodash';
import moment from 'moment';
import { notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { parseDice, rollDice } from './dice';

export const sendChat = (rtdb, campaign, character, content = {}) => {
  if (isEmpty(content)) return;

  rtdb.ref(`chats/${campaign}/messages/${uuidv4()}`).set({
    sender: content.sender || character.name,
    time: moment().valueOf(),
    ...content,
  });
};

export const rollAttack = (rtdb, campaign, character, attack) => {
  if (isNaN(attack.hitBonus)) {
    notification.error({
      message: 'Invalid hit bonus',
      description: 'Your hit bonus is not a number',
    });
    return;
  }
  let diceStr = '1d20';
  if (Number(attack.hitBonus) !== 0) diceStr += ` ${attack.hitBonus}`;
  const result = rollDice(parseDice(diceStr));
  if (result) {
    sendChat(rtdb, campaign, character, {
      type: 'attack',
      attack,
      result,
    });
  }
};

export const saveToTurnOrder = (rtdb, campaign, character, turnOrder, characterId, diceResult) => {
  if (turnOrder.some((t) => t.characterId === characterId)) return;
  rtdb
    .ref(`campaign_turn_order/${campaign}/${turnOrder.length}`)
    .set({ characterId, name: character.name, roll: diceResult.result });
};
