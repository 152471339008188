import React from 'react';
import { useAtom } from 'jotai';
import { Button, Form, Input, Modal, Collapse, Popconfirm } from 'antd';
import { BsFillChatDotsFill, BsPlusCircle } from 'react-icons/bs';

import {
  userAtom,
  selectedCampaignAtom,
  characterAtom,
  characterFeatsAtom,
  dmSelectedCharacterAtom,
} from '../../utils/atoms';
import { sortList, FEAT_SORT_OPTIONS } from '../../utils/sort';
import { STAT_MODIFIER_OPTIONS } from '../../utils/constants';
import StatEffectList from '../../components/StatEffectList';
import { getModifierStatLabel } from '../../utils/utils';
import { sendChat } from '../../utils/db';
import './CharacterFeats.css';

const CharacterFeats = ({ rtdb }) => {
  const [user] = useAtom(userAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [character] = useAtom(characterAtom);
  const [feats] = useAtom(characterFeatsAtom);
  const [featsForm] = Form.useForm();
  const [featsModal, setFeatsModal] = React.useState(null);
  const [sort] = React.useState(FEAT_SORT_OPTIONS[0]);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const selectedCharacter = dmSelectedCharacter || user.selectedCharacter;

  const mapFeatModifiers = (modifiers, featId) => ({
    modifiers: modifiers.map((m) => {
      const stat = m.stat[1];
      return { ...m, stat };
    }),
    featId,
  });

  const onSubmit = (values) => {
    if (featsModal === 'new') {
      rtdb
        .ref(`character_feats/${selectedCharacter}/${feats.length}`)
        .set(values)
        .then(() => {
          featsForm.resetFields();
          setFeatsModal(null);
        });
      const modifiers = values.modifiers.length && mapFeatModifiers(values.modifiers, feats.length);
      if (modifiers) rtdb.ref(`characters/${selectedCharacter}/featModifiers/${feats.length}`).set(modifiers);
    } else if (featsModal < feats.length) {
      rtdb
        .ref(`character_feats/${selectedCharacter}/${featsModal}`)
        .set(values)
        .then(() => {
          featsForm.resetFields();
          setFeatsModal(null);
        });
      const modifiers = values.modifiers.length && mapFeatModifiers(values.modifiers, featsModal);
      if (modifiers) rtdb.ref(`characters/${selectedCharacter}/featModifiers/${featsModal}`).set(modifiers);
      else rtdb.ref(`characters/${selectedCharacter}/featModifiers/${featsModal}`).remove();
    }
  };

  const onShareToChat = (e, feat) => {
    e.stopPropagation();
    sendChat(rtdb, campaign, character, {
      type: 'sharefeat',
      feat,
    });
  };

  const onEdit = (i) => {
    featsForm.setFieldsValue(feats[i]);
    setFeatsModal(i);
  };

  const onDelete = (i) => {
    rtdb.ref(`character_feats/${selectedCharacter}/${i}`).remove();
    rtdb.ref(`characters/${selectedCharacter}/featModifiers/${i}`).remove();
  };

  const renderFeatsList = () => {
    const sorted = sortList(feats, sort);
    const getIndex = (feat) => feats.indexOf(feat);
    return (
      <Collapse>
        {sorted.map(
          (feat, i) =>
            feat && (
              <Collapse.Panel
                key={i}
                header={
                  <div className="FeatHeader">
                    <div className="FeatName">{feat.featName}</div>
                    <Button icon={<BsFillChatDotsFill />} onClick={(e) => onShareToChat(e, feat)}>
                      Share to chat
                    </Button>
                  </div>
                }
              >
                <div className="FeatContent">
                  {feat.description && <div className="FeatDescription">{feat.description}</div>}
                  {feat.modifiers && feat.modifiers.length && (
                    <div className="FeatModifiers">
                      {feat.modifiers.map((m) => (
                        <div className="FeatModifier">
                          <div className="Stat">{getModifierStatLabel(STAT_MODIFIER_OPTIONS, m)}</div>
                          <div className="Operation">{m.operation}</div>
                          <div className="Value">{m.value}</div>
                        </div>
                      ))}
                    </div>
                  )}
                  <Button type="link" onClick={() => onEdit(getIndex(feat))}>
                    Edit
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this feature/trait?"
                    onConfirm={() => onDelete(getIndex(feat))}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <Button type="link">Delete</Button>
                  </Popconfirm>
                </div>
              </Collapse.Panel>
            )
        )}
      </Collapse>
    );
  };

  const renderFeatsModal = () => {
    return (
      <Modal
        title={featsModal === 'new' ? 'New feature/trait' : 'Edit feature/trait'}
        footer={null}
        centered
        destroyOnClose
        visible={featsModal !== null}
        onCancel={() => {
          featsForm.resetFields();
          setFeatsModal(null);
        }}
      >
        <div className="FeatsModalContent">
          <Form name="feats" form={featsForm} onFinish={onSubmit}>
            <Form.Item
              label="Feature/trait"
              name="featName"
              initialValue=""
              rules={[{ required: true, message: 'Enter a name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" initialValue="">
              <Input.TextArea rows={4} />
            </Form.Item>
            <StatEffectList />
            <Form.Item>
              <div className="ModalButtonWrapper">
                <Button size="large" htmlType="submit">
                  {featsModal === 'new' ? 'Create' : 'Save'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };

  return (
    <div className="Feats">
      <div className="FeatsList">
        {feats.length ? renderFeatsList() : <div className="Empty">You don't have any features or traits yet</div>}
      </div>
      <div className="ButtonWrapper">
        <Button
          type="link"
          size="large"
          className="NewButton"
          icon={<BsPlusCircle />}
          onClick={() => setFeatsModal('new')}
        >
          New feature/trait
        </Button>
      </div>
      {renderFeatsModal()}
    </div>
  );
};

export default CharacterFeats;
