import React from 'react';
import moment from 'moment';
import { useAtom } from 'jotai';
import { Menu, Button, Form, Input, Popconfirm } from 'antd';
import { BsPlusCircle } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

import { selectedCampaignAtom } from '../utils/atoms';
import { useTimeout } from '../utils/hooks';
import { delay } from '../utils/utils';
import './Journal.css';

const Journal = ({ rtdb, auth }) => {
  const [campaign] = useAtom(selectedCampaignAtom);

  const [journal, setJournal] = React.useState();
  const [selectedEntry, setSelectedEntry] = React.useState('0');
  const [loaded, setLoaded] = React.useState(false);

  const [journalForm] = Form.useForm();

  useTimeout(() => setLoaded(true), 75);

  // listen for journal updates
  React.useEffect(() => {
    rtdb.ref(`user_journals/${auth.user.uid}/${campaign}`).on('value', (snapshot) => {
      const j = snapshot.val();
      setJournal(j);
      if (j && j.selectedEntry) setSelectedEntry(j.selectedEntry);
    });

    return () => {
      rtdb.ref(`user_journals/${auth.user.uid}/${campaign}`).off();
    };
  }, [rtdb, auth, campaign]);

  // update form when journal changes
  React.useEffect(() => {
    if (journal && journal.entries && journal.entries[Number(selectedEntry)]) {
      journalForm.setFieldsValue({ name: '', text: '', ...journal.entries[Number(selectedEntry)] });
    }
  }, [journal, journalForm, selectedEntry]);

  const entries =
    journal && journal.entries
      ? journal.entries
      : [{ time: moment().valueOf(), name: moment().format('MMM D YYYY  h:mm a') }];

  const saveField = (entry, name, value) => {
    if (entries && entries[entry] && value === entries[entry][name]) return;
    rtdb.ref(`user_journals/${auth.user.uid}/${campaign}/entries/${entry}/${name}`).set(value);

    if (name === 'text') {
      const entryName = journalForm.getFieldValue('name');
      if (entries[entry].name !== entryName) {
        rtdb.ref(`user_journals/${auth.user.uid}/${campaign}/entries/${entry}/name`).set(entryName);
      }
    }
  };

  const onSelectEntry = (entry) => {
    if (journal && journal.selectedEntry === entry) return;
    rtdb.ref(`user_journals/${auth.user.uid}/${campaign}/selectedEntry`).set(entry);
  };

  const onNewEntry = () => {
    if (!journal || !journal.entries) {
      rtdb.ref(`user_journals/${auth.user.uid}/${campaign}/`).set({
        selectedEntry: '1',
        entries: [...entries, { time: moment().valueOf(), name: moment().format('MMM D YYYY  h:mm a') }],
      });
    } else {
      rtdb.ref(`user_journals/${auth.user.uid}/${campaign}/`).set({
        selectedEntry: `${entries.length}`,
        entries: [...entries, { time: moment().valueOf(), name: moment().format('MMM D YYYY  h:mm a') }],
      });
    }
  };

  const onDeleteEntry = (entry) => {
    if (journal && journal.entries && journal.entries[Number(entry)]) {
      rtdb.ref(`user_journals/${auth.user.uid}/${campaign}/`).set({
        selectedEntry: '0',
        entries: entries.filter((e, i) => i !== Number(entry)),
      });
    }
  };

  return (
    (loaded || !!journalForm.getFieldValue('text')) && (
      <div className="Journal">
        <div className="JournalContent">
          <div className="Entries">
            <h2>Journal Entries</h2>
            <Button icon={<BsPlusCircle />} onClick={onNewEntry}>
              New entry
            </Button>
            <Menu mode="inline" selectedKeys={[selectedEntry]} onClick={(e) => onSelectEntry(e.key)}>
              {entries.map((entry, i) => (
                <Menu.Item key={`${i}`}>{entry.name}</Menu.Item>
              ))}
            </Menu>
          </div>
          <div className="Entry">
            <Form name="journal" form={journalForm}>
              <Form.Item label="Entry name" name="name" initialValue={entries[Number(selectedEntry)].name}>
                <Input
                  onKeyUp={delay((e) => saveField(selectedEntry, 'name', e.target.value), 500)}
                  onBlur={(e) => saveField(selectedEntry, 'name', e.target.value)}
                />
              </Form.Item>
              <Form.Item label=" " colon={false} name="text" initialValue={entries[Number(selectedEntry)].text}>
                <Input.TextArea
                  rows={20}
                  onKeyUp={delay((e) => saveField(selectedEntry, 'text', e.target.value), 500)}
                  onBlur={(e) => saveField(selectedEntry, 'text', e.target.value)}
                />
              </Form.Item>
              {journal && journal.entries && journal.entries.length > 1 && (
                <div className="DeleteButtonWrapper">
                  <Popconfirm
                    title="Are you sure you want to delete this entry?"
                    onConfirm={() => onDeleteEntry(selectedEntry)}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <Button className="DeleteButton" size="large" danger icon={<FaTrash />}>
                      Delete this entry
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    )
  );
};

export default Journal;
