import { sortBy } from 'lodash';

import { getDiceAverage, parseDice } from './dice';
import { formatSpellLevel, parseLevel } from './utils';

export const sortList = (toSort, sort) => {
  if (!sort) return toSort;
  return sortBy(toSort, (a) => {
    if (!a) return a;
    return sort.pre ? sort.pre(a[sort.field]) : a[sort.field];
  });
};

export const CHARACTER_SORT_OPTIONS = [
  { label: 'Name', field: 'name', pre: (a) => a.toLowerCase() },
  { label: 'Level', field: 'classLevel', pre: (a) => -Number(parseLevel(a)) },
];

export const ATTACK_SORT_OPTIONS = [
  { label: 'Name', field: 'attackName', pre: (a) => a.toLowerCase() },
  { label: 'Attack bonus', field: 'hitBonus', pre: (a) => -Number(a) },
  {
    label: 'Average damage',
    field: 'damage',
    pre: (a) => -getDiceAverage(parseDice(a)),
  },
  { label: 'Damage type', field: 'damageType', pre: (a) => a.toLowerCase() },
];

export const SPELL_SORT_OPTIONS = [
  { label: 'Name', field: 'spellName', pre: (a) => a.toLowerCase() },
  { label: 'Spell level', field: 'level', pre: (a) => (formatSpellLevel(a) === 'Cantrip' ? 0 : Number(a)) },
  {
    label: 'Range',
    field: 'range',
    pre: (a) => -Number(a),
  },
  { label: 'Cast time', field: 'castTime', pre: (a) => a.toLowerCase() },
];

export const RESOURCE_SORT_OPTIONS = [
  { label: 'Name', field: 'resourceName', pre: (a) => a.toLowerCase() },
  { label: 'Current', field: 'current', pre: (a) => -Number(a) },
  {
    label: 'Max',
    field: 'max',
    pre: (a) => -Number(a),
  },
];

export const INVENTORY_SORT_OPTIONS = [
  { label: 'Name', field: 'itemName', pre: (a) => a.itemName.toLowerCase() },
  { label: 'Number held', field: 'number', pre: (a) => -Number(a.number) },
  {
    label: 'Equipped',
    field: 'equipped',
    pre: (a) => (a.equipped ? `0${a.itemName}` : a.equippable ? `1${a.itemName}` : `2${a.itemName}`),
  },
];

export const FEAT_SORT_OPTIONS = [{ label: 'Name', field: 'featName', pre: (a) => a.toLowerCase() }];

export const MINION_SORT_OPTIONS = [
  { label: 'Name', field: 'minionName', pre: (a) => a.toLowerCase() },
  { label: 'HP', field: 'maxhp', pre: (a) => (a ? -Number(a) : 0) },
];

export const TURN_ORDER_SORT_OPTIONS = [{ label: 'Roll', field: 'roll', pre: (a) => -Number(a) }];

export const MAP_SORT_OPTIONS = [
  { label: 'Name', field: 'name', pre: (a) => a.toLowerCase() },
  { label: 'Size', field: 'imageData', pre: (a) => -a.width * a.height },
];

export const NPC_SORT_OPTIONS = [
  { label: 'Name', field: 'name', pre: (a) => a.toLowerCase() },
  { label: 'HP', field: 'maxhp', pre: (a) => (a ? -Number(a) : 0) },
];

export const OBJECT_SORT_OPTIONS = [{ label: 'Name', field: 'name', pre: (a) => a.toLowerCase() }];
