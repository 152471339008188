import { Form, Cascader, Select, InputNumber, Tooltip, Button } from 'antd';
import { BsPlusCircle } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

import { STAT_MODIFIER_OPTIONS } from '../utils/constants';
import './StatEffectList.css';

const StatEffectList = ({ message }) => {
  return (
    <Form.List name="modifiers" initialValue={[]}>
      {(fields, { add, remove }) => (
        <div className="Modifiers">
          {fields.map((field, i) => (
            <div key={field.key} className="Modifier">
              <Form.Item name={[i, 'stat']} rules={[{ required: true, message: 'Select a stat' }]}>
                <Cascader options={STAT_MODIFIER_OPTIONS} placeholder="Select a stat"></Cascader>
              </Form.Item>
              <Form.Item name={[i, 'operation']} initialValue="+">
                <Select>
                  <Select.Option value="+">+</Select.Option>
                  <Select.Option value="-">-</Select.Option>
                  <Select.Option value="=">=</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name={[i, 'value']} rules={[{ required: true, message: 'Enter a number' }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item>
                <Tooltip title="Remove this effect" placement="right">
                  <Button className="RemoveButton" icon={<FaTrash />} onClick={() => remove(field.name)} />
                </Tooltip>
              </Form.Item>
            </div>
          ))}
          <Button type="link" size="large" className="AddButton" icon={<BsPlusCircle />} onClick={() => add()}>
            New stat effect
          </Button>
          {message && fields.length > 0 && <div className="Message">{message}</div>}
        </div>
      )}
    </Form.List>
  );
};

export default StatEffectList;
