import React from 'react';
import { Button } from 'antd';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import { FaDAndD } from 'react-icons/fa';

import { userAtom, selectedCampaignAtom } from '../../utils/atoms';
import './CampaignSelect.css';

const CampaignSelect = ({ auth, rtdb }) => {
  const [user] = useAtom(userAtom);
  const [, setCampaign] = useAtom(selectedCampaignAtom);
  const [invites, setInvites] = React.useState([]);

  React.useEffect(() => {
    rtdb.ref(`users/${auth.user.uid}/campaignInvites`).on('value', async (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const invites = await Promise.all(
          data.map(async (campaignId) => {
            const campaignEntry = await rtdb.ref(`campaigns/${campaignId}`).get();
            if (campaignEntry.exists()) {
              return {
                ...campaignEntry.val(),
                id: campaignId,
              };
            }
            return null;
          })
        );
        setInvites(invites.filter((invite) => !!invite));
      }
    });

    return () => {
      rtdb.ref(`users/${auth.user.uid}/campaignInvites`).off();
    };
  }, [auth.user.uid, rtdb]);

  const history = useHistory();

  return (
    <div className="CampaignSelect">
      <div className="DNDIcon">
        <FaDAndD />
      </div>
      <h1>Select a campaign</h1>
      {invites.map((campaign) => (
        <Button className="CampaignButton" size="large" key={campaign.id} onClick={() => setCampaign(campaign.id)}>
          <div>{campaign.name}</div>
          <div>DM: {campaign.creatorName}</div>
        </Button>
      ))}
      {user && user.canCreateCampaign && (
        <Button className="CampaignButton" type="primary" size="large" onClick={() => history.push('/newcampaign')}>
          Start a new campaign
        </Button>
      )}
      <Button className="LogOutButton" danger size="large" onClick={() => auth.signOut()}>
        Log out
      </Button>
    </div>
  );
};

export default CampaignSelect;
