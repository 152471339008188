import React from 'react';
import firebase from 'firebase/app';
import withFirebaseAuth from 'react-with-firebase-auth';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'antd/dist/antd.dark.css';

import { firebaseConfig } from './config';
import UserView from './layout/UserView';
import Invite from './pages/Invite/Invite';
import Login from './pages/Login';
import './animations.css';
import './App.css';

const firebaseApp = firebase.initializeApp(firebaseConfig);
const rtdb = firebase.database();
const storage = firebase.storage();

function App(props) {
  // const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  // React.useEffect(() => {
  //   const handleResizeWindow = () => setScreenWidth(window.innerWidth);
  //   window.addEventListener('resize', handleResizeWindow);
  //   return () => {
  //     window.removeEventListener('resize', handleResizeWindow);
  //   };
  // }, []);

  const childProps = {
    fb: firebaseApp,
    auth: props,
    rtdb,
    storage,
    // screenWidth,
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        <Router>
          <Switch>
            <Route path="/invite/:id">
              <Invite {...childProps} />
            </Route>
            <Route path="/">{props.user ? <UserView {...childProps} /> : <Login {...childProps} />}</Route>
          </Switch>
        </Router>
      </div>
    </DndProvider>
  );
}

export default withFirebaseAuth({
  providers: {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
  },
  firebaseAppAuth: firebaseApp.auth(),
})(App);
