import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAtom } from 'jotai';

import MainLayout from './MainLayout';
import CampaignSelect from '../pages/Campaign/CampaignSelect';
import NewCampaign from '../pages/Campaign/NewCampaign';
import DMInvite from '../pages/Invite/DMInvite';
import Admin from '../pages/Admin';
import { userAtom, selectedCampaignAtom } from '../utils/atoms';

const UserView = ({ rtdb, auth, ...rest }) => {
  const [, setUser] = useAtom(userAtom);
  const [campaign] = useAtom(selectedCampaignAtom);

  // listen for user data
  React.useEffect(() => {
    rtdb.ref(`users/${auth.user.uid}`).on('value', (userEntry) => {
      if (userEntry.exists()) {
        setUser(userEntry.val());
      }
    });

    return () => {
      rtdb.ref(`users/${auth.user.uid}`).off();
    };
  }, [rtdb, auth, setUser]);

  const childProps = {
    rtdb,
    auth,
    ...rest,
  };

  return (
    <Switch>
      <Route path="/admin">
        <Admin {...childProps} />
      </Route>
      <Route path="/newcampaign">
        <NewCampaign {...childProps} />
      </Route>
      <Route path="/dminvite/:id">
        <DMInvite {...childProps} />
      </Route>
      <Route path="/">{!campaign ? <CampaignSelect {...childProps} /> : <MainLayout {...childProps} />}</Route>
    </Switch>
  );
};

export default UserView;
