export const firebaseConfig = {
  apiKey: 'AIzaSyAvLlfnN2clngOE3-kzwo2F4Zk13-oAJZY',
  authDomain: 'tabletop-241021.firebaseapp.com',
  databaseURL: 'https://tabletop-2.firebaseio.com',
  projectId: 'tabletop-241021',
  storageBucket: 'tabletop-241021.appspot.com',
  messagingSenderId: '75442260848',
  appId: '1:75442260848:web:78a80dd1327b062b',
};

const BUCKET_URL = 'https://storage.googleapis.com/tabletop-241021.appspot.com';

export const URLS = {
  bucket: BUCKET_URL,
  thumbs64: `${BUCKET_URL}/thumbs64`,
  thumbs128: `${BUCKET_URL}/thumbs128`,
  thumbs256: `${BUCKET_URL}/thumbs256`,
};
