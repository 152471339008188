import React from 'react';
import { useAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { map, isEqual } from 'lodash';
import FileUploader from 'react-firebase-file-uploader';
import { Drawer, List, Button, Modal, Form, Input, Avatar, Badge, Popconfirm, Collapse, Progress } from 'antd';
import { BsPlusCircle } from 'react-icons/bs';
import { FaCheckCircle, FaRegEye } from 'react-icons/fa';
import { IoCloudUpload } from 'react-icons/io5';
import { MdError } from 'react-icons/md';

import {
  mapDrawerAtom,
  selectedCampaignAtom,
  dmPreviewMapAtom,
  adjustGridAtom,
  gridRefAtom,
  campaignAtom,
  campaignDMAtom,
  mapsAtom,
  dropObjectAtom,
  dmVisionAtom,
  characterListAtom,
  dmSelectedCharacterAtom,
  pageAtom,
} from '../../utils/atoms';
import { URLS } from '../../config';
import { delay } from '../../utils/utils';
import SortMenu from '../../components/SortMenu';
import SimpleNPCForm from '../../components/SimpleNPCForm';
import { MAP_SORT_OPTIONS, NPC_SORT_OPTIONS, OBJECT_SORT_OPTIONS, sortList } from '../../utils/sort';
import './MapDMDrawer.css';

const MapDMDrawer = ({ rtdb, auth, storage }) => {
  const [drawer, setDrawer] = useAtom(mapDrawerAtom);
  const [maps, setMaps] = useAtom(mapsAtom);
  const [characterList] = useAtom(characterListAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [campaignData] = useAtom(campaignAtom);
  const [campaignDMData, setCampaignDMData] = useAtom(campaignDMAtom);
  const [dmPreviewMap, setDMPreviewMap] = useAtom(dmPreviewMapAtom);
  const [gridRef] = useAtom(gridRefAtom);
  const [, setAdjustingGrid] = useAtom(adjustGridAtom);
  const [, setDropObject] = useAtom(dropObjectAtom);
  const [, setDMVision] = useAtom(dmVisionAtom);
  const [, setDMSelectedCharacter] = useAtom(dmSelectedCharacterAtom);
  const [, setPage] = useAtom(pageAtom);

  const [mapModal, setMapModal] = React.useState();
  const [mapModalImage, setMapModalImage] = React.useState();
  const [isMapUploading, setIsMapUploading] = React.useState(false);
  const [mapUploadProgress, setMapUploadingProgress] = React.useState(0);
  const [mapSort, setMapSort] = React.useState(MAP_SORT_OPTIONS[0]);
  const [mapFilter, setMapFilter] = React.useState();

  const [npcModal, setNpcModal] = React.useState();
  const [npcModalImage, setNpcModalImage] = React.useState();
  const [isNpcUploading, setIsNpcUploading] = React.useState(false);
  const [npcUploadProgress, setNpcUploadingProgress] = React.useState(0);
  const [npcSort, setNpcSort] = React.useState(NPC_SORT_OPTIONS[0]);
  const [npcFilter, setNpcFilter] = React.useState();

  const [objectModal, setObjectModal] = React.useState();
  const [objectModalImage, setObjectModalImage] = React.useState();
  const [isObjectUploading, setIsObjectUploading] = React.useState(false);
  const [objectUploadProgress, setObjectUploadingProgress] = React.useState(0);
  const [objectSort, setObjectSort] = React.useState(OBJECT_SORT_OPTIONS[0]);
  const [objectFilter, setObjectFilter] = React.useState();

  // listen for DM campaign data
  React.useEffect(() => {
    if (campaign) {
      rtdb.ref(`campaigns_dm/${campaign}`).on('value', (snapshot) => {
        const data = snapshot.val();
        setCampaignDMData(data);
        setDMVision(!!(data && data.dmVision));
      });

      return () => {
        rtdb.ref(`campaigns_dm/${campaign}`).off();
      };
    }
  }, [rtdb, campaign, setCampaignDMData, setDMVision]);

  // listen for maps data
  React.useEffect(() => {
    if (campaignDMData && campaignDMData.maps && campaignDMData.maps.length) {
      campaignDMData.maps.forEach((mapId) => {
        rtdb.ref(`maps/${mapId}`).on('value', (snapshot) => {
          const data = snapshot.val();
          if (data && !isEqual(maps[mapId], data)) {
            const newMaps = { ...maps };
            newMaps[mapId] = data;
            setMaps(newMaps);
          }
        });
      });

      return () => {
        campaignDMData.maps.forEach((mapId) => {
          if (campaignData && campaignData.currentMap === mapId) return;
          rtdb.ref(`maps/${mapId}`).off();
        });
      };
    }
  }, [rtdb, campaignDMData, campaignData, maps, setMaps]);

  const handleMapUploadStart = () => {
    setIsMapUploading(true);
    setMapUploadingProgress(0);
  };

  const handleMapUploadProgress = (progress) => setMapUploadingProgress(progress);

  const handleMapUploadError = (error) => {
    setIsMapUploading(false);
    console.error(error);
  };

  const handleMapUploadSuccess = (filename) => {
    setIsMapUploading(false);
    setMapUploadingProgress(100);
    setMapModalImage(`maps/${filename}`);
  };

  const recomputeGridSize = () => {
    if (gridRef && gridRef.current) gridRef.current.recomputeGridSize();
  };

  const onSaveMap = async (values) => {
    if (!mapModalImage) return;
    const newMapData = {
      name: values.mapName,
      image: mapModalImage,
    };
    if (values.tags) {
      newMapData.tags = values.tags.split(' ');
    }
    if (mapModal === 'new') {
      const newMap = await rtdb.ref(`maps`).push(newMapData);
      const i = campaignDMData && campaignDMData.maps ? campaignDMData.maps.length : 0;
      rtdb.ref(`campaigns_dm/${campaign}/maps/${i}`).set(newMap.key);
    } else {
      rtdb.ref(`maps/${mapModal}`).update(newMapData);
    }
    setMapModal(undefined);
    setMapModalImage(undefined);
  };

  const onDeleteMap = (map) => {
    const existingMaps = campaignDMData && campaignDMData.maps ? campaignDMData.maps : [];
    rtdb.ref(`campaigns_dm/${campaign}/maps`).set(existingMaps.filter((m) => m !== map.id));
    rtdb.ref(`maps/${map.id}`).remove();
    const newMaps = { ...maps };
    delete newMaps[map.id];
    setMaps(newMaps);
  };

  const onEditMap = (map) => {
    setMapModalImage(map.image);
    setMapModal(map.id);
  };

  const onSelectMap = (map) => {
    rtdb.ref(`campaigns/${campaign}/currentMap`).set(map.id);
    setAdjustingGrid(false);
    setDMPreviewMap(undefined);
    setDrawer(undefined);
    recomputeGridSize();
  };

  const onPreviewMap = (map) => {
    setAdjustingGrid(false);
    setDMPreviewMap(map.id);
    setDrawer(undefined);
    recomputeGridSize();
  };

  const onSwitchBack = () => {
    setAdjustingGrid(false);
    setDMPreviewMap(undefined);
    setDrawer(undefined);
    recomputeGridSize();
  };

  const onAdjustGrid = (map) => {
    setDMPreviewMap(map.id);
    setAdjustingGrid(true);
    setDrawer(undefined);
    recomputeGridSize();
  };

  const onSaveNpc = async (values) => {
    const newNpcData = { ...values, image: npcModalImage, type: 'basic' };
    if (values.tags) {
      newNpcData.tags = values.tags.split(' ');
    }
    if (values.maxhp) {
      newNpcData.hp = values.maxhp;
    }
    if (npcModal === 'new') {
      rtdb.ref(`campaigns_dm/${campaign}/npcs`).push(newNpcData);
    } else {
      rtdb.ref(`campaigns_dm/${campaign}/npcs/${npcModal}`).update(newNpcData);
    }
    setNpcModal(undefined);
    setNpcModalImage(undefined);
  };

  const onSaveObject = async (values) => {
    const newObjectData = { ...values, image: objectModalImage };
    if (values.tags) {
      newObjectData.tags = values.tags.split(' ');
    }
    if (objectModal === 'new') {
      rtdb.ref(`campaigns_dm/${campaign}/objects`).push(newObjectData);
    } else {
      rtdb.ref(`campaigns_dm/${campaign}/objects/${objectModal}`).update(newObjectData);
    }
    setObjectModal(undefined);
    setObjectModalImage(undefined);
  };

  const renderMapItem = (map) => {
    const isMapProcessing = !map.mapWidth || !map.mapHeight;
    const isGridSet = !!map.width && !!map.height;

    const getBadge = () => {
      if (isMapProcessing) return <IoCloudUpload style={{ color: 'cornflowerblue', fontSize: '1.25em' }} />;
      if (!isGridSet) return <MdError style={{ color: 'firebrick', fontSize: '1.4em' }} />;
      if (campaignData.currentMap === map.id)
        return <FaCheckCircle style={{ color: 'limegreen', fontSize: '1.25em' }} />;
      if (dmPreviewMap === map.id) return <FaRegEye style={{ color: 'darkorange', fontSize: '1.35em' }} />;
      return 0;
    };

    return (
      <List.Item>
        <List.Item.Meta
          avatar={
            <Badge count={getBadge()}>
              <Avatar shape="square" size={90} src={map.image ? `${URLS.thumbs64}/${map.image}` : ''} />
            </Badge>
          }
          title={map.name}
          description={
            <div className="MapDescription">
              <div className="Row">
                <Button onClick={() => onEditMap(map)}>Edit</Button>
                <Button onClick={() => onAdjustGrid(map)} disabled={isMapProcessing}>
                  Adjust grid
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete this map?"
                  okText="Delete"
                  cancelText="Cancel"
                  onConfirm={() => onDeleteMap(map)}
                >
                  <Button>Delete</Button>
                </Popconfirm>
              </div>
              <div className="Row">
                {isMapProcessing ? (
                  <div className="Selected">This map is being processed</div>
                ) : campaignData.currentMap === map.id && dmPreviewMap === undefined ? (
                  <div className="Selected">This map is what the players see</div>
                ) : campaignData.currentMap === map.id && !!dmPreviewMap ? (
                  <Button onClick={onSwitchBack}>Switch back to this map</Button>
                ) : (
                  <>
                    <Button onClick={() => onPreviewMap(map)} disabled={dmPreviewMap === map.id}>
                      {dmPreviewMap === map.id ? 'Previewing' : 'Preview'}
                    </Button>
                    <Button onClick={() => onSelectMap(map)} disabled={!isGridSet}>
                      {isGridSet ? 'Switch to this map' : 'Grid not set'}
                    </Button>
                  </>
                )}
              </div>
            </div>
          }
        />
      </List.Item>
    );
  };

  const filterMaps = (mapArray) => {
    if (!mapFilter) return mapArray;
    return mapArray.filter(
      (m) =>
        m.name.toLowerCase().includes(mapFilter.toLowerCase()) ||
        (m.tags && m.tags.some((t) => t.toLowerCase().includes(mapFilter.toLowerCase())))
    );
  };

  const renderMaps = () => {
    const mapArray = map(maps, (m, id) => ({ ...m, id }));
    const filtered = filterMaps(mapArray);
    const sorted = sortList(filtered, mapSort);
    return (
      <>
        <h2>Maps</h2>
        <Button className="WideButton" size="large" icon={<BsPlusCircle />} onClick={() => setMapModal('new')}>
          New map
        </Button>
        <div className="MapSearch">
          <Input
            allowClear
            placeholder="Type here to filter maps by name or tag"
            onChange={delay((e) => {
              setMapFilter(e.target.value);
            }, 250)}
          />
        </div>
        <SortMenu sort={mapSort} setSort={setMapSort} options={MAP_SORT_OPTIONS} />
        {mapArray.length ? (
          <List itemLayout="horizontal" dataSource={sorted} renderItem={renderMapItem} />
        ) : (
          <i>No maps were found</i>
        )}
      </>
    );
  };

  const renderMapModal = () => {
    let name = '';
    let tags = '';
    const isExisting = mapModal && mapModal !== 'new';
    if (isExisting) {
      const m = maps[mapModal];
      name = m.name;
      if (m.tags) tags = m.tags.join(' ');
    }
    return (
      <Modal
        title={isExisting ? 'Edit map' : 'New map'}
        visible={!!mapModal}
        footer={null}
        width={620}
        centered
        destroyOnClose
        onCancel={() => {
          setMapModal(undefined);
          setMapModalImage(undefined);
        }}
      >
        <div className="MapModalContent">
          <div className={!mapModalImage ? 'Image NoImage' : 'Image'}>
            <label>
              {isMapUploading ? (
                <Progress type="circle" percent={mapUploadProgress} />
              ) : mapModalImage ? (
                <img alt="Map" src={`${URLS.bucket}/${mapModalImage}`} />
              ) : (
                'Click to upload an image for your map'
              )}

              <FileUploader
                hidden
                randomizeFilename
                accept="image/*"
                storageRef={storage.ref('maps')}
                onUploadStart={handleMapUploadStart}
                onUploadError={handleMapUploadError}
                onUploadSuccess={handleMapUploadSuccess}
                onProgress={handleMapUploadProgress}
              />
            </label>
          </div>
          <div className="Form">
            <Form name="map" onFinish={onSaveMap}>
              <div className="FormContent">
                <Form.Item
                  name="mapName"
                  label="Name"
                  initialValue={name}
                  rules={[{ required: true, message: 'Enter a name' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="tags"
                  label="Tags"
                  initialValue={tags}
                  extra="Optional. Separate tags with spaces. This will help you search for similar maps"
                >
                  <Input />
                </Form.Item>
                <div className="ModalButtonWrapper">
                  <Button size="large" htmlType="submit">
                    {isExisting ? 'Save' : 'Create'}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  };

  const renderNpcItem = (npc) => {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar size={56} src={`${URLS.thumbs64}/${npc.image}`} />}
          title={npc.name}
          description={
            <div className="NpcDescription">
              <div className="Row">
                <Button
                  onClick={() => {
                    setNpcModalImage(npc.image);
                    setNpcModal(npc.id);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete this?"
                  okText="Delete"
                  cancelText="Cancel"
                  onConfirm={() => {
                    rtdb.ref(`campaigns_dm/${campaign}/npcs/${npc.id}`).remove();
                  }}
                >
                  <Button>Delete</Button>
                </Popconfirm>
                <Button
                  onClick={() => {
                    setDropObject({
                      ...npc,
                      type: 'basic',
                      id: uuidv4(),
                      npcId: npc.id,
                    });
                    setDrawer(undefined);
                  }}
                  disabled={false}
                >
                  Place on map
                </Button>
              </div>
            </div>
          }
        />
      </List.Item>
    );
  };

  const renderCharacterItem = (character) => {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar size={56} src={`${URLS.thumbs64}/${character.image}`} />}
          title={character.name}
          description={
            <div className="NpcDescription">
              <div className="Row">
                <Button
                  onClick={() => {
                    setDMSelectedCharacter(character.id);
                    setPage('character');
                  }}
                  disabled={false}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    setDMSelectedCharacter(character.id);
                    setDropObject({
                      ...character,
                      type: 'npc',
                      name: character.name || '???',
                      image: character.image || '',
                      id: character.id,
                      characterId: character.id,
                    });
                    setDrawer(undefined);
                  }}
                  disabled={false}
                >
                  Place on map
                </Button>
              </div>
            </div>
          }
        />
      </List.Item>
    );
  };

  const filterNpcs = (npcArray) => {
    if (!npcArray || !npcFilter) return npcArray;
    return npcArray.filter(
      (m) =>
        m.name.toLowerCase().includes(npcFilter.toLowerCase()) ||
        (m.tags && m.tags.some((t) => t.toLowerCase().includes(npcFilter.toLowerCase())))
    );
  };

  const renderNPCs = () => {
    const npcArray = map(campaignDMData?.npcs, (m, id) => ({ ...m, id }));
    const filtered = filterNpcs(npcArray);
    const sorted = sortList(filtered, npcSort);
    const basic = sorted.filter((n) => n.type === 'basic');

    const charArray = characterList || [];
    const filteredChars = filterNpcs(charArray);
    const sortedChars = sortList(filteredChars, npcSort);
    return (
      <>
        <h2>Non-player characters</h2>
        <div className="NpcSearch">
          <Input
            allowClear
            placeholder="Type here to filter NPCs by name or tag"
            onChange={delay((e) => {
              setNpcFilter(e.target.value);
            }, 250)}
          />
        </div>
        <SortMenu sort={npcSort} setSort={setNpcSort} options={NPC_SORT_OPTIONS} />
        <Collapse defaultActiveKey={['0', '1']}>
          <Collapse.Panel key="0" header="Basic NPCs">
            <div className="BasicNPCs">
              <Button className="WideButton" size="large" icon={<BsPlusCircle />} onClick={() => setNpcModal('new')}>
                New basic NPC
              </Button>
              {basic.length ? <List itemLayout="horizontal" dataSource={basic} renderItem={renderNpcItem} /> : null}
            </div>
          </Collapse.Panel>
          <Collapse.Panel key="1" header="Full character NPCs">
            <div className="FullNPCs">
              {sortedChars && sortedChars.length ? (
                <List itemLayout="horizontal" dataSource={sortedChars} renderItem={renderCharacterItem} />
              ) : null}
            </div>
          </Collapse.Panel>
        </Collapse>
      </>
    );
  };

  const renderNpcModal = () => {
    const isExisting = npcModal && npcModal !== 'new';
    const npc = isExisting && campaignDMData && campaignDMData.npcs ? campaignDMData.npcs[npcModal] : {};
    return (
      <Modal
        title={`${isExisting ? 'Edit' : 'New'} basic NPC`}
        footer={null}
        centered
        destroyOnClose
        width={700}
        visible={!!npcModal}
        onCancel={() => {
          setNpcModal(undefined);
          setNpcModalImage(undefined);
        }}
      >
        <SimpleNPCForm
          formName="basicNPC"
          nameVar="name"
          existing={isExisting && npc}
          onFinish={onSaveNpc}
          noImageText="Click to upload an image for your NPC"
          image={npcModalImage}
          setImage={setNpcModalImage}
          isUploading={isNpcUploading}
          setIsUploading={setIsNpcUploading}
          uploadProgress={npcUploadProgress}
          setUploadingProgress={setNpcUploadingProgress}
          storageRef={storage.ref('icons')}
          message={isExisting && "Note: editing the NPC template here doesn't update existing ones on the map"}
        />
      </Modal>
    );
  };

  const renderObjectItem = (object) => {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar size={56} src={`${URLS.thumbs64}/${object.image}`} />}
          title={object.name}
          description={
            <div className="ObjectDescription">
              <div className="Row">
                <Button
                  onClick={() => {
                    setObjectModalImage(object.image);
                    setObjectModal(object.id);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete this?"
                  okText="Delete"
                  cancelText="Cancel"
                  onConfirm={() => {
                    rtdb.ref(`campaigns_dm/${campaign}/objects/${object.id}`).remove();
                  }}
                >
                  <Button>Delete</Button>
                </Popconfirm>
                <Button
                  onClick={() => {
                    setDropObject({
                      ...object,
                      type: 'object',
                      id: uuidv4(),
                      objectId: object.id,
                    });
                    setDrawer(undefined);
                  }}
                  disabled={false}
                >
                  Place on map
                </Button>
              </div>
            </div>
          }
        />
      </List.Item>
    );
  };

  const filterObjects = (objectArray) => {
    if (!objectArray || !objectFilter) return objectArray;
    return objectArray.filter(
      (m) =>
        m.name.toLowerCase().includes(objectFilter.toLowerCase()) ||
        (m.tags && m.tags.some((t) => t.toLowerCase().includes(objectFilter.toLowerCase())))
    );
  };

  const renderObjects = () => {
    const objectArray = map(campaignDMData?.objects, (m, id) => ({ ...m, id }));
    const filtered = filterObjects(objectArray);
    const sorted = sortList(filtered, objectSort);
    return (
      <>
        <h2>Objects</h2>
        <div className="ObjectSearch">
          <Input
            allowClear
            placeholder="Type here to filter objects by name or tag"
            onChange={delay((e) => {
              setObjectFilter(e.target.value);
            }, 250)}
          />
        </div>
        <Button className="WideButton" size="large" icon={<BsPlusCircle />} onClick={() => setObjectModal('new')}>
          New object
        </Button>
        <div className="Objects">
          {sorted.length ? <List itemLayout="horizontal" dataSource={sorted} renderItem={renderObjectItem} /> : null}
        </div>
      </>
    );
  };

  const renderObjectModal = () => {
    const isExisting = objectModal && objectModal !== 'new';
    const object = isExisting && campaignDMData && campaignDMData.objects ? campaignDMData.objects[objectModal] : {};
    return (
      <Modal
        title={`${isExisting ? 'Edit' : 'New'} object`}
        footer={null}
        centered
        destroyOnClose
        width={700}
        visible={!!objectModal}
        onCancel={() => {
          setObjectModal(undefined);
          setObjectModalImage(undefined);
        }}
      >
        <SimpleNPCForm
          formName="basicNPC"
          nameVar="name"
          existing={isExisting && object}
          onFinish={onSaveObject}
          noImageText="Click to upload an image for your object"
          image={objectModalImage}
          setImage={setObjectModalImage}
          isUploading={isObjectUploading}
          setIsUploading={setIsObjectUploading}
          uploadProgress={objectUploadProgress}
          setUploadingProgress={setObjectUploadingProgress}
          storageRef={storage.ref('icons')}
          message={isExisting && "Note: editing the object template here doesn't update existing ones on the map"}
          hideMore
        />
      </Modal>
    );
  };

  const renderContent = () => {
    switch (drawer) {
      case 'maps':
        return renderMaps();
      case 'npcs':
        return renderNPCs();
      case 'objects':
        return renderObjects();
      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        placement="left"
        getContainer={false}
        visible={['maps', 'npcs', 'objects'].some((d) => drawer === d)}
        onClose={() => {
          setDrawer(undefined);
          setMapFilter();
          setNpcFilter();
          setObjectFilter();
        }}
        width={500}
        style={{ position: 'absolute' }}
      >
        <div className="DMDrawerContent">{renderContent()}</div>
      </Drawer>
      {renderMapModal()}
      {renderNpcModal()}
      {renderObjectModal()}
    </>
  );
};

export default MapDMDrawer;
