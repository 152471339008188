import React from 'react';
import { Button, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';

import { userAtom, selectedCampaignAtom } from '../../utils/atoms';
import './NewCampaign.css';

const NewCampaign = ({ rtdb, auth }) => {
  const [user] = useAtom(userAtom);
  const [, setCampaign] = useAtom(selectedCampaignAtom);
  const history = useHistory();

  const onSubmit = async (values) => {
    const pushed = await rtdb.ref('campaigns').push({
      name: values.name,
      dmUsers: [auth.user.uid],
      creatorName: auth.user.displayName || 'Unknown',
    });
    const invites = user.campaignInvites || [];
    await rtdb.ref(`users/${auth.user.uid}/campaignInvites/${invites.length}`).set(pushed.key);
    setCampaign(pushed.key);
    history.push('/');
  };

  return (
    <div className="NewCampaign">
      {user && user.canCreateCampaign ? (
        <div>
          <div>New campaign:</div>
          <Form name="newcampaign" initialValues={{ remember: true }} onFinish={onSubmit}>
            <Form.Item
              label="Campaign name"
              name="name"
              rules={[{ required: true, message: 'Enter a name for the campaign' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button onClick={() => history.push('/')}>Go back</Button>
              <Button htmlType="submit">Start campaign</Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div>
          <div>You don't have permission to make new campaigns</div>
          <div>Ask an admin to grant you the ability</div>
          <Button onClick={() => history.push('/')}>Go back</Button>
        </div>
      )}
    </div>
  );
};

export default NewCampaign;
