import { atom } from 'jotai';

export const userAtom = atom({});
export const isDMAtom = atom(false);
export const pageAtom = atom('map');
export const characterTabAtom = atom('biography');
export const dmSelectedCharacterAtom = atom();

export const selectedCampaignAtom = atom();
export const campaignAtom = atom();
export const campaignDMAtom = atom();
export const turnOrderAtom = atom([]);

export const mapAtom = atom();
export const mapsAtom = atom({});
export const mapDrawerAtom = atom();
export const dropObjectAtom = atom();
export const dmPreviewMapAtom = atom();
export const dmVisionAtom = atom(false);
export const adjustGridAtom = atom(false);
export const gridRefAtom = atom();
export const zoomAtom = atom(1);

export const characterListAtom = atom();
export const characterAtom = atom();
export const characterAttacksAtom = atom([]);
export const characterSpellsAtom = atom([]);
export const characterInventoryAtom = atom([]);
export const characterCurrenciesAtom = atom();
export const characterResourcesAtom = atom([]);
export const characterFeatsAtom = atom([]);
export const characterMinionsAtom = atom({});
