import React from 'react';
import { Form, Input, InputNumber, Collapse, Button, Progress } from 'antd';
import FileUploader from 'react-firebase-file-uploader';

import { URLS } from '../config';
import './SimpleNPCForm.css';

const SimpleNPCForm = ({
  storageRef,
  formName,
  onFinish,
  image,
  noImageText,
  setImage,
  isUploading,
  setIsUploading,
  uploadProgress,
  setUploadingProgress,
  nameVar,
  existing,
  message,
  hideMore,
}) => {
  const handleMapUploadStart = () => {
    setIsUploading(true);
    setUploadingProgress(0);
  };

  const handleMapUploadProgress = (progress) => setUploadingProgress(progress);

  const handleMapUploadError = (error) => {
    setIsUploading(false);
    console.error(error);
  };

  const handleMapUploadSuccess = (filename) => {
    setIsUploading(false);
    setUploadingProgress(100);
    setImage(`icons/${filename}`);
  };

  return (
    <Form name={formName} onFinish={onFinish}>
      <div className="SimpleNPCFormContent">
        <div className="Row">
          <div className={!image ? 'Image NoImage' : 'Image'}>
            <label>
              {isUploading ? (
                <Progress type="circle" percent={uploadProgress} />
              ) : image ? (
                <img alt="" src={`${URLS.bucket}/${image}`} />
              ) : (
                noImageText
              )}

              <FileUploader
                hidden
                randomizeFilename
                accept="image/*"
                storageRef={storageRef}
                onUploadStart={handleMapUploadStart}
                onUploadError={handleMapUploadError}
                onUploadSuccess={handleMapUploadSuccess}
                onProgress={handleMapUploadProgress}
              />
            </label>
          </div>
          <div className="Form">
            <div className="FormContent">
              <Form.Item
                name={nameVar}
                label="Name"
                initialValue={existing ? existing[nameVar] || '' : ''}
                rules={[{ required: true, message: 'Enter a name' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="tags"
                label="Tags"
                initialValue={existing && existing.tags ? existing.tags.join(' ') : ''}
                extra="For searching. Separate tags with spaces"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="title"
                label="Title"
                initialValue={existing ? existing.title || '' : ''}
                extra="Shown under name"
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </div>
        {!hideMore && (
          <Collapse>
            <Collapse.Panel header="More">
              <div className="Row">
                <Form.Item name="maxhp" label="Max. HP" initialValue={existing ? existing.maxhp || '' : ''}>
                  <InputNumber />
                </Form.Item>
                <Form.Item name="ac" label="AC" initialValue={existing ? existing.ac || '' : ''}>
                  <InputNumber />
                </Form.Item>
                <Form.Item name="size" label="Size" initialValue={existing ? existing.size || '' : ''}>
                  <Input className="SmallInput" />
                </Form.Item>
              </div>
              <div className="Row">
                <Form.Item name="strBonus" label="Str. Bonus" initialValue={existing ? existing.strBonus || '' : ''}>
                  <InputNumber />
                </Form.Item>
                <Form.Item name="dexBonus" label="Dex. Bonus" initialValue={existing ? existing.dexBonus || '' : ''}>
                  <InputNumber />
                </Form.Item>
                <Form.Item name="conBonus" label="Con. Bonus" initialValue={existing ? existing.conBonus || '' : ''}>
                  <InputNumber />
                </Form.Item>
                <Form.Item name="intBonus" label="Int. Bonus" initialValue={existing ? existing.intBonus || '' : ''}>
                  <InputNumber />
                </Form.Item>
                <Form.Item name="wisBonus" label="Wis. Bonus" initialValue={existing ? existing.wisBonus || '' : ''}>
                  <InputNumber />
                </Form.Item>
                <Form.Item name="chaBonus" label="Cha. Bonus" initialValue={existing ? existing.chaBonus || '' : ''}>
                  <InputNumber />
                </Form.Item>
              </div>
              <div className="Row FullWidth">
                <Form.Item
                  name="otherInfo"
                  label="Other information"
                  initialValue={existing ? existing.otherInfo || '' : ''}
                >
                  <Input.TextArea rows={8} />
                </Form.Item>
              </div>
            </Collapse.Panel>
          </Collapse>
        )}
        <div className="ModalButtonWrapper">
          {message && <div className="Message">{message}</div>}
          <Button size="large" htmlType="submit">
            {existing ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default SimpleNPCForm;
