import { forEach } from 'lodash';

export function delay(fn, ms) {
  let timer = 0;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 0);
  };
}

export function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function withSign(num) {
  if (typeof num === 'string' && (num.startsWith('+') || num.startsWith('-'))) return num;
  if (num < 0) return `${num}`;
  return `+${num}`;
}

export function parseLevel(classLevel) {
  if (!classLevel || !classLevel.split) return 0;
  const splitLevel = classLevel.split(/[^0-9]+/);
  let level = 0;
  splitLevel.forEach((s) => (level += Number(s)));
  return level;
}

export function getProficiencyBonus(level) {
  if (level < 5) return 2;
  if (level < 9) return 3;
  if (level < 13) return 4;
  if (level < 17) return 5;
  return 6;
}

export function getAbilityBonus(score = 0) {
  return Math.floor((score - 10) / 2);
}

export function getSpellAttackBonus(character) {
  const { spellCastingAbility } = character;
  const abilityScore = spellCastingAbility ? character[spellCastingAbility] : 0;
  const abilityScorewithModifiers = getStatWithModifier(character, spellCastingAbility, abilityScore);
  const abilityBonus = getAbilityBonus(abilityScorewithModifiers);
  const bonusWithModifiers = getStatWithModifier(character, `${spellCastingAbility}Bonus`, abilityBonus);
  const level = parseLevel(character.classLevel);
  const proficiencyBonus = getProficiencyBonus(level);
  return proficiencyBonus + bonusWithModifiers  ;
}

export function getSpellSaveDC(character) {
  const bonus = getSpellAttackBonus(character);
  return 8 + bonus;
}

export function formatSpellLevel(level, short = false) {
  if (typeof level === 'string' && (level.startsWith('L') || level === 'Cantrip' || level === 'C')) return level;
  return Number(level) > 0 ? `L${Number(level)}` : short ? 'C' : 'Cantrip';
}

export const mapContainsCharacter = (map, characterId) => {
  if (map && map.objects && !map.objects.some((o) => o.characterId === characterId)) return false;
  return true;
};

export const getStatModifiers = (character, stat) => {
  const modifiers = [];
  if (character && character.featModifiers) {
    forEach(character.featModifiers, (fm) => {
      if (!fm || !fm.modifiers) return;
      fm.modifiers.forEach((m) => {
        if (m.stat === stat) modifiers.push(m);
      });
    });
  }
  if (character && character.itemModifiers) {
    forEach(character.itemModifiers, (im) => {
      if (!im || !im.modifiers || !im.equipped) return;
      im.modifiers.forEach((m) => {
        if (m.stat === stat) modifiers.push(m);
      });
    });
  }
  return modifiers;
};

export const getStatWithModifier = (character, stat, initial) => {
  const modifiers = getStatModifiers(character, stat);
  if (!modifiers.length) return initial;

  const equalsModifiers = modifiers.filter((m) => m.operation === '=');
  if (equalsModifiers.length) {
    let max = 0;
    equalsModifiers.forEach((m) => {
      max = Math.max(max, m.value);
    });
    return max;
  }

  let result = Number(initial);
  modifiers.forEach((m) => {
    if (m.operation === '+') {
      result += m.value;
    } else if (m.operation === '-') {
      result -= m.value;
    }
  });
  return result;
};

export const getModifierStatLabel = (options, modifier) => {
  const type = options.find((t) => t.value === modifier.stat[0]);
  const stat = type.children.find((s) => s.value === modifier.stat[1]);
  return stat.label;
};
