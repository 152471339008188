import { Dropdown, Menu, Button } from 'antd';

import './SortMenu.css';

const SortMenu = ({ options, sort, setSort }) => {
  return (
    <div className="SortMenu">
      <div>Sort by: </div>
      <Dropdown
        overlay={
          <Menu>
            {options.map((o) => (
              <Menu.Item key={o.field} onClick={() => setSort(o)}>
                {o.label}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button type="link">{sort.label}</Button>
      </Dropdown>
    </div>
  );
};

export default SortMenu;
