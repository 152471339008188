import { Button, Tooltip } from 'antd';
import { useAtom } from 'jotai';
import {
  GiPointySword,
  GiBoltSpellCast,
  GiDiceTwentyFacesTwenty,
  GiBattery75,
  GiStrong,
  GiBattleGear,
  GiMinions,
} from 'react-icons/gi';
import { MdGridOn, MdGridOff } from 'react-icons/md';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { BsSlashSquare } from 'react-icons/bs';
import { GoListOrdered } from 'react-icons/go';

import { userAtom, mapDrawerAtom, gridRefAtom, zoomAtom, dmSelectedCharacterAtom, isDMAtom } from '../../utils/atoms';
import './MapToolbar.css';

const ToolbarButton = ({ icon, label, onClick }) => (
  <Tooltip placement="left" title={label}>
    <Button icon={icon} onClick={onClick}></Button>
  </Tooltip>
);

const MapToolbar = ({ rtdb, auth }) => {
  const [user] = useAtom(userAtom);
  const [isDM] = useAtom(isDMAtom);
  const [gridRef] = useAtom(gridRefAtom);
  const [zoom, setZoom] = useAtom(zoomAtom);
  const [, setDrawer] = useAtom(mapDrawerAtom);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const onToggleGrid = () => {
    rtdb.ref(`users/${auth.user.uid}/showMapGrid`).set(!user.showMapGrid);
  };

  const recomputeGridSize = () => {
    if (gridRef && gridRef.current) gridRef.current.recomputeGridSize();
  };

  const onSetZoom = (action) => {
    switch (action) {
      case 'in':
        if (zoom < 2) {
          setZoom(Math.min(2, zoom * 1.1));
          recomputeGridSize();
        }
        break;
      case 'out':
        if (zoom > 0.5) {
          setZoom(Math.max(0.5, zoom / 1.1));
          recomputeGridSize();
        }
        break;
      default:
        setZoom(1);
        recomputeGridSize();
    }
  };

  return (
    <div className="Toolbar">
      <div className="ToolbarSection">
        <ToolbarButton label="Zoom in" icon={<BiZoomIn />} onClick={() => onSetZoom('in')}></ToolbarButton>
        <ToolbarButton label="Zoom out" icon={<BiZoomOut />} onClick={() => onSetZoom('out')}></ToolbarButton>
        <ToolbarButton label="Reset zoom" icon={<BsSlashSquare />} onClick={() => onSetZoom()}></ToolbarButton>
        <ToolbarButton
          label={user.showMapGrid ? 'Hide map grid' : 'Show map grid'}
          icon={user.showMapGrid ? <MdGridOff /> : <MdGridOn />}
          onClick={onToggleGrid}
        ></ToolbarButton>
        {(dmSelectedCharacter || user.selectedCharacter) && (
          <>
            <ToolbarButton
              label="Roll a check"
              icon={<GiDiceTwentyFacesTwenty />}
              onClick={() => setDrawer('rolls')}
            ></ToolbarButton>
            <ToolbarButton
              label="Attacks"
              icon={<GiPointySword />}
              onClick={() => setDrawer('attacks')}
            ></ToolbarButton>
            <ToolbarButton
              label="Spells"
              icon={<GiBoltSpellCast />}
              onClick={() => setDrawer('spells')}
            ></ToolbarButton>
            <ToolbarButton
              label="Resources"
              icon={<GiBattery75 />}
              onClick={() => setDrawer('resources')}
            ></ToolbarButton>
            <ToolbarButton
              label="Items / equipment"
              icon={<GiBattleGear />}
              onClick={() => setDrawer('inventory')}
            ></ToolbarButton>
            <ToolbarButton
              label="Features / traits"
              icon={<GiStrong />}
              onClick={() => setDrawer('feats')}
            ></ToolbarButton>
            {!isDM && (
              <ToolbarButton
                label="Minions / summons"
                icon={<GiMinions />}
                onClick={() => setDrawer('minions')}
              ></ToolbarButton>
            )}
          </>
        )}
        <ToolbarButton
          label="Turn order"
          icon={<GoListOrdered />}
          onClick={() => setDrawer('turnorder')}
        ></ToolbarButton>
      </div>
    </div>
  );
};

export default MapToolbar;
