import React from 'react';
import { map } from 'lodash';
import { useAtom } from 'jotai';
import { Button, Divider } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaDAndD } from 'react-icons/fa';

import { useTimeout } from '../../utils/hooks';
import { selectedCampaignAtom, campaignAtom, isDMAtom } from '../../utils/atoms';
import './Campaign.css';

const Campaign = ({ rtdb }) => {
  const [campaign] = useAtom(selectedCampaignAtom);
  const [campaignData] = useAtom(campaignAtom);
  const [isDM] = useAtom(isDMAtom);

  const [loaded, setLoaded] = React.useState(false);
  const [players, setPlayers] = React.useState([]);

  useTimeout(() => setLoaded(true), 75);

  React.useEffect(() => {
    rtdb.ref(`campaign_players/${campaign}`).on('value', (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const players = map(data, (p) => p);
        setPlayers(players);
      } else {
        setPlayers([]);
      }
    });

    return () => {
      rtdb.ref(`campaign_players/${campaign}`).off();
    };
  }, [rtdb, campaign]);

  return campaignData
    ? loaded && (
        <div className="Campaign">
          <div className="DNDIcon">
            <FaDAndD />
          </div>
          <h1>{campaignData.name}</h1>
          {isDM && <h3>You are a Dungeon Master</h3>}
          <CopyToClipboard text={`${window.location.href}invite/${campaign}`}>
            <Button size="large">Copy invite link</Button>
          </CopyToClipboard>
          <Divider />
          <div className="Lists">
            <div className="Col">
              <h2>Dungeon Master</h2>
              <div>{campaignData.creatorName}</div>
            </div>
            <div className="Col">
              <h2>Players</h2>
              {players.length ? players.map((p, i) => <div key={`${i}`}>{p}</div>) : <i>No players have joined yet</i>}
            </div>
            {/* <div className="Col">Characters list</div> */}
          </div>
        </div>
      )
    : null;
};

export default Campaign;
