import React from 'react';
import { useAtom } from 'jotai';
import { forEach, isEmpty } from 'lodash';
import { Button } from 'antd';

import {
  userAtom,
  isDMAtom,
  characterAtom,
  campaignAtom,
  selectedCampaignAtom,
  turnOrderAtom,
  mapAtom,
  mapsAtom,
  dropObjectAtom,
  dmPreviewMapAtom,
  adjustGridAtom,
  dmSelectedCharacterAtom,
  zoomAtom,
} from '../../utils/atoms';
import { mapContainsCharacter } from '../../utils/utils';
import { useTimeout, usePrevious } from '../../utils/hooks';
import MapDMToolbar from './MapDMToolbar';
import MapDMDrawer from './MapDMDrawer';
import MapToolbar from './MapToolbar';
import MapDrawer from './MapDrawer';
import MapGrid from './MapGrid';
import './Map.css';

const Map = ({ rtdb, ...rest }) => {
  const [user] = useAtom(userAtom);
  const [isDM] = useAtom(isDMAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [campaignData] = useAtom(campaignAtom);
  const [map, setMap] = useAtom(mapAtom);
  const [maps] = useAtom(mapsAtom);
  const [dmPreviewMap, setDMPreviewMap] = useAtom(dmPreviewMapAtom);
  const [adjustingGrid] = useAtom(adjustGridAtom);
  const [character] = useAtom(characterAtom);
  const [dropObject, setDropObject] = useAtom(dropObjectAtom);
  const [, setTurnOrder] = useAtom(turnOrderAtom);
  const [, setZoom] = useAtom(zoomAtom);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const [loaded, setLoaded] = React.useState(false);

  const selectedCharacter = dmSelectedCharacter || user.selectedCharacter;
  const previousCharacter = usePrevious(selectedCharacter);

  useTimeout(() => setLoaded(true), 75);

  const getMapId = React.useCallback(() => {
    if (isDM && dmPreviewMap) return dmPreviewMap;
    if (campaignData && campaignData.currentMap) return campaignData.currentMap;
    return undefined;
  }, [campaignData, isDM, dmPreviewMap]);

  const removeObject = React.useCallback(
    (x, y, id, objects) => {
      if (!campaignData || !map) return;
      const objs = objects || map.grid[x][y].objects;
      return rtdb.ref(`maps/${getMapId()}/grid/${x}/${y}/objects`).set(objs.filter((o) => o.id && o.id !== id));
    },
    [rtdb, campaignData, map, getMapId]
  );

  const addObject = React.useCallback(
    (x, y, object, objects) => {
      if (!campaignData || !map) return;
      const i = objects ? objects.length : map.grid[x][y] ? map.grid[x][y].objects.length : 0;
      return rtdb.ref(`maps/${getMapId()}/grid/${x}/${y}/objects/${i}`).set(object);
    },
    [rtdb, campaignData, map, getMapId]
  );

  const moveObject = React.useCallback(
    (id, x1, y1, x2, y2) => {
      if (!map) return;
      const obj = map.grid[x1][y1].objects.find((o) => o.id === id);
      if (obj) {
        return Promise.all([removeObject(x1, y1, id), addObject(x2, y2, obj)]);
      }
    },
    [map, addObject, removeObject]
  );

  const updateObject = React.useCallback(
    (x, y, id, values, objects) => {
      if (!campaignData || !map) return;
      if (!isEmpty(values)) {
        const objs = objects || map.grid[x][y].objects;
        const gridObject = objs.find((o) => o.id === id);
        if (gridObject) {
          const i = objs.indexOf(gridObject);
          if (!values.image) delete values.image;
          if (!values.size) delete values.size;
          if (!values.speed) delete values.speed;
          if (!values.hp) delete values.hp;
          if (!values.maxhp) delete values.maxhp;
          if (!values.temphp) delete values.temphp;
          return rtdb.ref(`maps/${getMapId()}/grid/${x}/${y}/objects/${i}`).update(values);
        }
      }
    },
    [rtdb, campaignData, map, getMapId]
  );

  // listen for map updates
  React.useEffect(() => {
    const mapId = getMapId();
    if (mapId) {
      const processMap = (m) => {
        const objects = [];
        if (m) {
          const grid = new Array(m.width || 20)
            .fill()
            .map(() => new Array(m.height || 20).fill().map(() => ({ objects: [] })));
          forEach(m.grid, (col, ci) => {
            forEach(col, (row, ri) => {
              const x = Number(ci);
              const y = Number(ri);
              if (row) {
                grid[x][y] = {
                  ...row,
                  objects: row.objects ? row.objects.filter((o) => !!o) : [],
                };
                if (row.objects && row.objects.length) {
                  row.objects.forEach((o) => objects.push({ ...o, x, y }));
                }
              } else {
                grid[x][y] = { objects: [] };
              }
            });
          });
          m.grid = grid;
          m.objects = objects;
          setMap(m);
        }
      };

      if (isDM) {
        processMap(maps[mapId]);
      } else {
        rtdb.ref(`maps/${mapId}`).on('value', (snapshot) => {
          const m = snapshot.val();
          processMap(m);
        });

        return () => {
          rtdb.ref(`maps/${mapId}`).off();
        };
      }
    } else {
      setMap(null);
    }
  }, [rtdb, isDM, maps, setMap, getMapId]);

  // listen for turn order updates
  React.useEffect(() => {
    rtdb.ref(`campaign_turn_order/${campaign}`).on('value', (snapshot) => {
      const t = snapshot.val() || [];
      setTurnOrder(t);
    });

    return () => {
      rtdb.ref(`campaign_turn_order/${campaign}`).off();
    };
  }, [rtdb, campaign, setTurnOrder]);

  // handle character updates
  React.useEffect(() => {
    if (!campaignData || !map || !character || selectedCharacter !== previousCharacter) return;
    if (mapContainsCharacter(map, selectedCharacter)) {
      const object = map.objects.find((o) => o.characterId === selectedCharacter);
      const newValues = {};
      ['name', 'image', 'hp', 'maxhp', 'temphp', 'ac', 'size'].forEach((key) => {
        if (object[key] !== character[key]) newValues[key] = character[key];
      });
      updateObject(object.x, object.y, object.id, newValues);
    }
  }, [rtdb, selectedCharacter, previousCharacter, campaignData, map, character, updateObject]);

  const mapId = getMapId();
  const previousMapId = usePrevious(mapId);
  const [isNewMap, setIsNewMap] = React.useState(false);
  React.useEffect(() => {
    if (mapId !== previousMapId) {
      setIsNewMap(true);
    }
  }, [mapId, previousMapId, setIsNewMap]);
  React.useEffect(() => {
    if (isNewMap && map && map.zoom) {
      setZoom(map.zoom);
      setIsNewMap(false);
    }
  }, [isNewMap, map, setZoom]);

  const renderCharacterOverlay = () => {
    const containsCharacter = user.selectedCharacter && mapContainsCharacter(map, user.selectedCharacter);

    if (!isDM && !dmPreviewMap && (!user.selectedCharacter || !containsCharacter)) {
      const content =
        user.selectedCharacter && !containsCharacter ? (
          <div className="CharacterOverlayContent">
            {dropObject ? (
              <>
                <h2>Click somewhere to place your character</h2>
                <Button onClick={() => setDropObject(undefined)}>Cancel</Button>
              </>
            ) : (
              <>
                <h2>Your character is not on this map</h2>
                {character && (
                  <Button
                    onClick={() => {
                      setDropObject({
                        type: 'player',
                        name: character.name || '???',
                        image: character.image || '',
                        id: user.selectedCharacter,
                        characterId: user.selectedCharacter,
                      });
                    }}
                  >
                    Add {character.name || 'your character'} to the map
                  </Button>
                )}
              </>
            )}
          </div>
        ) : (
          !user.selectedCharacter && (
            <div className="CharacterOverlayContent">
              <h2>You have not selected a character</h2>
            </div>
          )
        );
      return <div className="CharacterOverlay">{content}</div>;
    } else if (dropObject) {
      return (
        <div className="DropOverlay">
          <div className="CharacterOverlayContent">
            <h2>Click somewhere to place the character or object</h2>
            <Button onClick={() => setDropObject(undefined)}>Cancel</Button>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderPreviewOverlay = () => {
    if (!dmPreviewMap || adjustingGrid || !map.width || !map.height) return null;
    return (
      <div className="PreviewOverlay">
        <h2>Previewing this map</h2>
        <Button onClick={() => setDMPreviewMap(undefined)}>Stop previewing</Button>
      </div>
    );
  };

  const renderDMOverlay = () => {
    if (!isDM || !character || !selectedCharacter) return null;
    return (
      <div className="DMOverlay" key={character.name}>
        <h2>{character.name}</h2>
      </div>
    );
  };

  return (
    loaded && (
      <div className="Map" id="map">
        <div className="MainSection">
          <MapGrid
            rtdb={rtdb}
            mapId={getMapId()}
            moveObject={moveObject}
            addObject={addObject}
            removeObject={removeObject}
            updateObject={updateObject}
            {...rest}
          />
        </div>
        {renderCharacterOverlay()}
        {renderPreviewOverlay()}
        {renderDMOverlay()}
        <MapToolbar rtdb={rtdb} {...rest} />
        <MapDrawer rtdb={rtdb} {...rest} />
        {isDM && (
          <>
            <MapDMToolbar rtdb={rtdb} {...rest} />
            <MapDMDrawer rtdb={rtdb} {...rest} />
          </>
        )}
      </div>
    )
  );
};

export default Map;
