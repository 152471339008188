export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function parseDice(diceStr) {
  if (!diceStr || !diceStr.split) return [];
  const diceSplit = diceStr.toLowerCase().split(/[^0-9d-]+/);

  const result = diceSplit.map((dice) => {
    try {
      const itemSplit = dice.split('d');

      if (itemSplit.length === 1) {
        const bonus = Math.floor(Number(itemSplit[0]));
        if (isNaN(bonus)) return null;
        return { bonus };
      }

      if (itemSplit.length === 2) {
        const n1Split = itemSplit[0].split(/[^0-9]+/);
        if (n1Split.length > 1) return null;

        const number = itemSplit[0] === '' ? 1 : Math.floor(Number(itemSplit[0]));
        if (number <= 0 || isNaN(number)) return null;

        const n2Split = itemSplit[1].split(/[^0-9]+/);

        if (n2Split.length === 1) {
          const size = Math.floor(Number(itemSplit[1]));
          if (size <= 1 || isNaN(size)) return null;
          return { number, size };
        }

        if (n2Split.length === 2) {
          const size = Math.floor(Number(n2Split[0]));
          const bonus = Math.floor(Number(n2Split[1]));

          if (size <= 1 || isNaN(size) || isNaN(bonus)) return null;

          const isNeg = itemSplit[1].includes('-');
          return { number, size, bonus: isNeg ? -bonus : bonus };
        }
      }

      return null;
    } catch (err) {
      return null;
    }
  });

  if (result.some((d) => d === null)) return null;
  if (!result.some((d) => !!d.number)) return null;

  return result;
}

export function getDiceString(diceArray) {
  let result = '';
  diceArray.forEach((d) => {
    if (d.number && d.size) {
      result += ` ${d.number}d${d.size}`;
    }
    if (d.bonus) {
      result += ` ${d.bonus > 0 ? `+${d.bonus}` : d.bonus}`;
    }
  });
  return result.trim();
}

export function rollDice(diceArray) {
  if (!diceArray || !diceArray.length) return null;
  try {
    const results = diceArray.map((d) => {
      if (!d.number) return [d.bonus];
      const results = new Array(d.number).fill(0).map(() => getRandomInt(1, d.size));
      if (d.bonus) results.push(d.bonus);
      return results;
    });
    const rolled = [].concat.apply([], results);
    const result = rolled.reduce((acc, n) => acc + n, 0);
    return {
      input: getDiceString(diceArray),
      rolled,
      result,
    };
  } catch (err) {
    return null;
  }
}

export function getDiceAverage(diceArray) {
  if (!diceArray || !diceArray.length) return null;
  try {
    const results = diceArray.map((d) => {
      if (!d.number && d.bonus) return [d.bonus];
      const results = new Array(d.number).fill(0).map(() => (d.size + 1) / 2);
      if (d.bonus) results.push(d.bonus);
      return results;
    });
    const rolled = [].concat.apply([], results);
    return rolled.reduce((acc, n) => acc + n, 0);
  } catch (err) {
    return null;
  }
}
