import React from 'react';
import { AutoSizer, Grid } from 'react-virtualized';
import { isEqual } from 'lodash';
import { useDrop } from 'react-dnd';
import { useAtom } from 'jotai';

import MapCell from './MapCell';
import MapGridAdjust from './MapGridAdjust';
import { mapAtom, adjustGridAtom, gridRefAtom, zoomAtom, campaignAtom, isDMAtom, userAtom } from '../../utils/atoms';
import { usePrevious } from '../../utils/hooks';
import { URLS } from '../../config';
import './MapGrid.css';

const MapGrid = ({ rtdb, auth, mapId, moveObject, addObject, removeObject, updateObject }) => {
  const [map] = useAtom(mapAtom);
  const [adjustingGrid] = useAtom(adjustGridAtom);
  const [, setGridRef] = useAtom(gridRefAtom);
  const [zoom] = useAtom(zoomAtom);
  const [campaignData] = useAtom(campaignAtom);
  const [isDM] = useAtom(isDMAtom);
  const [user] = useAtom(userAtom);

  const [animationObjects, setAnimationObjects] = React.useState({});

  const gridRef = React.useRef();
  const prevMap = usePrevious(map);
  const prevGrid = prevMap && prevMap.grid;

  const cellWidth = map ? map.cellWidth * zoom : 0;
  const cellHeight = map ? map.cellHeight * zoom : 0;

  // recalculate grid size when cell size changes
  React.useEffect(() => {
    if (cellWidth && cellHeight && gridRef && gridRef.current) {
      gridRef.current.recomputeGridSize();
    }
  }, [gridRef, cellWidth, cellHeight]);

  // animate objects which moved since the last grid update
  React.useEffect(() => {
    if (map && map.grid && prevGrid) {
      const newAnimObjects = { ...animationObjects };
      map.grid.forEach((col, x) =>
        col.forEach((row, y) => {
          if (!prevGrid[x] || !prevGrid[x][y]) return;
          prevGrid[x][y].objects.forEach((o) => {
            if (!row || !row.objects || !row.objects.some((p) => p.id === o.id)) {
              if (!animationObjects[o.id] || animationObjects[o.id].x !== x || animationObjects[o.id].y !== y) {
                newAnimObjects[o.id] = { x, y };
              }
            }
          });
        })
      );
      if (!isEqual(animationObjects, newAnimObjects)) setAnimationObjects(newAnimObjects);
    }
  }, [map, prevGrid, animationObjects]);

  // set up drop area
  const [{ dragInitial, canDrop }, drop] = useDrop(() => {
    return {
      accept: 'obj',
      canDrop: (item) => {
        return (
          isDM ||
          (user.selectedCharacter &&
            (item.characterId === user.selectedCharacter || item.ownerCharacterId === user.selectedCharacter))
        );
      },
      drop: (item, monitor) => {
        if (item.columnIndex === undefined || item.rowIndex === undefined) return;
        const dragOffset = monitor.getDifferenceFromInitialOffset();
        const columnIndex = Math.round(item.columnIndex + dragOffset.x / cellWidth);
        const rowIndex = Math.round(item.rowIndex + dragOffset.y / cellHeight);
        if (item.columnIndex === columnIndex && item.rowIndex === rowIndex) return;
        moveObject(item.id, item.columnIndex, item.rowIndex, columnIndex, rowIndex);
      },
      collect: (monitor) => {
        const dragInitial = monitor.getInitialSourceClientOffset();
        return {
          // isOver: !!monitor.isOver(),
          canDrop: !!monitor.canDrop(),
          dragInitial,
        };
      },
    };
  }, [map, cellWidth, cellHeight]);

  // save grid reference
  React.useEffect(() => {
    if (gridRef.current) {
      setGridRef(gridRef);
    }
  }, [gridRef, setGridRef]);

  if (!map || !map.grid) return <div className="NoMap">There is no map currently selected for this campaign</div>;

  if (adjustingGrid || !map.width || !map.cellWidth || !map.height || !map.cellHeight)
    return <MapGridAdjust rtdb={rtdb} mapId={mapId} />;

  const cellRenderer = ({ columnIndex, rowIndex, style, key }) => (
    <MapCell
      rtdb={rtdb}
      auth={auth}
      cell={map.grid[columnIndex][rowIndex]}
      canDrop={canDrop}
      dragInitial={dragInitial}
      cellWidth={cellWidth}
      cellHeight={cellHeight}
      columnIndex={columnIndex}
      rowIndex={rowIndex}
      animationObjects={animationObjects}
      setAnimationObjects={setAnimationObjects}
      addObject={addObject}
      removeObject={removeObject}
      updateObject={updateObject}
      style={style}
      key={key}
    />
  );

  let className = 'MapGrid';
  if (campaignData && campaignData.timeFrozen) {
    className += ' Frozen';
    if (!isDM) className += ' NoMove';
  }

  return (
    <AutoSizer>
      {({ width, height }) => (
        <div className={className} id="mapGrid" style={{ width, height }} ref={drop}>
          <Grid
            ref={gridRef}
            columnCount={map.width}
            columnWidth={cellWidth}
            height={height}
            rowCount={map.height}
            rowHeight={cellHeight}
            width={width}
            overscanColumnCount={20}
            overscanRowCount={20}
            containerStyle={{
              backgroundImage: `url(${URLS.bucket}/${map.image})`,
              backgroundSize: `${map.width * cellWidth}px ${map.height * cellHeight}px`,
            }}
            cellRenderer={cellRenderer}
          />
        </div>
      )}
    </AutoSizer>
  );
};

export default MapGrid;
