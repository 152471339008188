import React from 'react';
import { sortBy } from 'lodash';
import { Button, Form, Input, Checkbox, Modal, Collapse, Popconfirm } from 'antd';
import { useAtom } from 'jotai';
import { BsFillChatDotsFill, BsPlusCircle } from 'react-icons/bs';
import { GiBattleGear } from 'react-icons/gi';
import { MdUndo } from 'react-icons/md';

import {
  userAtom,
  selectedCampaignAtom,
  characterAtom,
  characterInventoryAtom,
  dmSelectedCharacterAtom,
} from '../../utils/atoms';
import { sendChat } from '../../utils/db';
import { INVENTORY_SORT_OPTIONS } from '../../utils/sort';
import SortMenu from '../../components/SortMenu';
import './CharacterInventory.css';
import StatEffectList from '../../components/StatEffectList';

const CharacterInventory = ({ rtdb }) => {
  const [user] = useAtom(userAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [character] = useAtom(characterAtom);
  const [inventory] = useAtom(characterInventoryAtom);
  const [inventoryForm] = Form.useForm();
  const [inventoryModal, setInventoryModal] = React.useState(null);
  const [sort, setSort] = React.useState(INVENTORY_SORT_OPTIONS[2]);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const selectedCharacter = dmSelectedCharacter || user.selectedCharacter;

  const mapItemModifiers = (modifiers, itemId, equipped) => ({
    modifiers: modifiers.map((m) => {
      const stat = m.stat[1];
      return { ...m, stat };
    }),
    itemId,
    equipped,
  });

  const onSubmit = (values) => {
    if (inventoryModal === 'new') {
      rtdb
        .ref(`character_inventory/${selectedCharacter}/${inventory.length}`)
        .set(values)
        .then(() => {
          inventoryForm.resetFields();
          setInventoryModal(null);
        });
      const modifiers = values.modifiers.length && mapItemModifiers(values.modifiers, inventory.length, false);
      if (modifiers) rtdb.ref(`characters/${selectedCharacter}/itemModifiers/${inventory.length}`).set(modifiers);
    } else if (inventoryModal < inventory.length) {
      const equipped = !!(inventory && inventory[inventoryModal] && inventory[inventoryModal].equipped);
      rtdb
        .ref(`character_inventory/${selectedCharacter}/${inventoryModal}`)
        .set({ ...values, equipped })
        .then(() => {
          inventoryForm.resetFields();
          setInventoryModal(null);
        });
      const modifiers = values.modifiers.length && mapItemModifiers(values.modifiers, inventoryModal, equipped);
      if (modifiers) rtdb.ref(`characters/${selectedCharacter}/itemModifiers/${inventoryModal}`).set(modifiers);
      else rtdb.ref(`characters/${selectedCharacter}/itemModifiers/${inventoryModal}`).remove();
    }
  };

  const onEquip = (e, i, equipped) => {
    e.stopPropagation();
    rtdb.ref(`character_inventory/${selectedCharacter}/${i}/equipped`).set(!equipped);
    rtdb.ref(`characters/${selectedCharacter}/itemModifiers/${i}/equipped`).set(!equipped);
  };

  const onShareToChat = (e, item) => {
    e.stopPropagation();
    sendChat(rtdb, campaign, character, {
      type: 'shareitem',
      item,
    });
  };

  const onEdit = (i) => {
    inventoryForm.setFieldsValue(inventory[i]);
    setInventoryModal(i);
  };

  const onDelete = (i) => {
    rtdb.ref(`character_inventory/${selectedCharacter}/${i}`).remove();
    rtdb.ref(`characters/${selectedCharacter}/itemModifiers/${i}`).remove();
  };

  const renderInventoryList = () => {
    const sorted = sortBy(
      inventory.filter((a) => !!a),
      (a) => (sort.pre ? sort.pre(a) : a[sort.field])
    );
    const getIndex = (item) => inventory.indexOf(item);
    return (
      <Collapse>
        {sorted.map((item, i) => (
          <Collapse.Panel
            header={
              <div className="ItemHeader">
                <div className="ItemName">{item.itemName}</div>
                <div className="ItemNumber">x{item.number || 1}</div>
                <div className="EquipWrapper">
                  {item.equippable ? (
                    <Button
                      icon={item.equipped ? <MdUndo /> : <GiBattleGear />}
                      onClick={(e) => onEquip(e, getIndex(item), item.equipped)}
                    >
                      {item.equipped ? 'Unequip' : 'Equip'}
                    </Button>
                  ) : (
                    <div />
                  )}
                </div>
                <Button icon={<BsFillChatDotsFill />} onClick={(e) => onShareToChat(e, item)}>
                  Share to chat
                </Button>
              </div>
            }
            key={i}
          >
            <div className="ItemContent">
              {item.description && <div className="ItemDescription">{item.description}</div>}
              <Button type="link" onClick={() => onEdit(getIndex(item))}>
                Edit
              </Button>
              <Popconfirm
                title="Are you sure you want to delete this item?"
                onConfirm={() => onDelete(getIndex(item))}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            </div>
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  };

  const renderInventoryModal = () => {
    return (
      <Modal
        title={inventoryModal === 'new' ? 'New item' : 'Edit item'}
        footer={null}
        centered
        destroyOnClose
        visible={inventoryModal !== null}
        onCancel={() => {
          inventoryForm.resetFields();
          setInventoryModal(null);
        }}
      >
        <div className="InventoryModalContent">
          <Form name="inventory" form={inventoryForm} onFinish={onSubmit}>
            <Form.Item
              label="Item name"
              name="itemName"
              initialValue=""
              rules={[{ required: true, message: 'Enter a name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Equippable" name="equippable" valuePropName="checked" initialValue={false}>
              <Checkbox />
            </Form.Item>
            <Form.Item label="How many" name="number" initialValue="1">
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" initialValue="">
              <Input.TextArea rows={4} />
            </Form.Item>
            <StatEffectList message="Note: items must be equipped to gain stat effects" />
            <Form.Item>
              <div className="ModalButtonWrapper">
                <Button size="large" htmlType="submit">
                  {inventoryModal === 'new' ? 'Create' : 'Save'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };

  return (
    <div className="Inventory">
      <SortMenu options={INVENTORY_SORT_OPTIONS} sort={sort} setSort={setSort} />
      <div className="InventoryList">
        {inventory.length ? renderInventoryList() : <div className="Empty">You don't have any items yet</div>}
      </div>
      <div className="ButtonWrapper">
        <Button
          type="link"
          size="large"
          className="NewButton"
          icon={<BsPlusCircle />}
          onClick={() => setInventoryModal('new')}
        >
          New item
        </Button>
      </div>
      {renderInventoryModal()}
    </div>
  );
};

export default CharacterInventory;
