import React from 'react';
import { useAtom } from 'jotai';
import { v4 as uuidv4 } from 'uuid';
import { sortBy, map } from 'lodash';
import { Drawer, Collapse, Button, Tooltip, List, Form, Input, Modal, InputNumber, Avatar, Popconfirm } from 'antd';
import {
  GiBoltSpellCast,
  GiDiceTwentyFacesTwenty,
  GiD12,
  GiD10,
  GiDiceEightFacesEight,
  GiPerspectiveDiceSix,
  GiD4,
  GiCrownCoin,
} from 'react-icons/gi';
import { FaDiceD20, FaTrash } from 'react-icons/fa';
import { BsFillChatDotsFill, BsPlusCircle } from 'react-icons/bs';

import {
  mapDrawerAtom,
  selectedCampaignAtom,
  turnOrderAtom,
  userAtom,
  characterAtom,
  characterAttacksAtom,
  characterSpellsAtom,
  characterFeatsAtom,
  characterResourcesAtom,
  characterInventoryAtom,
  characterCurrenciesAtom,
  characterMinionsAtom,
  isDMAtom,
  dropObjectAtom,
  dmSelectedCharacterAtom,
} from '../../utils/atoms';
import {
  sortList,
  ATTACK_SORT_OPTIONS,
  SPELL_SORT_OPTIONS,
  RESOURCE_SORT_OPTIONS,
  FEAT_SORT_OPTIONS,
  INVENTORY_SORT_OPTIONS,
  MINION_SORT_OPTIONS,
  TURN_ORDER_SORT_OPTIONS,
} from '../../utils/sort';
import SortMenu from '../../components/SortMenu';
import SimpleNPCForm from '../../components/SimpleNPCForm';
import {
  formatSpellLevel,
  getAbilityBonus,
  withSign,
  getProficiencyBonus,
  parseLevel,
  getSpellAttackBonus,
  getSpellSaveDC,
  delay,
  getStatWithModifier,
} from '../../utils/utils';
import { sendChat, rollAttack, saveToTurnOrder } from '../../utils/db';
import { parseDice, rollDice } from '../../utils/dice';
import { URLS } from '../../config';
import './MapDrawer.css';

const PanelHeader = ({ name, first, icon, tooltip, onClick }) => (
  <div className="PanelHeader">
    {first && <div className="First">{first}</div>}
    <div className="Name">{name}</div>
    <Tooltip placement="right" title={tooltip}>
      <Button icon={icon} onClick={onClick} />
    </Tooltip>
  </div>
);

const CollapseList = ({
  title,
  hideTitle,
  list,
  sortOptions,
  sort,
  setSort,
  renderPanelHeader,
  renderPanelContent,
}) => {
  return (
    <div className="List">
      {!hideTitle && <h2>{title}</h2>}
      {sort && sortOptions && sortOptions.length > 1 && (
        <SortMenu options={sortOptions} sort={sort} setSort={setSort} />
      )}
      {list.length ? (
        <Collapse>
          {list.map(
            (a) =>
              a && (
                <Collapse.Panel header={renderPanelHeader(a)}>
                  <div className="PanelContent">{renderPanelContent(a)}</div>
                </Collapse.Panel>
              )
          )}
        </Collapse>
      ) : (
        <i>
          You have no {title.toLowerCase()}
          <br />
          Go to your character sheet to add some
        </i>
      )}
    </div>
  );
};

const MapDrawer = ({ rtdb, storage }) => {
  const [drawer, setDrawer] = useAtom(mapDrawerAtom);
  const [sort, setSort] = React.useState();
  const [user] = useAtom(userAtom);
  const [isDM] = useAtom(isDMAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [turnOrder] = useAtom(turnOrderAtom);
  const [character] = useAtom(characterAtom);
  const [attacks] = useAtom(characterAttacksAtom);
  const [spells] = useAtom(characterSpellsAtom);
  const [resources] = useAtom(characterResourcesAtom);
  const [feats] = useAtom(characterFeatsAtom);
  const [inventory] = useAtom(characterInventoryAtom);
  const [currencies] = useAtom(characterCurrenciesAtom);
  const [minions, setMinions] = useAtom(characterMinionsAtom);
  const [, setDropObject] = useAtom(dropObjectAtom);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const [showTurnOrderModal, setShowTurnOrderModal] = React.useState(false);
  const [minionModal, setMinionModal] = React.useState();
  const [minionSort, setMinionSort] = React.useState(MINION_SORT_OPTIONS[0]);
  const [minionFilter, setMinionFilter] = React.useState();
  const [minionModalImage, setMinionModalImage] = React.useState();
  const [isMinionUploading, setIsMinionUploading] = React.useState(false);
  const [minionUploadProgress, setMinionUploadingProgress] = React.useState(0);

  const [currenciesForm] = Form.useForm();

  const selectedCharacter = dmSelectedCharacter || user.selectedCharacter;

  // listen for minions
  React.useEffect(() => {
    rtdb.ref(`character_minions/${selectedCharacter}`).on('value', (snapshot) => {
      const data = snapshot.val();
      setMinions(data);
    });

    return () => {
      rtdb.ref(`character_minions/${selectedCharacter}`).off();
    };
  }, [rtdb, selectedCharacter, setMinions]);

  // set default sort
  React.useEffect(() => {
    switch (drawer) {
      case 'attacks':
        setSort(ATTACK_SORT_OPTIONS[1]);
        return;
      case 'spells':
        setSort(SPELL_SORT_OPTIONS[1]);
        return;
      case 'resources':
        setSort(RESOURCE_SORT_OPTIONS[0]);
        return;
      case 'feats':
        setSort(FEAT_SORT_OPTIONS[0]);
        return;
      case 'inventory':
        setSort(INVENTORY_SORT_OPTIONS[2]);
        return;
      case 'turnorder':
        setSort(TURN_ORDER_SORT_OPTIONS[0]);
        return;
      default:
        setSort(undefined);
        return;
    }
  }, [drawer]);

  // update currencies form
  React.useEffect(() => {
    currenciesForm.setFieldsValue(currencies);
  }, [currenciesForm, currencies]);

  const onClearTurnOrder = () => {
    rtdb.ref(`campaign_turn_order/${campaign}`).remove();
  };

  const onRoll = (bonus, stat) => {
    const dice = parseDice(`1d20 ${bonus === 0 ? '' : bonus}`);
    const result = rollDice(dice);
    if (stat === 'Initiative') saveToTurnOrder(rtdb, campaign, character, turnOrder, selectedCharacter, result);
    sendChat(rtdb, campaign, character, {
      type: 'stat',
      stat,
      result,
      bonus: withSign(bonus),
    });
  };

  const onRollDice = (diceType) => {
    const dice = parseDice(diceType);
    const result = rollDice(dice);
    sendChat(rtdb, campaign, character, {
      type: 'roll',
      result,
    });
  };

  const saveField = (name, value, root = 'character_currencies') => {
    if (root === 'character_currencies' && currencies && value === currencies[name]) return;
    rtdb.ref(`${root}/${selectedCharacter}/${name}`).set(value);
  };

  const onAddToTurnOrder = (values) => {
    const { name, bonus } = values;
    let roll = values.roll;
    if (!roll) {
      const dice = parseDice(bonus ? `1d20 ${bonus}` : '1d20');
      const result = rollDice(dice);
      roll = result.result;
      sendChat(rtdb, campaign, character, {
        stat: 'Initiative',
        result,
        bonus: withSign(values.bonus || 0),
        sender: name,
        type: 'stat',
      });
    }
    rtdb.ref(`campaign_turn_order/${campaign}/${turnOrder.length}`).set({ name, roll });
    setShowTurnOrderModal(false);
  };

  const onRemoveTurnOrder = (item) => {
    const j = turnOrder.indexOf(item);
    const newTurnOrder = turnOrder.filter((t, i) => i !== j);
    rtdb.ref(`campaign_turn_order/${campaign}`).set(newTurnOrder);
  };

  const onSaveMinion = async (values) => {
    const newMinionData = { ...values, image: minionModalImage };
    if (values.tags) {
      newMinionData.tags = values.tags.split(' ');
    }
    if (values.maxhp) {
      newMinionData.hp = values.maxhp;
    }
    if (minionModal === 'new') {
      rtdb.ref(`character_minions/${selectedCharacter}`).push(newMinionData);
    } else {
      rtdb.ref(`character_minions/${selectedCharacter}/${minionModal}`).update(newMinionData);
    }
    setMinionModal(undefined);
    setMinionModalImage(undefined);
  };

  const renderRollButton = (label, stat, proficiency) => {
    let bonusWithModifiers = 0;
    const statValue = character && character[stat] ? character[stat] : 0;
    const statWithModifier = getStatWithModifier(character, stat, statValue);
    const bonusWithStatModifiers = getStatWithModifier(character, `${stat}Bonus`, getAbilityBonus(statWithModifier));

    if (stat === 'initiative') {
      bonusWithModifiers = Number(character.initiative);
    } else if (proficiency) {
      const base = proficiency.split('Proficient')[0];
      if (base.endsWith('Save')) {
        const modifierName = `${base}Bonus`;
        bonusWithModifiers = getStatWithModifier(character, modifierName, bonusWithStatModifiers);
      } else {
        bonusWithModifiers = getStatWithModifier(character, base, bonusWithStatModifiers);
      }

      if (character[proficiency]) {
        bonusWithModifiers += getProficiencyBonus(parseLevel(character.classLevel));
      }
    } else {
      bonusWithModifiers = bonusWithStatModifiers;
    }

    return (
      <Button icon={<GiDiceTwentyFacesTwenty />} onClick={() => onRoll(bonusWithModifiers, label)}>
        {label} ({withSign(bonusWithModifiers)})
      </Button>
    );
  };

  const renderDiceButton = (diceType, icon) => (
    <Button icon={icon} onClick={() => onRollDice(diceType)}>
      {diceType}
    </Button>
  );

  const renderRolls = () => {
    return (
      <div className="Rolls">
        <h2>Roll a check</h2>
        <Collapse defaultActiveKey={['3']} accordion>
          <Collapse.Panel key="0" header={<div className="PanelHeader">Basic abilities</div>}>
            {renderRollButton('Strength', 'str')}
            {renderRollButton('Dexterity', 'dex')}
            {renderRollButton('Constitution', 'con')}
            {renderRollButton('Intelligence', 'int')}
            {renderRollButton('Wisdom', 'wis')}
            {renderRollButton('Charisma', 'cha')}
          </Collapse.Panel>
          <Collapse.Panel key="1" header={<div className="PanelHeader">Saving throws</div>}>
            {renderRollButton('Strength save', 'str', 'strSaveProficient')}
            {renderRollButton('Dexterity save', 'dex', 'dexSaveProficient')}
            {renderRollButton('Constitution save', 'con', 'conSaveProficient')}
            {renderRollButton('Intelligence save', 'int', 'intSaveProficient')}
            {renderRollButton('Wisdom save', 'wis', 'wisSaveProficient')}
            {renderRollButton('Charisma save', 'cha', 'chaSaveProficient')}
          </Collapse.Panel>
          <Collapse.Panel key="2" header={<div className="PanelHeader">Skills</div>}>
            {renderRollButton('Acrobatics', 'dex', 'acrobaticsProficient')}
            {renderRollButton('Animal Handling', 'wis', 'animalProficient')}
            {renderRollButton('Arcana', 'int', 'arcanaProficient')}
            {renderRollButton('Athletics', 'str', 'athleticsProficient')}
            {renderRollButton('Deception', 'cha', 'deceptionProficient')}
            {renderRollButton('History', 'int', 'historyProficient')}
            {renderRollButton('Insight', 'wis', 'insightProficient')}
            {renderRollButton('Intimidation', 'cha', 'intimidationProficient')}
            {renderRollButton('Investigation', 'int', 'investigationProficient')}
            {renderRollButton('Medicine', 'wis', 'medicineProficient')}
            {renderRollButton('Nature', 'int', 'natureProficient')}
            {renderRollButton('Perception', 'wis', 'perceptionProficient')}
            {renderRollButton('Performance', 'cha', 'performanceProficient')}
            {renderRollButton('Persuasion', 'cha', 'persuasionProficient')}
            {renderRollButton('Religion', 'int', 'religionProficient')}
            {renderRollButton('Sleight of hand', 'dex', 'sleightProficient')}
            {renderRollButton('Stealth', 'dex', 'stealthProficient')}
            {renderRollButton('Survival', 'wis', 'survivalProficient')}
          </Collapse.Panel>
          <Collapse.Panel key="3" header={<div className="PanelHeader">Other</div>}>
            {renderRollButton('Initiative', 'initiative')}
            {renderDiceButton('D20', <GiDiceTwentyFacesTwenty />)}
            {renderDiceButton('D12', <GiD12 />)}
            {renderDiceButton('D10', <GiD10 />)}
            {renderDiceButton('D8', <GiDiceEightFacesEight />)}
            {renderDiceButton('D6', <GiPerspectiveDiceSix />)}
            {renderDiceButton('D4', <GiD4 />)}
            {renderDiceButton('D2', <GiCrownCoin />)}
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  };

  const renderAttacks = () => {
    const sorted = sortList(attacks, sort);
    const onRoll = (e, attack) => {
      e.stopPropagation();
      rollAttack(rtdb, campaign, character, attack);
    };
    return (
      <CollapseList
        title="Attacks"
        list={sorted}
        sortOptions={ATTACK_SORT_OPTIONS}
        sort={sort}
        setSort={setSort}
        renderPanelHeader={(a) => (
          <PanelHeader
            name={a.attackName}
            first={withSign(a.hitBonus)}
            icon={<FaDiceD20 />}
            tooltip="Roll attack"
            onClick={(e) => onRoll(e, a)}
          />
        )}
        renderPanelContent={(a) => (
          <>
            <div>
              <b>Damage:</b> {a.damage} {a.damageType}
            </div>
            {a.description && <div>{a.description}</div>}
          </>
        )}
      />
    );
  };

  const renderSpells = () => {
    const sorted = sortList(spells, sort);
    const onCast = (e, spell) => {
      e.stopPropagation();
      sendChat(rtdb, campaign, character, {
        type: 'spell',
        spell,
      });
    };
    const spellAttackBonus = withSign(getSpellAttackBonus(character));
    return (
      <>
        <h2>Spells</h2>
        <div className="SpellAttackBonus">
          <div className="Label">Spell attack bonus:</div>
          <Tooltip title="Roll" placement="right">
            <Button
              type="link"
              className="Value"
              onClick={() =>
                rollAttack(rtdb, campaign, character, { attackName: 'Spell attack', hitBonus: spellAttackBonus })
              }
            >
              {spellAttackBonus}
            </Button>
          </Tooltip>
        </div>
        <div className="SpellSaveDC">
          <div className="Label">Spell save DC:</div>
          <div className="Value">{getSpellSaveDC(character)}</div>
        </div>
        <CollapseList
          title="Spells"
          hideTitle
          list={sorted}
          sortOptions={SPELL_SORT_OPTIONS}
          sort={sort}
          setSort={setSort}
          renderPanelHeader={(a) => (
            <PanelHeader
              name={a.spellName}
              first={formatSpellLevel(a.level, true)}
              icon={<GiBoltSpellCast />}
              tooltip="Cast spell"
              onClick={(e) => onCast(e, a)}
            />
          )}
          renderPanelContent={(a) => (
            <>
              <div>
                <b>Range:</b> {a.range}
              </div>
              <div>
                <b>Cast time:</b> {a.castTime}
              </div>
              {a.duration && (
                <div>
                  <b>Duration:</b> {a.duration}
                </div>
              )}
              {a.requirements && (
                <div>
                  <b>Requirements:</b> {a.requirements}
                </div>
              )}
              {a.description && <div>{a.description}</div>}
            </>
          )}
        />
      </>
    );
  };

  const renderResources = () => {
    const sorted = sortList(resources, sort);
    const getIndex = (resource) => resources.indexOf(resource);
    return (
      <div className="Resources">
        <h2>Resources</h2>
        {sort && <SortMenu options={RESOURCE_SORT_OPTIONS} sort={sort} setSort={setSort} />}
        {sorted.length ? (
          <List
            itemLayout="horizontal"
            dataSource={sorted}
            renderItem={(resource) => (
              <List.Item>
                <div className="Resource">
                  <div className="Name">{resource.resourceName}</div>
                  <div className="Values">
                    <div className="Current" style={Number(resource.current) <= 0 ? { color: 'red' } : undefined}>
                      {resource.current} / {resource.max}
                    </div>
                    <Button
                      size="small"
                      disabled={Number(resource.current) === 0}
                      onClick={() => {
                        const newCurrent = Number(resource.current) - 1;
                        if (newCurrent < 0) return;
                        rtdb
                          .ref(`character_resources/${selectedCharacter}/${getIndex(resource)}/current`)
                          .set(newCurrent);
                      }}
                    >
                      -
                    </Button>
                    <Button
                      size="small"
                      disabled={Number(resource.current) >= Number(resource.max)}
                      onClick={() => {
                        const newCurrent = Number(resource.current) + 1;
                        if (newCurrent > Number(resource.max)) return;
                        rtdb
                          .ref(`character_resources/${selectedCharacter}/${getIndex(resource)}/current`)
                          .set(newCurrent);
                      }}
                    >
                      +
                    </Button>
                  </div>
                </div>
              </List.Item>
            )}
          />
        ) : (
          <i>
            You have no resources
            <br />
            Go to your character sheet to add some
          </i>
        )}
      </div>
    );
  };

  const renderFeats = () => {
    const sorted = sortList(feats, sort);
    const onShare = (e, feat) => {
      e.stopPropagation();
      sendChat(rtdb, campaign, character, {
        type: 'sharefeat',
        feat,
      });
    };
    return (
      <CollapseList
        title="Features / Traits"
        list={sorted}
        sortOptions={FEAT_SORT_OPTIONS}
        sort={sort}
        setSort={setSort}
        renderPanelHeader={(a) => (
          <PanelHeader
            name={a.featName}
            icon={<BsFillChatDotsFill />}
            tooltip="Share to chat"
            onClick={(e) => onShare(e, a)}
          />
        )}
        renderPanelContent={(a) => <>{a.description && <div>{a.description}</div>}</>}
      />
    );
  };

  const renderCurrencyField = (label, name) => {
    return (
      <div className="Currency">
        <Form.Item label={label} name={name} initialValue={currencies[name]}>
          <InputNumber
            onKeyUp={delay((e) => saveField(name, e.target.value), 500)}
            onBlur={(e) => saveField(name, e.target.value)}
          />
        </Form.Item>
      </div>
    );
  };

  const renderInventory = () => {
    const sorted = sort
      ? sortBy(
          inventory.filter((i) => !!i),
          (a) => (sort.pre ? sort.pre(a) : a[sort.field])
        )
      : inventory;
    const onShare = (e, item) => {
      e.stopPropagation();
      sendChat(rtdb, campaign, character, {
        type: 'shareitem',
        item,
      });
    };
    return (
      <div className="Inventory">
        <h2>Items / Equipment</h2>
        <Form form={currenciesForm} name="currencies">
          <div className="Currencies">
            {renderCurrencyField('Gold', 'gold')}
            {renderCurrencyField('Silver', 'silver')}
            {renderCurrencyField('Copper', 'copper')}
          </div>
        </Form>
        <CollapseList
          title="Items / Equipment"
          hideTitle
          list={sorted}
          sortOptions={INVENTORY_SORT_OPTIONS}
          sort={sort}
          setSort={setSort}
          renderPanelHeader={(a) => (
            <PanelHeader
              name={a.itemName}
              icon={<BsFillChatDotsFill />}
              tooltip="Share to chat"
              onClick={(e) => onShare(e, a)}
            />
          )}
          renderPanelContent={(a) => <>{a.description && <div>{a.description}</div>}</>}
        />
      </div>
    );
  };

  const renderMinionModal = () => {
    const isExisting = minionModal && minionModal !== 'new';
    const minion = isExisting && minions ? minions[minionModal] : {};
    return (
      <Modal
        title={`${isExisting ? 'Edit' : 'New'} minion / summon`}
        footer={null}
        centered
        destroyOnClose
        width={700}
        visible={!!minionModal}
        onCancel={() => {
          setMinionModal(undefined);
          setMinionModalImage(undefined);
        }}
      >
        <SimpleNPCForm
          formName="minion"
          nameVar="minionName"
          existing={isExisting && minion}
          onFinish={onSaveMinion}
          noImageText="Click to upload an image for your minion / summon"
          image={minionModalImage}
          setImage={setMinionModalImage}
          isUploading={isMinionUploading}
          setIsUploading={setIsMinionUploading}
          uploadProgress={minionUploadProgress}
          setUploadingProgress={setMinionUploadingProgress}
          storageRef={storage.ref('icons')}
          message={isExisting && "Note: editing this minion won't update existing ones on the map"}
        />
      </Modal>
    );
  };

  const renderMinionItem = (minion) => {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar size={56} src={`${URLS.thumbs64}/${minion.image}`} />}
          title={minion.minionName}
          description={
            <div className="MinionDescription">
              <div className="Row">
                <Button
                  onClick={() => {
                    setDropObject({
                      ...minion,
                      name: minion.minionName,
                      type: 'minion',
                      id: uuidv4(),
                      ownerCharacterId: selectedCharacter,
                      minionId: minion.id,
                    });
                    setDrawer(undefined);
                  }}
                  disabled={false}
                >
                  Place on map
                </Button>
                <Button
                  onClick={() => {
                    setMinionModalImage(minion.image);
                    setMinionModal(minion.id);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete this minion / summon?"
                  okText="Delete"
                  cancelText="Cancel"
                  onConfirm={() => {
                    rtdb.ref(`character_minions/${selectedCharacter}/${minion.id}`).remove();
                  }}
                >
                  <Button>Delete</Button>
                </Popconfirm>
              </div>
            </div>
          }
        />
      </List.Item>
    );
  };

  const filterMinions = (minionArray) => {
    if (!minionArray || !minionFilter) return minionArray;
    return minionArray.filter(
      (m) => m.minionName.includes(minionFilter) || (m.tags && m.tags.some((t) => t.includes(minionFilter)))
    );
  };

  const renderMinions = () => {
    const minionArray = map(minions, (m, id) => ({ ...m, id }));
    const filtered = filterMinions(minionArray);
    const sorted = sortList(filtered, minionSort);
    return (
      <div className="Minions">
        <h2>Minions / Summons</h2>
        <Button size="large" className="WideButton" icon={<BsPlusCircle />} onClick={() => setMinionModal('new')}>
          New minion / summon
        </Button>
        <div className="MinionSearch">
          <Input
            allowClear
            placeholder="Type here to filter by name or tags"
            onChange={delay((e) => {
              setMinionFilter(e.target.value);
            }, 250)}
          />
        </div>
        <SortMenu sort={minionSort} setSort={setMinionSort} options={MINION_SORT_OPTIONS} />
        {minionArray.length ? (
          <List itemLayout="horizontal" dataSource={sorted} renderItem={renderMinionItem} />
        ) : (
          <i>No minions were found</i>
        )}
        {renderMinionModal()}
      </div>
    );
  };

  const renderTurnOrderModal = () => (
    <Modal
      title="Add to turn order"
      footer={null}
      centered
      destroyOnClose
      visible={!!showTurnOrderModal}
      onCancel={() => {
        setShowTurnOrderModal(false);
      }}
    >
      <Form name="turnOrder" onFinish={onAddToTurnOrder}>
        <div className="TurnOrderModalContent">
          <Form.Item
            label="Character name"
            name="name"
            initialValue=""
            rules={[{ required: true, message: 'Enter a name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Initiative roll"
            name="roll"
            initialValue=""
            extra="Leave this blank to roll for initiative"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Initiative bonus"
            name="bonus"
            initialValue=""
            extra="This will be ignored if you specify the roll above"
          >
            <InputNumber />
          </Form.Item>
          <div className="ModalButtonWrapper">
            <Button size="large" htmlType="submit">
              Add
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );

  const renderTurnOrder = () => {
    const sorted = sortList(turnOrder, sort);
    return (
      <div className="TurnOrder">
        <h2>Turn Order</h2>
        <div className="Controls">
          {selectedCharacter && (
            <Button
              size="large"
              icon={<FaDiceD20 />}
              disabled={turnOrder.some((t) => t.characterId === selectedCharacter)}
              onClick={() => onRoll(Number(character.initiative), 'Initiative')}
            >
              Roll initiative
            </Button>
          )}
          {isDM && (
            <>
              <Button size="large" icon={<BsPlusCircle />} onClick={() => setShowTurnOrderModal(true)}>
                Manually add somebody
              </Button>
              <Button size="large" icon={<FaTrash />} disabled={!turnOrder.length} onClick={onClearTurnOrder}>
                Clear turn order
              </Button>
            </>
          )}
        </div>
        <div className="TurnOrderList">
          {sorted.length ? (
            <List
              itemLayout="horizontal"
              dataSource={sorted}
              renderItem={(item) =>
                item && (
                  <List.Item>
                    <div className="Item">
                      <div className="Name">
                        {isDM && (
                          <Tooltip placement="left" title="Remove">
                            <Button size="small" icon={<FaTrash />} onClick={() => onRemoveTurnOrder(item)} />
                          </Tooltip>
                        )}
                        <div>{item.name}</div>
                      </div>
                      <div className="Roll">{item.roll}</div>
                    </div>
                  </List.Item>
                )
              }
            />
          ) : (
            <i className="NoTurnOrder">No turn order has been established yet</i>
          )}
        </div>
        {isDM && renderTurnOrderModal()}
      </div>
    );
  };

  const renderContent = () => {
    switch (drawer) {
      case 'rolls':
        return renderRolls();
      case 'attacks':
        return renderAttacks();
      case 'spells':
        return renderSpells();
      case 'resources':
        return renderResources();
      case 'feats':
        return renderFeats();
      case 'inventory':
        return renderInventory();
      case 'minions':
        return renderMinions();
      case 'turnorder':
        return renderTurnOrder();
      default:
        return null;
    }
  };

  return (
    <Drawer
      placement="right"
      getContainer={false}
      visible={['rolls', 'attacks', 'spells', 'resources', 'feats', 'inventory', 'minions', 'turnorder'].some(
        (d) => drawer === d
      )}
      onClose={() => {
        setShowTurnOrderModal(false);
        setDrawer(undefined);
      }}
      width={450}
      style={{ position: 'absolute' }}
    >
      <div className="DrawerContent">{renderContent()}</div>
    </Drawer>
  );
};

export default MapDrawer;
