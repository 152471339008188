import React from 'react';
import moment from 'moment';
import { useAtom } from 'jotai';
import Linkify from 'react-linkify';
import FileUploader from 'react-firebase-file-uploader';
import { Menu, Button, Form, Input, Popconfirm } from 'antd';
import { BsPlusCircle } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

import { selectedCampaignAtom, isDMAtom } from '../utils/atoms';
import { useTimeout } from '../utils/hooks';
import { delay } from '../utils/utils';
import { URLS } from '../config';
import './Knowledge.css';

const Knowledge = ({ rtdb, auth, storage }) => {
  const [campaign] = useAtom(selectedCampaignAtom);
  const [isDM] = useAtom(isDMAtom);

  const [knowledge, setKnowledge] = React.useState();
  const [selectedEntry, setSelectedEntry] = React.useState();
  const [loaded, setLoaded] = React.useState(false);

  const [isImageUploading, setIsImageUploading] = React.useState(false);
  const [imageProgress, setImageProgress] = React.useState(0);

  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const [fileProgress, setFileProgress] = React.useState(0);

  const [knowledgeForm] = Form.useForm();

  useTimeout(() => setLoaded(true), 75);

  // listen for knowledge updates
  React.useEffect(() => {
    rtdb.ref(`campaign_knowledge/${campaign}`).on('value', (snapshot) => {
      const k = snapshot.val();
      setKnowledge(k);
    });

    return () => {
      rtdb.ref(`campaign_knowledge/${campaign}`).off();
    };
  }, [rtdb, auth, campaign]);

  // update form when knowledge changes
  React.useEffect(() => {
    if (knowledge && knowledge.entries && knowledge.entries[Number(selectedEntry)]) {
      knowledgeForm.setFieldsValue({ name: '', text: '', ...knowledge.entries[Number(selectedEntry)] });
    }
  }, [knowledge, knowledgeForm, selectedEntry]);

  const entries = knowledge && knowledge.entries ? knowledge.entries : [];

  const saveField = (entry, name, value) => {
    if (entries && entries[entry] && value === entries[entry][name]) return;
    rtdb.ref(`campaign_knowledge/${campaign}/entries/${entry}/${name}`).set(value);

    if (name === 'text') {
      const entryName = knowledgeForm.getFieldValue('name');
      if (entries[entry].name !== entryName) {
        rtdb.ref(`campaign_knowledge/${campaign}/entries/${entry}/name`).set(entryName);
      }
    }
  };

  const handleImageUploadStart = () => {
    setIsImageUploading(true);
    setImageProgress(0);
  };

  const handleImageProgress = (progress) => setImageProgress(progress);

  const handleImageUploadError = (error) => {
    setIsImageUploading(false);
    console.error(error);
  };

  const handleImageUploadSuccess = (filename) => {
    setIsImageUploading(false);
    setImageProgress(100);
    rtdb.ref(`campaign_knowledge/${campaign}/entries/${selectedEntry}/image`).set(`images/${filename}`);
  };

  const onDeleteImage = () => {
    rtdb.ref(`campaign_knowledge/${campaign}/entries/${selectedEntry}/image`).remove();
    // TODO: delete image from storage
  };

  const handleFileUploadStart = () => {
    setIsFileUploading(true);
    setFileProgress(0);
  };

  const handleFileProgress = (progress) => setFileProgress(progress);

  const handleFileUploadError = (error) => {
    setIsFileUploading(false);
    console.error(error);
  };

  const handleFileUploadSuccess = (filename) => {
    setIsFileUploading(false);
    setFileProgress(100);
    rtdb.ref(`campaign_knowledge/${campaign}/entries/${selectedEntry}/file`).set(`files/${campaign}/${filename}`);
  };

  const onDeleteFile = () => {
    rtdb.ref(`campaign_knowledge/${campaign}/entries/${selectedEntry}/file`).remove();
    // TODO: delete file from storage
  };

  const onSelectEntry = (entry) => {
    setSelectedEntry(entry);
  };

  const onNewEntry = () => {
    if (!knowledge || !knowledge.entries) {
      rtdb.ref(`campaign_knowledge/${campaign}/`).set({
        entries: [...entries, { time: moment().valueOf(), name: moment().format('MMM D YYYY  h:mm a') }],
      });
      setSelectedEntry(`${entries.length}`);
    } else {
      rtdb.ref(`campaign_knowledge/${campaign}/`).set({
        entries: [...entries, { time: moment().valueOf(), name: moment().format('MMM D YYYY  h:mm a') }],
      });
      setSelectedEntry(`${entries.length}`);
    }
  };

  const onDeleteEntry = (entry) => {
    if (knowledge && knowledge.entries && knowledge.entries[Number(entry)]) {
      rtdb.ref(`campaign_knowledge/${campaign}/`).set({
        entries: entries.filter((e, i) => i !== Number(entry)),
      });
      setSelectedEntry();
    }
  };

  const renderEntryName = () =>
    isDM ? (
      <Form.Item label="Entry name" name="name" initialValue={entries[Number(selectedEntry)].name}>
        <Input
          onKeyUp={delay((e) => saveField(selectedEntry, 'name', e.target.value), 500)}
          onBlur={(e) => saveField(selectedEntry, 'name', e.target.value)}
        />
      </Form.Item>
    ) : (
      <h2 className="EntryName">{entries[Number(selectedEntry)].name}</h2>
    );

  const renderImage = () => {
    const entry = entries[Number(selectedEntry)];
    const hasImage = entry && !!entry.image;
    if (!isDM && !hasImage) return null;
    if (isDM) {
      let className = 'KnowledgeImageUpload';
      if (hasImage) className += ' ImageUploaded';
      return (
        <div className="ImageWrapper">
          <div className="KnowledgeImage">
            <div className={className}>
              <label>
                {hasImage ? (
                  <img alt={entry.name} src={`${URLS.bucket}/${entry.image}`} />
                ) : (
                  'Click to upload an image for this entry'
                )}
                <FileUploader
                  hidden
                  randomizeFilename
                  accept="image/*"
                  storageRef={storage.ref('images')}
                  onUploadStart={handleImageUploadStart}
                  onUploadError={handleImageUploadError}
                  onUploadSuccess={handleImageUploadSuccess}
                  onProgress={handleImageProgress}
                />
              </label>
            </div>
          </div>
          {hasImage && (
            <div className="DeleteButtonWrapper">
              <Popconfirm
                title="Are you sure you want to delete this image?"
                onConfirm={() => onDeleteImage()}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button className="DeleteButton" size="large" danger icon={<FaTrash />}>
                  Remove image
                </Button>
              </Popconfirm>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="ImageFileSpacer">
          <a target="_blank" rel="noreferrer" href={`${URLS.bucket}/${entry.image}`}>
            <img alt={entry.name} src={`${URLS.bucket}/${entry.image}`} />
          </a>
        </div>
      );
    }
  };

  const renderFile = () => {
    const entry = entries[Number(selectedEntry)];
    const hasFile = entry && !!entry.file;
    if (!isDM && !hasFile) return null;
    if (isDM) {
      let className = 'KnowledgeImageUpload';
      if (hasFile) className += ' ImageUploaded';
      return (
        <div className="ImageWrapper">
          <div className="KnowledgeImage">
            <div className={className}>
              <label>
                {hasFile ? entry.file.substr(entry.file.lastIndexOf('/') + 1) : 'Click to upload a file for this entry'}
                <FileUploader
                  hidden
                  accept="*"
                  storageRef={storage.ref(`files/${campaign}`)}
                  onUploadStart={handleFileUploadStart}
                  onUploadError={handleFileUploadError}
                  onUploadSuccess={handleFileUploadSuccess}
                  onProgress={handleFileProgress}
                />
              </label>
            </div>
          </div>
          {hasFile && (
            <div className="DeleteButtonWrapper">
              <Popconfirm
                title="Are you sure you want to delete this file?"
                onConfirm={() => onDeleteFile()}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button className="DeleteButton" size="large" danger icon={<FaTrash />}>
                  Remove file
                </Button>
              </Popconfirm>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="ImageFileSpacer">
          <a target="_blank" rel="noreferrer" href={`${URLS.bucket}/${entry.file}`}>
            File: {entry.file.substr(entry.file.lastIndexOf('/') + 1)}
          </a>
        </div>
      );
    }
  };

  const renderEntryText = () =>
    isDM ? (
      <Form.Item label=" " colon={false} name="text" initialValue={entries[Number(selectedEntry)].text}>
        <Input.TextArea
          rows={20}
          onKeyUp={delay((e) => saveField(selectedEntry, 'text', e.target.value), 500)}
          onBlur={(e) => saveField(selectedEntry, 'text', e.target.value)}
        />
      </Form.Item>
    ) : (
      <div className="EntryText">
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {entries[Number(selectedEntry)].text}
        </Linkify>
      </div>
    );

  const renderDeleteButton = () =>
    isDM && (
      <div className="DeleteButtonWrapper">
        <Popconfirm
          title="Are you sure you want to delete this entry?"
          onConfirm={() => onDeleteEntry(selectedEntry)}
          okText="Delete"
          cancelText="Cancel"
        >
          <Button className="DeleteButton" size="large" danger icon={<FaTrash />}>
            Delete this entry
          </Button>
        </Popconfirm>
      </div>
    );

  return (
    (loaded || !!knowledgeForm.getFieldValue('text')) && (
      <div className="Knowledge">
        <div className="KnowledgeContent">
          <div className="Entries">
            <h2>Knowledge</h2>
            {isDM && (
              <Button icon={<BsPlusCircle />} onClick={onNewEntry}>
                New entry
              </Button>
            )}
            <Menu mode="inline" selectedKeys={[selectedEntry]} onClick={(e) => onSelectEntry(e.key)}>
              {entries.map((entry, i) => (
                <Menu.Item key={`${i}`}>{entry.name}</Menu.Item>
              ))}
            </Menu>
            {!entries.length && <div className="NoEntries">No entries yet</div>}
          </div>
          <div className="Entry">
            {selectedEntry ? (
              <Form name="knowledge" form={knowledgeForm}>
                {renderEntryName()}
                <div className="FilesWrapper">
                  {renderImage()}
                  {renderFile()}
                </div>
                {renderEntryText()}
                {renderDeleteButton()}
              </Form>
            ) : (
              <div className="NoSelected">
                <h2>No entry selected</h2>
                <div className="NoSelectedMessage">
                  {entries.length ? 'Select an entry on the left' : 'No entries have been created yet'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Knowledge;
