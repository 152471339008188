import { Button, Tooltip } from 'antd';
import { useAtom } from 'jotai';
import { GiTreasureMap, GiStopSign } from 'react-icons/gi';
import { ImEyePlus, ImEyeBlocked } from 'react-icons/im';
import { FaRegPlayCircle } from 'react-icons/fa';
import { BsPeopleFill } from 'react-icons/bs';
import { IoShapes } from 'react-icons/io5';

import { mapDrawerAtom, campaignAtom, selectedCampaignAtom, dmVisionAtom } from '../../utils/atoms';
import './MapDMToolbar.css';

const ToolbarButton = ({ icon, label, onClick }) => (
  <Tooltip placement="bottom" title={label}>
    <Button icon={icon} onClick={onClick}></Button>
  </Tooltip>
);

const MapDMToolbar = ({ rtdb, auth }) => {
  const [campaign] = useAtom(selectedCampaignAtom);
  const [campaignData] = useAtom(campaignAtom);
  const [dmVision] = useAtom(dmVisionAtom);
  const [, setDrawer] = useAtom(mapDrawerAtom);

  const toggleFreezeTime = () => {
    if (campaign && campaignData) {
      rtdb.ref(`campaigns/${campaign}/timeFrozen`).set(!campaignData.timeFrozen);
    }
  };

  const toggleDMVision = () => {
    if (campaign) {
      rtdb.ref(`campaigns_dm/${campaign}/dmVision`).set(!dmVision);
    }
  };

  return (
    <div className="DMToolbar">
      <div className="ToolbarSection">
        <ToolbarButton label="Maps" icon={<GiTreasureMap />} onClick={() => setDrawer('maps')} />
        <ToolbarButton label="NPCs" icon={<BsPeopleFill />} onClick={() => setDrawer('npcs')} />
        <ToolbarButton label="Objects" icon={<IoShapes />} onClick={() => setDrawer('objects')} />
        <ToolbarButton
          label={campaignData && campaignData.timeFrozen ? 'Unfreeze time' : 'Freeze time'}
          icon={campaignData && campaignData.timeFrozen ? <FaRegPlayCircle /> : <GiStopSign />}
          onClick={toggleFreezeTime}
        />
        <ToolbarButton
          label={dmVision ? 'Turn off DM vision' : 'Turn on DM vision'}
          icon={dmVision ? <ImEyeBlocked /> : <ImEyePlus />}
          onClick={toggleDMVision}
        />
      </div>
    </div>
  );
};

export default MapDMToolbar;
