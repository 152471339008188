export const STAT_MODIFIER_OPTIONS = [
  {
    value: 'abilityScore',
    label: 'Ability score',
    children: [
      { value: 'str', label: 'Str score' },
      { value: 'dex', label: 'Dex score' },
      { value: 'con', label: 'Con score' },
      { value: 'int', label: 'Int score' },
      { value: 'wis', label: 'Wis score' },
      { value: 'cha', label: 'Cha score' },
    ],
  },
  {
    value: 'abilityBonuses',
    label: 'Ability bonus',
    children: [
      { value: 'strBonus', label: 'Str bonus' },
      { value: 'dexBonus', label: 'Dex bonus' },
      { value: 'conBonus', label: 'Con bonus' },
      { value: 'intBonus', label: 'Int bonus' },
      { value: 'wisBonus', label: 'Wis bonus' },
      { value: 'chaBonus', label: 'Cha bonus' },
    ],
  },
  {
    value: 'savingThrows',
    label: 'Saving throw',
    children: [
      { value: 'strSaveBonus', label: 'Str save' },
      { value: 'dexSaveBonus', label: 'Dex save' },
      { value: 'conSaveBonus', label: 'Con save' },
      { value: 'intSaveBonus', label: 'Int save' },
      { value: 'wisSaveBonus', label: 'Wis save' },
      { value: 'chaSaveBonus', label: 'Cha save' },
    ],
  },
  {
    value: 'skills',
    label: 'Skill',
    children: [
      { value: 'acrobatics', label: 'Acrobatics' },
      { value: 'animal', label: 'Animal Handling' },
      { value: 'arcana', label: 'Arcana' },
      { value: 'athletics', label: 'Athletics' },
      { value: 'deception', label: 'Deception' },
      { value: 'history', label: 'History' },
      { value: 'insight', label: 'Insight' },
      { value: 'intimidation', label: 'Intimidation' },
      { value: 'investigation', label: 'Investigation' },
      { value: 'medicine', label: 'Medicine' },
      { value: 'nature', label: 'Nature' },
      { value: 'perception', label: 'Perception' },
      { value: 'performance', label: 'Performance' },
      { value: 'persuasion', label: 'Persuasion' },
      { value: 'religion', label: 'Religion' },
      { value: 'sleight', label: 'Sleight of Hand' },
      { value: 'stealth', label: 'Stealth' },
      { value: 'survival', label: 'Survival' },
    ],
  },
  {
    value: 'other',
    label: 'Other',
    children: [
      { value: 'initiative', label: 'Initiative' },
      { value: 'ac', label: 'Armor class' },
    ],
  },
];
