import React from 'react';
import { useAtom } from 'jotai';
import { Button, Form, Input, Menu, Popconfirm, InputNumber, Badge } from 'antd';
import { FaUser } from 'react-icons/fa';
import { MdHistory } from 'react-icons/md';
import { BsPlusCircle } from 'react-icons/bs';
import {
  GiDiceShield,
  GiPointySword,
  GiBoltSpellCast,
  GiBattery75,
  GiBattleGear,
  GiStrong,
  GiSkills,
  GiQuillInk,
} from 'react-icons/gi';

import {
  delay,
  withSign,
  parseLevel,
  getProficiencyBonus,
  getAbilityBonus,
  getStatModifiers,
  getStatWithModifier,
} from '../../utils/utils';
import { sortList, RESOURCE_SORT_OPTIONS } from '../../utils/sort';
import { saveToTurnOrder, sendChat } from '../../utils/db';
import { parseDice, rollDice } from '../../utils/dice';
import { useTimeout } from '../../utils/hooks';
import {
  userAtom,
  characterAtom,
  characterTabAtom,
  selectedCampaignAtom,
  characterAttacksAtom,
  characterFeatsAtom,
  characterSpellsAtom,
  characterResourcesAtom,
  characterInventoryAtom,
  characterCurrenciesAtom,
  turnOrderAtom,
  dmSelectedCharacterAtom,
} from '../../utils/atoms';
import CharacterImage from './CharacterImage';
import CharacterSkills from './CharacterSkills';
import CharacterAttacks from './CharacterAttacks';
import CharacterSpells from './CharacterSpells';
import CharacterInventory from './CharacterInventory';
import CharacterFeats from './CharacterFeats';
import SortMenu from '../../components/SortMenu';
import './CharacterView.css';

const CharacterView = ({ rtdb, auth, storage }) => {
  const [user] = useAtom(userAtom);
  const [tab, setTab] = useAtom(characterTabAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [character, setCharacter] = useAtom(characterAtom);
  const [, setAttacks] = useAtom(characterAttacksAtom);
  const [, setFeats] = useAtom(characterFeatsAtom);
  const [, setSpells] = useAtom(characterSpellsAtom);
  const [, setInventory] = useAtom(characterInventoryAtom);
  const [currencies, setCurrencies] = useAtom(characterCurrenciesAtom);
  const [resources, setResources] = useAtom(characterResourcesAtom);
  const [turnOrder] = useAtom(turnOrderAtom);
  const [dmSelectedCharacter, setDMSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const [sortResources, setSortResources] = React.useState(RESOURCE_SORT_OPTIONS[0]);
  const [bio, setBio] = React.useState({});
  const [notes, setNotes] = React.useState({});
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const [loaded, setLoaded] = React.useState(false);

  const [form] = Form.useForm();
  const [resourcesForm] = Form.useForm();
  const [currenciesForm] = Form.useForm();

  useTimeout(() => setLoaded(true), 75);

  const selectedCharacter = dmSelectedCharacter || user.selectedCharacter;

  // listen for window resizing
  React.useEffect(() => {
    const handleResizeWindow = delay(() => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    }, 500);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  // listen for character changes
  React.useEffect(() => {
    if (!user || !selectedCharacter) {
      setCharacter(null);
    } else {
      rtdb.ref(`characters/${selectedCharacter}`).on('value', (charEntry) => {
        const char = charEntry.val();
        if (!char) return;
        const dexBonus = getAbilityBonus(char.dex);
        const proficiencyBonus = getProficiencyBonus(parseLevel(char.classLevel));
        char.initiative = withSign(getStatWithModifier(char, 'initiative', dexBonus));
        char.proficiency = withSign(proficiencyBonus);
        char.ac = getStatWithModifier(char, 'ac', 10 + dexBonus);
        char.passivePerception = 10 + getAbilityBonus(char.wis);
        if (char.perceptionProficient) char.passivePerception += proficiencyBonus;

        form.setFieldsValue(char);
        setCharacter(char);
      });
      rtdb.ref(`character_bio/${selectedCharacter}`).on('value', (bioEntry) => {
        const bio = bioEntry.val();
        form.setFieldsValue(bio);
        setBio(bio);
      });
      rtdb.ref(`character_attacks/${selectedCharacter}`).on('value', (charEntry) => {
        const attacks = charEntry.val();
        setAttacks(attacks || []);
      });
      rtdb.ref(`character_spells/${selectedCharacter}`).on('value', (charEntry) => {
        const spells = charEntry.val();
        setSpells(spells || []);
      });
      rtdb.ref(`character_resources/${selectedCharacter}`).on('value', (charEntry) => {
        const resources = charEntry.val() || [];
        setResources(resources);
        const formData = {};
        resources.forEach((r, i) => {
          formData[`${i}_resourceName`] = r.resourceName;
          formData[`${i}_current`] = r.current;
          formData[`${i}_max`] = r.max;
        });
        resourcesForm.setFieldsValue(formData);
      });
      rtdb.ref(`character_inventory/${selectedCharacter}`).on('value', (charEntry) => {
        const inventory = charEntry.val();
        setInventory(inventory || []);
      });
      rtdb.ref(`character_currencies/${selectedCharacter}`).on('value', (charEntry) => {
        const currencies = charEntry.val() || {};
        setCurrencies(currencies);
        currenciesForm.setFieldsValue(currencies);
      });
      rtdb.ref(`character_feats/${selectedCharacter}`).on('value', (charEntry) => {
        const feats = charEntry.val();
        setFeats(feats || []);
      });
      rtdb.ref(`character_notes/${selectedCharacter}`).on('value', (notesEntry) => {
        const notes = notesEntry.val();
        form.setFieldsValue(notes);
        setNotes(notes);
      });

      return () => {
        rtdb.ref(`characters/${selectedCharacter}`).off();
        rtdb.ref(`character_bio/${selectedCharacter}`).off();
        rtdb.ref(`character_attacks/${selectedCharacter}`).off();
        rtdb.ref(`character_spells/${selectedCharacter}`).off();
        rtdb.ref(`character_resources/${selectedCharacter}`).off();
        rtdb.ref(`character_inventory/${selectedCharacter}`).off();
        rtdb.ref(`character_currencies/${selectedCharacter}`).off();
        rtdb.ref(`character_feats/${selectedCharacter}`).off();
        rtdb.ref(`character_notes/${selectedCharacter}`).off();
      };
    }
  }, [
    rtdb,
    user,
    selectedCharacter,
    form,
    resourcesForm,
    currenciesForm,
    setCharacter,
    setAttacks,
    setFeats,
    setSpells,
    setResources,
    setInventory,
    setCurrencies,
  ]);

  const onBack = () => {
    setCharacter(null);
    rtdb.ref(`users/${auth.user.uid}/selectedCharacter`).remove();
    setDMSelectedCharacter();
  };

  const onSave = (values, root) => {
    rtdb.ref(`${root}/${selectedCharacter}`).update(values);
  };

  const saveField = (name, value, root = 'characters') => {
    if (root === 'characters' && character && value === character[name]) return;
    if (root === 'character_bio' && bio && value === bio[name]) return;
    if (root === 'character_notes' && notes && value === notes[name]) return;
    if (root === 'character_currencies' && currencies && value === currencies[name]) return;
    rtdb.ref(`${root}/${selectedCharacter}/${name}`).set(value);
  };

  const onRollStat = (bonus, stat) => {
    const dice = parseDice(Number(bonus) === 0 ? `1d20` : `1d20 ${bonus}`);
    const result = rollDice(dice);
    if (stat === 'Initiative') saveToTurnOrder(rtdb, campaign, character, turnOrder, selectedCharacter, result);
    sendChat(rtdb, campaign, character, {
      stat,
      result,
      bonus: withSign(bonus),
      type: 'stat',
    });
  };

  const renderTextField = (className, label, name, root = 'characters') => {
    return (
      <div key={`${name}Wrapper`} className={className}>
        <Form.Item key={`${name}FI`} label={label} name={name} initialValue="">
          <Input
            key={name}
            onKeyUp={delay((e) => saveField(name, e.target.value, root), 500)}
            onBlur={(e) => saveField(name, e.target.value, root)}
          />
        </Form.Item>
      </div>
    );
  };

  const renderTextareaField = (className, label, name, rows, root = 'characters') => {
    return (
      <div className={className}>
        <Form.Item label={label} name={name} initialValue="">
          <Input.TextArea
            rows={rows}
            onKeyUp={delay((e) => saveField(name, e.target.value, root), 500)}
            onBlur={(e) => saveField(name, e.target.value, root)}
          />
        </Form.Item>
      </div>
    );
  };

  const renderMainInfoField = (label, name) => renderTextField('MainInfoField', label, name);

  const renderAbilityStat = (label, name) => {
    const stat = character && character[name] ? character[name] : 0;
    const statWithModifier = getStatWithModifier(character, name, stat);
    const bonus = getAbilityBonus(stat);
    const bonusWithModifier = getStatWithModifier(character, `${name}Bonus`, getAbilityBonus(statWithModifier));
    return (
      <Button className="StatButton" onClick={() => onRollStat(bonusWithModifier, label)}>
        <div className="AbilityStat">
          <div className="AbilityStatRow">
            <div className="AbilityStatCol">
              <Form.Item name={name} initialValue="">
                <Input
                  onKeyUp={delay((e) => saveField(name, e.target.value), 500)}
                  onBlur={(e) => saveField(name, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </Form.Item>
            </div>
            <div className="AbilityStatCol">
              <Badge
                count={bonus !== bonusWithModifier ? '...' : 0}
                title="Bonus is modified by a stat effect"
                color="#108ee9"
                offset={[10, -10]}
              >
                <div className="AbilityStatBigText">{withSign(bonusWithModifier)}</div>
              </Badge>
            </div>
          </div>
          <div className="AbilityStatRow AbilityStatName">{label}</div>
        </div>
      </Button>
    );
  };

  const renderMainStat = ({ label, name, name2, narrow, readOnly, rollable }) => {
    let bonus = 0;
    const modifiers = getStatModifiers(character, name);
    if (name === 'initiative') {
      bonus = character ? character.initiative : 0;
    }
    return (
      <Button
        className={`StatButton ${rollable ? '' : 'NotRollable'}`}
        onClick={() => rollable && onRollStat(bonus, label)}
      >
        <div
          className={narrow ? 'MainStat MainStatNarrow' : name2 ? 'MainStat MainStatWide' : 'MainStat MainStatNormal'}
        >
          <div className="MainStatRow">
            <div className="MainStatCol">
              <Badge
                count={modifiers.length ? '...' : 0}
                title="Modified by a stat effect"
                color="#108ee9"
                // offset={[10, -10]}
              >
                <Form.Item name={name} initialValue="">
                  <Input
                    onKeyUp={delay((e) => saveField(name, e.target.value), 500)}
                    onBlur={(e) => saveField(name, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    disabled={readOnly}
                  />
                </Form.Item>
              </Badge>
            </div>
            {name2 && (
              <div className="MainStatCol">
                <Form.Item name={name2} initialValue="">
                  <Input
                    onKeyUp={delay((e) => saveField(name2, e.target.value), 500)}
                    onBlur={(e) => saveField(name2, e.target.value)}
                    disabled={readOnly}
                  />
                </Form.Item>
              </div>
            )}
          </div>
          <div className="MainStatRow MainStatName">{label}</div>
        </div>
      </Button>
    );
  };

  const renderBioShortField = (label, name) => renderTextField('BioShortField', label, name, 'character_bio');

  const renderBioMediumField = (label, name) => renderTextareaField('BioMediumField', label, name, 4, 'character_bio');

  const renderBioLongField = (label, name) => renderTextareaField('BioLongField', label, name, 8, 'character_bio');

  const renderBiography = () => {
    return (
      <div className="Biography">
        <div className="BioShortFields">
          {renderBioShortField('Age', 'age')}
          {renderBioShortField('Height', 'height')}
          {renderBioShortField('Weight', 'weight')}
          {renderBioShortField('Eyes', 'eyes')}
          {renderBioShortField('Skin', 'skin')}
          {renderBioShortField('Hair', 'hair')}
        </div>
        <div className="BioLongFields">
          {renderBioLongField('Backstory', 'backstory')}
          {renderBioMediumField('Personality Traits', 'personality')}
          {renderBioMediumField('Ideals', 'ideals')}
          {renderBioMediumField('Bonds', 'bonds')}
          {renderBioMediumField('Flaws', 'flaws')}
          {renderBioMediumField('Allies & Organizations', 'allies')}
          {renderBioMediumField('Treasure', 'treasure')}
        </div>
      </div>
    );
  };

  const renderResourceField = (className, label, name, i) => {
    return (
      <div className={className}>
        <Form.Item label={label} name={`${i}_${name}`} initialValue={resources[i][name]}>
          <Input
            onKeyUp={delay((e) => saveField(`${i}/${name}`, e.target.value, 'character_resources'), 500)}
            onBlur={(e) => saveField(`${i}/${name}`, e.target.value, 'character_resources')}
          />
        </Form.Item>
      </div>
    );
  };

  const renderResourcesList = () => {
    const sorted = sortList(resources, sortResources);
    const getIndex = (resource) => resources.indexOf(resource);
    return (
      <Form form={resourcesForm} name="resources">
        {sorted.map((resource, i) => (
          <div className="Resource" key={`resource${i}`}>
            {renderResourceField('ResourceName', 'Name', 'resourceName', getIndex(resource))}
            {renderResourceField('ResourceMax', 'Max', 'max', getIndex(resource))}
            {renderResourceField('ResourceCurrent', 'Current', 'current', getIndex(resource))}
            <div className="PlusMinus">
              <Button
                size="small"
                disabled={Number(resource.current) <= 0}
                onClick={() => {
                  const newCurrent = Number(resource.current) - 1;
                  if (newCurrent < 0) return;
                  rtdb.ref(`character_resources/${selectedCharacter}/${getIndex(resource)}/current`).set(newCurrent);
                }}
              >
                -
              </Button>
              <Button
                size="small"
                disabled={Number(resource.current) >= Number(resource.max)}
                onClick={() => {
                  const newCurrent = Number(resource.current) + 1;
                  if (newCurrent > Number(resource.max)) return;
                  rtdb.ref(`character_resources/${selectedCharacter}/${getIndex(resource)}/current`).set(newCurrent);
                }}
              >
                +
              </Button>
            </div>

            <Popconfirm
              title="Are you sure you want to delete this resource?"
              onConfirm={() => {
                rtdb.ref(`character_resources/${selectedCharacter}/${getIndex(resource)}`).remove();
              }}
              okText="Delete"
              cancelText="Cancel"
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </div>
        ))}
      </Form>
    );
  };

  const renderResources = () => {
    return (
      <div className="Resources">
        <SortMenu options={RESOURCE_SORT_OPTIONS} sort={sortResources} setSort={setSortResources} />
        <div className="ResourcesList">
          {resources.length ? (
            <div className="ResourcesContent">{renderResourcesList()}</div>
          ) : (
            <div className="Empty">You don't have any resources yet</div>
          )}
        </div>
        <div className="ButtonWrapper">
          <Button
            type="link"
            size="large"
            className="NewButton"
            icon={<BsPlusCircle />}
            onClick={() => {
              rtdb.ref(`character_resources/${selectedCharacter}/${resources.length}`).set({
                resourceName: '',
                current: '',
                max: '',
              });
            }}
          >
            New resource
          </Button>
        </div>
      </div>
    );
  };

  const renderCurrencyField = (label, name) => {
    return (
      <div className="Currency">
        <Form.Item label={label} name={name} initialValue={currencies[name]}>
          <InputNumber
            onKeyUp={delay((e) => saveField(name, e.target.value, 'character_currencies'), 500)}
            onBlur={(e) => saveField(name, e.target.value, 'character_currencies')}
          />
        </Form.Item>
      </div>
    );
  };

  const renderCurrenciesAndInventory = () => {
    return (
      <>
        <Form form={currenciesForm} name="currencies">
          <div className="Currencies">
            {renderCurrencyField('Gold', 'gold')}
            {renderCurrencyField('Silver', 'silver')}
            {renderCurrencyField('Copper', 'copper')}
          </div>
        </Form>
        <CharacterInventory rtdb={rtdb} />
      </>
    );
  };

  const renderProficiencies = () => {
    return (
      <div className="Proficiencies">
        {renderBioMediumField('Languages', 'languageProficiencies')}
        {renderBioMediumField('Tools', 'toolProficiencies')}
        {renderBioLongField('Other proficiencies', 'otherProficiencies')}
      </div>
    );
  };

  const renderNotesField = (label, name) => renderTextareaField('BioLongField', label, name, 20, 'character_notes');

  const renderNotes = () => {
    return <div className="Notes">{renderNotesField('Notes', 'notes')}</div>;
  };

  const renderHistory = () => {
    return <div className="History"></div>;
  };

  const renderTabContent = () => {
    switch (tab) {
      case 'biography':
        return renderBiography();
      case 'skills':
        return <CharacterSkills rtdb={rtdb} saveField={saveField} />;
      case 'attacks':
        return <CharacterAttacks rtdb={rtdb} />;
      case 'spells':
        return <CharacterSpells rtdb={rtdb} />;
      case 'resources':
        return renderResources();
      case 'inventory':
        return renderCurrenciesAndInventory();
      case 'feats':
        return <CharacterFeats rtdb={rtdb} />;
      case 'proficiencies':
        return renderProficiencies();
      case 'notes':
        return renderNotes();
      case 'history':
        return renderHistory();
      default:
        return null;
    }
  };

  return (
    (!!form.getFieldValue('name') || loaded) && (
      <div className="CharacterView" key="CharacterView">
        <Form name="character" key="CharacterViewForm" form={form} onFinish={onSave}>
          <div className="MainInfo" key="MainInfo">
            <CharacterImage rtdb={rtdb} storage={storage} />
            <div className="MainInfoFields" key="MainInfoFields">
              {renderMainInfoField('Name', 'name')}
              {renderMainInfoField('Class/level', 'classLevel')}
              {renderMainInfoField('Race', 'race')}
              {renderMainInfoField('Background', 'background')}
              {renderMainInfoField('Alignment', 'alignment')}
              {renderMainInfoField('Experience', 'exp')}
              {renderMainInfoField('Size', 'size')}
              {renderMainInfoField('Speed', 'speed')}
            </div>
          </div>

          <div className="Stats">
            <div className="AbilityStats">
              {renderAbilityStat('Strength', 'str')}
              {renderAbilityStat('Dexterity', 'dex')}
              {renderAbilityStat('Constitution', 'con')}
              {renderAbilityStat('Intelligence', 'int')}
              {renderAbilityStat('Wisdom', 'wis')}
              {renderAbilityStat('Charisma', 'cha')}
            </div>
            <div className="MainStats">
              {renderMainStat({
                label: 'Inspiration',
                name: 'inspiration',
                narrow: true,
              })}
              {renderMainStat({
                label: 'Initiative',
                name: 'initiative',
                narrow: true,
                readOnly: true,
                rollable: true,
              })}
              {renderMainStat({
                label: 'Proficiency bonus',
                name: 'proficiency',
                readOnly: true,
                narrow: true,
              })}
              {renderMainStat({
                label: 'Armor class',
                name: 'ac',
                narrow: true,
                readOnly: true,
              })}
              {renderMainStat({
                label: 'Hit points',
                name: 'hp',
                name2: 'maxhp',
              })}
              {renderMainStat({
                label: 'Temporary HP',
                name: 'temphp',
                narrow: true,
              })}
              {renderMainStat({
                label: 'Hit dice',
                name: 'hitDice',
                name2: 'maxHitDice',
              })}
              {renderMainStat({
                label: 'Death saves',
                name: 'deathSaves',
                narrow: true,
                rollable: true,
              })}
              {renderMainStat({
                label: 'Passive perception',
                name: 'passivePerception',
                readOnly: true,
                narrow: true,
              })}
            </div>
          </div>

          <div className="Tabs">
            <div className="TabsMenu">
              <Menu
                mode="inline"
                defaultSelectedKeys={[tab]}
                inlineCollapsed={screenWidth < 1000 || screenHeight < 800}
                onClick={(e) => setTab(e.key)}
              >
                <Menu.Item key="biography" icon={<FaUser />}>
                  Biography
                </Menu.Item>
                <Menu.Item key="skills" icon={<GiDiceShield />}>
                  Skills & Saving Throws
                </Menu.Item>
                <Menu.Item key="attacks" icon={<GiPointySword />}>
                  Attacks
                </Menu.Item>
                <Menu.Item key="spells" icon={<GiBoltSpellCast />}>
                  Spells
                </Menu.Item>
                <Menu.Item key="resources" icon={<GiBattery75 />}>
                  Resources
                </Menu.Item>
                <Menu.Item key="inventory" icon={<GiBattleGear />}>
                  Items & Equipment
                </Menu.Item>
                <Menu.Item key="feats" icon={<GiStrong />}>
                  Features & Traits
                </Menu.Item>
                <Menu.Item key="proficiencies" icon={<GiSkills />}>
                  Proficiencies
                </Menu.Item>
                <Menu.Item key="notes" icon={<GiQuillInk />}>
                  Notes
                </Menu.Item>
                <Menu.Item key="history" icon={<MdHistory disabled />}>
                  History
                </Menu.Item>
              </Menu>
              <Button size="large" onClick={onBack}>
                {screenWidth < 1000 || screenHeight < 800 ? 'Back' : 'Back to character select'}
              </Button>
            </div>
            <div className={screenWidth > 1000 && screenHeight > 800 ? 'TabsMenuSpacer' : ''} />
            <div className="TabsContent">
              <div className="TabsContentContainer">{renderTabContent()}</div>
            </div>
          </div>
        </Form>
      </div>
    )
  );
};

export default CharacterView;
