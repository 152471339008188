import React from 'react';
import { useAtom } from 'jotai';

import {
  userAtom,
  dropObjectAtom,
  isDMAtom,
  dmVisionAtom,
  dmSelectedCharacterAtom,
  characterAtom,
} from '../../utils/atoms';
import MapObject from './MapObject';
import './MapCell.css';

const MapCell = ({
  rtdb,
  auth,
  columnIndex,
  rowIndex,
  style,
  cell,
  cellWidth,
  cellHeight,
  canDrop,
  dragInitial,
  animationObjects,
  setAnimationObjects,
  addObject,
  removeObject,
  updateObject,
}) => {
  const [user] = useAtom(userAtom);
  const [isDM] = useAtom(isDMAtom);
  const [dmVision] = useAtom(dmVisionAtom);
  const [, setCharacter] = useAtom(characterAtom);
  const [dropObject, setDropObject] = useAtom(dropObjectAtom);
  const [, setDMSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  // filter out hidden objects
  const visibleObjects = React.useMemo(
    // () => (isDM && dmVision ? cell && cell.objects : cell && cell.objects ? cell.objects.filter((o) => !o.hidden) : []),
    () => {
      if (isDM && dmVision) {
        return cell && cell.objects;
      } else if (cell && cell.objects) {
        return cell.objects.filter((o) => !o.hidden);
      } else return [];
    },
    [isDM, dmVision, cell]
  );

  const [objectIndex, setObjectIndex] = React.useState(visibleObjects ? visibleObjects.length - 1 : -1);
  const [show, setShow] = React.useState(false);
  const [draggingThis, setDraggingThis] = React.useState(0);
  const [distance, setDistance] = React.useState();

  const isDragging = dragInitial !== null;

  React.useEffect(() => {
    setShow(true);
  }, []);

  React.useEffect(() => {
    if (!isDragging) {
      setDraggingThis(undefined);
      setDistance(undefined);
    }
  }, [isDragging]);

  React.useEffect(() => {
    setObjectIndex(visibleObjects ? visibleObjects.length - 1 : -1);
  }, [visibleObjects]);

  if (!show)
    return (
      <div style={style} className="Black">
        {' '}
      </div>
    );

  const showDragOverlay = canDrop && isDragging && !!draggingThis;

  const calculateDistance = (e) => {
    if (!dragInitial) return 0;
    const dimension = (initial, current, offset, cell) => Math.abs(current - offset - initial) / cell;
    return (
      Math.round(
        Math.max(
          dimension(dragInitial.x, e.clientX, e.nativeEvent.offsetX, cellWidth),
          dimension(dragInitial.y, e.clientY, e.nativeEvent.offsetY, cellHeight)
        )
      ) * 5
    );
  };

  const onClick = () => {
    if (dropObject) {
      addObject(columnIndex, rowIndex, dropObject);
      setDropObject(undefined);
    } else if (isDM && visibleObjects.length === 0) {
      setDMSelectedCharacter();
      setCharacter();
    }
  };

  const onDragEnter = (e) => {
    if (draggingThis) return;
    setDraggingThis(e.currentTarget);
    if (distance) return;
    setDistance(calculateDistance(e));
  };

  const onDragLeave = (e) => {
    if (draggingThis !== e.target) return;
    setDraggingThis(undefined);
    setDistance(undefined);
  };

  const Overlays = (
    <>
      <div className="InitialOverlay"> </div>
      {user && user.showMapGrid && (
        <div className="GridOverlay">
          <div className="GridOverlayInner"> </div>
        </div>
      )}
      {showDragOverlay && <div className="DragOverlay"> </div>}
      {showDragOverlay && distance > 0 && <div className="DragDistanceOverlay">{distance} feet</div>}
    </>
  );

  const renderContent = () => {
    if (visibleObjects && visibleObjects.length > 0) {
      const object = (
        <MapObject
          rtdb={rtdb}
          auth={auth}
          x={columnIndex}
          y={rowIndex}
          width={cellWidth}
          height={cellHeight}
          object={{
            ...visibleObjects[objectIndex],
            columnIndex,
            rowIndex,
          }}
          animationObjects={animationObjects}
          setAnimationObjects={setAnimationObjects}
          popoverObjects={visibleObjects.length > 1 ? visibleObjects : null}
          setObjectIndex={setObjectIndex}
          removeObject={removeObject}
          updateObject={updateObject}
        />
      );
      return object;
    }
    return null;
  };

  // const isFog = columnIndex < 8 && rowIndex < 8;

  let className = 'Cell';
  if (dropObject) className += ' Dropping';
  // if (isFog) className += ' Fogged';

  return (
    <div
      id="mapCell"
      className={className}
      style={style}
      onClick={onClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
    >
      {Overlays}
      <div className="Content">{renderContent()}</div>
    </div>
  );
};

export default MapCell;
