import { Menu } from 'antd';
import { useAtom } from 'jotai';

import { selectedCampaignAtom, isDMAtom, pageAtom } from '../utils/atoms';
import './TopButtons.css';

const TopButtons = ({ auth }) => {
  const [, setCampaign] = useAtom(selectedCampaignAtom);
  const [page, setPage] = useAtom(pageAtom);
  const [isDM] = useAtom(isDMAtom);

  const handleClick = (e) => {
    switch (e.key) {
      case 'switchcampaign':
        setCampaign(null);
        break;
      case 'logout':
        auth.signOut();
        break;
      default:
        setPage(e.key);
    }
  };

  return (
    <div className="TopButtons">
      <Menu mode="horizontal" selectedKeys={[page]} onClick={handleClick}>
        <Menu.Item key="map">Map</Menu.Item>
        <Menu.Item key="character" disabled={auth.user.isAnonymous}>
          {isDM ? 'Characters' : 'Character'}
        </Menu.Item>
        <Menu.Item key="journal" disabled={auth.user.isAnonymous}>
          Journal
        </Menu.Item>
        <Menu.Item key="knowledge">Knowledge</Menu.Item>
        <Menu.Item key="campaign">Campaign</Menu.Item>
        <Menu.SubMenu key="leave" title="Leave">
          <Menu.Item key="switchcampaign" disabled={auth.user.isAnonymous}>
            Switch campaign
          </Menu.Item>
          <Menu.Item key="logout">Log out</Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </div>
  );
};

export default TopButtons;
