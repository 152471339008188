import React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'antd';

import './Admin.css';

const Admin = ({ rtdb, auth }) => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [dmInviteLink, setDmInviteLink] = React.useState('');

  const history = useHistory();

  React.useEffect(() => {
    rtdb
      .ref('meta/adminUsers')
      .get()
      .then((adminUsersEntry) => {
        const adminUsers = adminUsersEntry.val();
        if (adminUsers && adminUsers.some((user) => user === auth.user.uid)) {
          setIsAdmin(true);
        } else {
          history.pushState('/');
        }
      });
  }, [rtdb, auth, history]);

  const onGenerateDMInviteLink = () => {
    const uuid = uuidv4();
    rtdb
      .ref('meta/dmInvite')
      .set(uuid)
      .then(() => {
        setDmInviteLink(`/dminvite/${uuid}`);
      });
  };

  return (
    <div className="Admin">
      {isAdmin && (
        <div>
          <Button onClick={onGenerateDMInviteLink}>Generate DM invite link</Button>
          {dmInviteLink && <a href={dmInviteLink}>DM invite link</a>}
        </div>
      )}
    </div>
  );
};

export default Admin;
