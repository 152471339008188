import React from 'react';
import { map } from 'lodash';
import { useAtom } from 'jotai';
import { Input, Button, notification, Modal, List, Popover, Badge, Tooltip } from 'antd';
import data from 'emoji-mart/data/google.json';
import { NimblePicker, Emoji } from 'emoji-mart';
import GiphySearchbox from 'react-giphy-searchbox';
import { Gif } from '@giphy/react-components';
import Linkify from 'react-linkify';
import { v4 as uuidv4 } from 'uuid';
import { IoIosArrowDropright } from 'react-icons/io';
import { GiRollingDices } from 'react-icons/gi';
import { VscReactions, VscSmiley } from 'react-icons/vsc';
import { AiOutlineGif } from 'react-icons/ai';
import UIfx from 'uifx';
import 'emoji-mart/css/emoji-mart.css';

import { formatSpellLevel, withSign, getModifierStatLabel } from '../utils/utils';
import { characterAtom, selectedCampaignAtom, isDMAtom } from '../utils/atoms';
import { STAT_MODIFIER_OPTIONS } from '../utils/constants';
import { parseDice, rollDice } from '../utils/dice';
import DiceSelector from './DiceSelector';
import { sendChat } from '../utils/db';
import { URLS } from '../config';
import './Chat.css';

const notificationSound = new UIfx(`${URLS.bucket}/sounds/just-maybe-577.mp3`, {
  volume: 0.3, // number between 0.0 ~ 1.0
  throttleMs: 500,
});

const EmojiPopover = ({ placement, onSelect, children }) => {
  const renderReactionPicker = () => {
    return (
      <NimblePicker
        title=""
        theme="dark"
        set="google"
        data={data}
        showPreview={false}
        showSkinTones={false}
        onSelect={(emoji) => onSelect(emoji)}
      />
    );
  };
  return (
    <Popover
      trigger="click"
      overlayClassName="ReactionPopover"
      placement={placement}
      content={() => renderReactionPicker()}
    >
      {children}
    </Popover>
  );
};

const Chat = ({ rtdb, auth, hideChat, setHideChat }) => {
  const [character] = useAtom(characterAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [isDM] = useAtom(isDMAtom);

  const [messages, setMessages] = React.useState([]);
  const [message, setMessage] = React.useState('');
  const [modalData, setModalData] = React.useState();
  const [showGiphyModal, setShowGiphyModal] = React.useState(false);
  const [showDiceModal, setShowDiceModal] = React.useState(false);

  const bottomRef = React.useRef();

  // listen for new and changed chat messages
  React.useEffect(() => {
    rtdb
      .ref(`chats/${campaign}/messages`)
      .orderByChild('time')
      .limitToLast(20)
      .on('child_added', (data) => {
        let msg = data.val();
        if (msg) {
          msg = { ...msg, id: data.key };
          setMessages((oldMessages) => [...oldMessages, msg]);
          notificationSound.play();
        }
      });
    rtdb
      .ref(`chats/${campaign}/messages`)
      .orderByChild('time')
      .limitToLast(20)
      .on('child_changed', (data) => {
        let msg = data.val();
        if (msg) {
          msg = { ...msg, id: data.key };
          setMessages((oldMessages) => oldMessages.map((m) => (m.id === msg.id ? msg : m)));
        }
      });

    return () => {
      rtdb.ref(`chats/${campaign}`).off();
    };
  }, [campaign, rtdb]);

  // scroll to bottom on new message
  React.useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [messages]);

  const getName = () =>
    isDM ? 'The Dungeon Master' : character ? character.name || 'Somebody' : auth.user.displayName || 'Somebody';

  const sendChatMessage = (message) => {
    if (!message) return;

    if (message.startsWith('/roll')) {
      const diceArray = parseDice(message.substring(6));
      if (!diceArray) {
        notification.error({
          message: 'Invalid dice',
          description: 'Your dice string was not properly formed',
        });
        return;
      }
      const result = rollDice(diceArray);
      if (result) {
        sendChat(rtdb, campaign, character, {
          type: 'roll',
          result,
          sender: getName(),
        });
      }
      setMessage('');
      return;
    }

    sendChat(rtdb, campaign, character, {
      type: 'message',
      message,
      sender: getName(),
    });
    setMessage('');
  };

  const onRollDamage = (attack, sender) => {
    const dice = parseDice(attack.damage);
    if (!dice) {
      notification.error({
        message: 'Invalid damage dice',
        description: 'The dice string was not properly formed',
      });
      return;
    }
    const result = rollDice(dice);
    sendChat(rtdb, campaign, character, {
      type: 'damage',
      attack,
      result,
      sender: sender || 'Somebody',
    });
    setMessage('');
  };

  const onSelectReaction = (message, emoji) => {
    const reactions = message.reactions ? map(message.reactions, (r, id) => ({ ...r, id })) : [];
    const existing = reactions.find((r) => r.emojiId === emoji.id);
    const name = getName();
    if (existing) {
      const users = existing.users ? map(existing.users, (u, id) => ({ picked: !!u, id })) : [];
      const user = users.find((u) => u.id === auth.user.uid);
      const userRef = rtdb.ref(
        `chats/${campaign}/messages/${message.id}/reactions/${existing.id}/users/${auth.user.uid}`
      );
      if (user && user.picked) {
        userRef.remove();
      } else {
        userRef.set(name);
      }
    } else {
      const users = {};
      users[auth.user.uid] = name;
      const newReaction = { emojiId: emoji.id, users };
      rtdb.ref(`chats/${campaign}/messages/${message.id}/reactions/${uuidv4()}`).set(newReaction);
    }
  };

  const renderDetailsModalContent = (modalData) => {
    if (modalData) {
      switch (modalData.type) {
        case 'shareattack':
          return (
            <>
              <div className="Info">
                Damage: {modalData.attack.damage} {modalData.attack.damageType}
              </div>
              <div className="Description">{modalData.attack.description}</div>
            </>
          );
        case 'spell':
          return (
            <>
              <div className="Info">Range: {modalData.spell.range}</div>
              <div className="Info">Cast time: {modalData.spell.castTime}</div>
              <div className="Description">{modalData.spell.description}</div>
            </>
          );
        case 'shareitem':
          return <div className="Description">{modalData.item.description}</div>;
        case 'sharefeat':
          return <div className="Description">{modalData.feat.description}</div>;
        default:
      }
    }
    return null;
  };

  const renderDetailsModal = () => {
    let title;
    if (modalData) {
      switch (modalData.type) {
        case 'shareattack':
          title = `Attack: ${modalData.attack.attackName}`;
          break;
        case 'spell':
          title = `${formatSpellLevel(modalData.spell.level)} Spell: ${modalData.spell.spellName}`;
          break;
        case 'shareitem':
          title = `Item: ${modalData.item.itemName}`;
          break;
        case 'sharefeat':
          title = `Feature/trait: ${modalData.feat.featName}`;
          break;
        default:
          title = undefined;
      }
    }
    return (
      <Modal title={title} footer={null} centered visible={!!modalData} onCancel={() => setModalData(undefined)}>
        <div className="DetailsModalContent">{renderDetailsModalContent(modalData)}</div>
      </Modal>
    );
  };

  const renderChatMessage = (message, i) => {
    switch (message.type) {
      case 'shareitem':
        return (
          <div className="ShareItem" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> shared an item:</div>
            </div>
            <div className="Text2">{message.item.itemName}</div>
            {message.item.description && (
              <div className="Text3">
                <Button type="link" onClick={() => setModalData(message)}>
                  View description
                </Button>
              </div>
            )}
            {message.item.modifiers && message.item.modifiers.length && (
              <div className="Text4">
                {message.item.modifiers.map((m, i) => (
                  <div key={`${i}`} className="Modifier">
                    <div className="ModifiedStat">{getModifierStatLabel(STAT_MODIFIER_OPTIONS, m)}</div>
                    <div className="Operation">{m.operation}</div>
                    <div className="Value">{m.value}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      case 'sharefeat':
        return (
          <div className="ShareFeat" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> shared a feature/trait:</div>
            </div>
            <div className="Text2">{message.feat.featName}</div>
            {message.feat.description && (
              <div className="Text3">
                <Button type="link" onClick={() => setModalData(message)}>
                  View description
                </Button>
              </div>
            )}
            {message.feat.modifiers && message.feat.modifiers.length && (
              <div className="Text4">
                {message.feat.modifiers.map((m, i) => (
                  <div key={`${i}`} className="Modifier">
                    <div className="ModifiedStat">{getModifierStatLabel(STAT_MODIFIER_OPTIONS, m)}</div>
                    <div className="Operation">{m.operation}</div>
                    <div className="Value">{m.value}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      case 'spell':
        return (
          <div className="Spell" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> cast a spell:</div>
            </div>
            <div className="Text2">
              {message.spell.spellName} ({formatSpellLevel(message.spell.level)})
            </div>
            <div className="Text3">Range: {message.spell.range}</div>
            <div className="Text4">Cast time: {message.spell.castTime}</div>
            {message.spell.duration && <div className="Text5">Duration: {message.spell.duration}</div>}
            {message.spell.requirements && <div className="Text6">Requirements: {message.spell.requirements}</div>}
            {message.spell.description && (
              <div className="Text7">
                <Button type="link" onClick={() => setModalData(message)}>
                  View description
                </Button>
              </div>
            )}
          </div>
        );
      case 'damage':
        return (
          <div className="Damage" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> damaged the target:</div>
            </div>
            <div className="Text2">({message.result.input})</div>
            {message.result.rolled.length > 1 && <div className="Text3">{message.result.rolled.join(' + ')} =</div>}
            <div className="Text4">{message.result.result}</div>
            <div className="Text5">{message.attack.damageType}</div>
          </div>
        );
      case 'shareattack':
        return (
          <div className="ShareAttack" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> shared an attack:</div>
            </div>
            <div className="Text2">
              {message.attack.attackName} ({withSign(message.attack.hitBonus)})
            </div>
            <div className="Text3">
              {message.attack.damage} {message.attack.damageType}
            </div>
            {message.attack.description && (
              <div className="Text4">
                <Button type="link" onClick={() => setModalData(message)}>
                  View description
                </Button>
              </div>
            )}
          </div>
        );
      case 'attack':
        return (
          <div className="Attack" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> attacked with:</div>
            </div>
            <div className="Text2">
              {message.attack.attackName} ({withSign(message.attack.hitBonus)})
            </div>
            {message.result.rolled.length > 1 && <div className="Text3">{message.result.rolled.join(' + ')} =</div>}
            <div className="Text4">{message.result.result}</div>
            {message.attack.damage && (
              <div className="Text5">
                <Button
                  type="link"
                  className="RollDamageButton"
                  onClick={() => onRollDamage(message.attack, message.sender)}
                >
                  Roll damage
                </Button>
              </div>
            )}
          </div>
        );
      case 'stat':
        return (
          <div className="Stat" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div>
                {' '}
                rolled for {message.stat} ({message.bonus}) ...
              </div>
            </div>
            {message.result.rolled.length > 1 && <div className="Text2">{message.result.rolled.join(' + ')} = </div>}
            <div className="Text3">{message.result.result}</div>
          </div>
        );
      case 'roll':
        if (!message.result) return <div />;
        return (
          <div className="Roll" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> rolled {message.result.input} ...</div>
            </div>
            {message.result.rolled.length > 1 && <div className="Text2">{message.result.rolled.join(' + ')} = </div>}
            <div className="Text3">{message.result.result}</div>
          </div>
        );
      case 'gif':
        if (!message.gif) return <div />;
        return (
          <div className="Gif" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> shared a GIF:</div>
            </div>
            <div className="Text2">
              <Gif noLink hideAttribution width={264} backgroundColor="black" gif={message.gif} />
            </div>
          </div>
        );
      case 'message':
      default:
        const messageLowerCase = message.message.toLowerCase().trim();
        return (
          <div className="Message" key={`${message.time}${i}`} ref={i === messages.length - 1 ? bottomRef : null}>
            <div className="Text1">
              <div className="Sender">{message.sender}</div>
              <div> says:</div>
            </div>
            <div className="Text2">
              {messageLowerCase.startsWith('http') &&
              (messageLowerCase.endsWith('.gif') ||
                messageLowerCase.endsWith('.jpg') ||
                messageLowerCase.endsWith('.jpeg') ||
                messageLowerCase.endsWith('.png')) ? (
                <img className="LinkedImage" alt="" src={message.message.trim()} />
              ) : (
                <div className="Text">
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {message.message}
                  </Linkify>
                </div>
              )}
            </div>
          </div>
        );
    }
  };

  const renderReactions = (message) => {
    const reactions = message && message.reactions ? map(message.reactions, (r, id) => ({ ...r, id })) : [];
    return reactions.map((r) => {
      const users = r.users ? map(r.users, (u, id) => ({ name: u, id })) : [];
      if (!users.length) return null;
      const userNames = users.map((u) => u.name).join(', ');
      return (
        <Tooltip placement="bottom" overlayClassName="ReactionTooltip" title={userNames}>
          <Badge key={r.emojiId} size="small" count={users.length} offset={[-3, 20]}>
            <Button
              className="EmojiButton"
              icon={<Emoji emoji={{ id: r.emojiId }} set="google" size={20} />}
              onClick={() => onSelectReaction(message, { id: r.emojiId })}
            ></Button>
          </Badge>
        </Tooltip>
      );
    });
  };

  const renderReactionOverlay = (message) => {
    return (
      <div className="ReactionOverlay">
        <EmojiPopover placement="left" onSelect={(emoji) => onSelectReaction(message, emoji)}>
          <Button className="AddReactionButton" icon={<VscReactions />}></Button>
        </EmojiPopover>
        {renderReactions(message)}
      </div>
    );
  };

  const onSelectGif = (gif) => {
    sendChat(rtdb, campaign, character, {
      type: 'gif',
      gif,
      sender: getName(),
    });
    setShowGiphyModal(false);
  };

  const renderGiphyModal = () => {
    return (
      <Modal footer={null} centered destroyOnClose visible={showGiphyModal} onCancel={() => setShowGiphyModal(false)}>
        <div className="GiphyModalContent">
          <GiphySearchbox
            autoFocus
            apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
            onSelect={(item) => onSelectGif(item)}
            masonryConfig={[{ columns: 4, imageWidth: 110, gutter: 5 }]}
          />
        </div>
      </Modal>
    );
  };

  return (
    <div className="Chat">
      {!hideChat && (
        <>
          <DiceSelector
            visible={showDiceModal}
            setVisible={setShowDiceModal}
            sendRoll={(result) => {
              if (result) {
                sendChat(rtdb, campaign, character, {
                  type: 'roll',
                  result,
                  sender: getName(),
                });
              }
            }}
          />
          {renderDetailsModal()}
          {renderGiphyModal()}
          <div className="Messages">
            <List
              itemLayout="horizontal"
              dataSource={messages}
              renderItem={(item) => (
                <List.Item>
                  <div className="MessageContent">
                    {renderChatMessage(item, messages.indexOf(item))}
                    {renderReactionOverlay(item)}
                  </div>
                </List.Item>
              )}
            />
          </div>
          <div className="Buttons">
            <EmojiPopover placement="left" onSelect={(emoji) => setMessage(`${message} ${emoji.native} `)}>
              <Button icon={<VscSmiley />}></Button>
            </EmojiPopover>
            <Button icon={<AiOutlineGif />} onClick={() => setShowGiphyModal(true)}></Button>
            <Button icon={<GiRollingDices />} onClick={() => setShowDiceModal(true)}></Button>
          </div>
          <div className="Controls">
            <Input
              name="message"
              className="NewMessage"
              autoComplete="off"
              value={message}
              placeholder={`${getName()} says...`}
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={() => sendChatMessage(message)}
              suffix={
                <Button
                  type="text"
                  shape="circle"
                  className="SendButton"
                  style={!message ? { display: 'none' } : {}}
                  onClick={() => sendChatMessage(message)}
                >
                  <IoIosArrowDropright />
                </Button>
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Chat;
