import React, { useEffect } from 'react';
import { useAtom } from 'jotai';

import { userAtom, characterListAtom, dmSelectedCharacterAtom } from '../../utils/atoms';
import CharacterSelect from './CharacterSelect';
import CharacterView from './CharacterView';
import './Character.css';

const Character = ({ rtdb, auth, ...rest }) => {
  const [user] = useAtom(userAtom);
  const [, setCharacterList] = useAtom(characterListAtom);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  // listen for character list
  React.useEffect(() => {
    const fetchCharData = async () => {
      if (!user.characters || user.characters.length === 0) {
        setCharacterList([]);
      } else {
        const chars = await Promise.all(
          user.characters.map(async (charId) => {
            const charEntry = await rtdb.ref(`characters/${charId}`).get();
            if (charEntry.exists()) {
              return { ...charEntry.val(), id: charId };
            }
            return null;
          })
        );
        setCharacterList(chars.filter((char) => !!char));
      }
    };
    fetchCharData();
  }, [rtdb, user.characters, setCharacterList]);

  const childProps = {
    rtdb,
    auth,
    ...rest,
  };
  return (
    <div className="Character" key="Character">
      {(user && user.selectedCharacter) || dmSelectedCharacter ? (
        <CharacterView {...childProps} />
      ) : (
        <CharacterSelect {...childProps} />
      )}
    </div>
  );
};

export default Character;
