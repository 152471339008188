import React from 'react';
import { AutoSizer, Grid } from 'react-virtualized';
import { useAtom } from 'jotai';
import { Button } from 'antd';

import { mapsAtom, adjustGridAtom, zoomAtom } from '../../utils/atoms';
import { URLS } from '../../config';
import './MapGridAdjust.css';

const AdjustCell = ({ columnIndex, rowIndex, style, key }) => <div className="AdjustCell" style={style} key={key} />;

const MapGridAdjust = ({ rtdb, mapId }) => {
  const [maps] = useAtom(mapsAtom);
  const [zoom] = useAtom(zoomAtom);
  const [, setAdjustingGrid] = useAtom(adjustGridAtom);

  const map = maps && maps[mapId];

  const [columns, setColumns] = React.useState(map.width || 20);
  const [rows, setRows] = React.useState(map.height || 20);
  const [mapZoom, setMapZoom] = React.useState(map.zoom || 1);

  const gridRef = React.useRef();

  const columnWidth = Math.round(map.mapWidth / columns);
  const rowHeight = Math.round(map.mapHeight / rows);

  const cellWidth = zoom * columnWidth;
  const cellHeight = zoom * rowHeight;

  // recalculate grid size when cell size changes
  React.useEffect(() => {
    if (cellWidth && cellHeight && gridRef && gridRef.current) {
      gridRef.current.recomputeGridSize();
    }
  }, [gridRef, cellWidth, cellHeight]);

  if (!map || !map.grid) return null;

  if (!map.mapWidth || !map.mapHeight) return 'loading';

  const onSaveGrid = () => {
    rtdb.ref(`maps/${mapId}`).update({
      width: columns,
      height: rows,
      zoom: mapZoom,
      cellWidth: columnWidth,
      cellHeight: rowHeight,
    });
    setAdjustingGrid(false);
  };

  const onSaveZoom = () => {
    setMapZoom(zoom);
  }

  const recomputeGridSize = () => {
    if (gridRef && gridRef.current) gridRef.current.recomputeGridSize();
  };

  const renderAdjustOverlay = () => {
    return (
      <div className="AdjustOverlay">
        <div className="Heading">
          <h2>Adjust the map grid</h2>
        </div>
        <div className="Row">
          <Button
            onClick={() => {
              setRows(rows - 1);
              recomputeGridSize();
            }}
          >
            Taller
          </Button>
        </div>
        <div className="Row">
          <div className="Col">
            <Button
              onClick={() => {
                setColumns(columns + 1);
                recomputeGridSize();
              }}
            >
              Narrower
            </Button>
          </div>
          <div className="Col Center">
            <div>Adjust Squares</div>
            <div>
              {columns} x {rows}
            </div>
          </div>
          <div className="Col">
            <Button
              onClick={() => {
                setColumns(columns - 1);
                recomputeGridSize();
              }}
            >
              Wider
            </Button>
          </div>
        </div>
        <div className="Row">
          <Button
            onClick={() => {
              setRows(rows + 1);
              recomputeGridSize();
            }}
          >
            Shorter
          </Button>
        </div>
        <div className="Row">
          <div className="Zoom">
            Default zoom: {(mapZoom * 100).toFixed(0)}%
          </div>
        </div>
        <div className="Row">
          <Button size="large" onClick={onSaveZoom}>
            Set default zoom to current zoom
          </Button>
        </div>
        <div className="Footer">
          <Button size="large" onClick={onSaveGrid}>
            Save and continue
          </Button>
        </div>
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => (
        <div className="MapGridAdjust" id="mapGridAdjust" style={{ width, height }}>
          <Grid
            ref={gridRef}
            columnCount={columns}
            columnWidth={cellWidth}
            height={height}
            rowCount={rows}
            rowHeight={cellHeight}
            width={width}
            overscanColumnCount={20}
            overscanRowCount={20}
            containerStyle={{
              backgroundImage: `url(${URLS.bucket}/${map.image})`,
              backgroundSize: `${cellWidth * columns}px ${cellHeight * rows}px`,
            }}
            cellRenderer={AdjustCell}
          />
          {renderAdjustOverlay()}
        </div>
      )}
    </AutoSizer>
  );
};

export default MapGridAdjust;
