import React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { useAtom } from 'jotai';

import { userAtom, characterAtom, dmSelectedCharacterAtom } from '../../utils/atoms';
import { URLS } from '../../config';
import './CharacterImage.css';

const CharacterImage = ({ rtdb, storage }) => {
  const [user] = useAtom(userAtom);
  const [character] = useAtom(characterAtom);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const selectedCharacter = dmSelectedCharacter || user.selectedCharacter;

  const [isUploading, setIsUploading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
  };

  const handleProgress = (progress) => setProgress(progress);

  const handleUploadError = (error) => {
    setIsUploading(false);
    console.error(error);
  };

  const handleUploadSuccess = (filename) => {
    setIsUploading(false);
    setProgress(100);
    rtdb.ref(`characters/${selectedCharacter}/image`).set(`icons/${filename}`);
  };

  const hasImage = character && !!character.image;
  let className = 'CharacterImageUpload';
  if (hasImage) className += ' ImageUploaded';

  return (
    <div className="CharacterImage">
      <div className={className}>
        <label>
          {hasImage ? (
            <img alt={character.name} src={`${URLS.bucket}/${character.image}`} />
          ) : (
            'Click to upload an image for your character'
          )}

          <FileUploader
            hidden
            randomizeFilename
            accept="image/*"
            storageRef={storage.ref('icons')}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
          />
        </label>
      </div>
    </div>
  );
};

export default CharacterImage;
