import React from 'react';
import { Button, Form, Input, Modal, Collapse, Popconfirm } from 'antd';
import { useAtom } from 'jotai';
import { FaDiceD20 } from 'react-icons/fa';
import { BsPlusCircle } from 'react-icons/bs';

import { rollAttack } from '../../utils/db';
import { withSign } from '../../utils/utils';
import { parseDice } from '../../utils/dice';
import {
  userAtom,
  selectedCampaignAtom,
  characterAtom,
  characterAttacksAtom,
  dmSelectedCharacterAtom,
} from '../../utils/atoms';
import { sortList, ATTACK_SORT_OPTIONS } from '../../utils/sort';
import SortMenu from '../../components/SortMenu';
import './CharacterAttacks.css';

const CharacterAttacks = ({ rtdb }) => {
  const [user] = useAtom(userAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [character] = useAtom(characterAtom);
  const [attacks] = useAtom(characterAttacksAtom);
  const [attackForm] = Form.useForm();
  const [attackModal, setAttackModal] = React.useState(null);
  const [sort, setSort] = React.useState(ATTACK_SORT_OPTIONS[1]);
  const [dmSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const selectedCharacter = dmSelectedCharacter || user.selectedCharacter;

  const onSubmit = (values) => {
    if (attackModal === 'new') {
      rtdb
        .ref(`character_attacks/${selectedCharacter}/${attacks.length}`)
        .set(values)
        .then(() => {
          attackForm.resetFields();
          setAttackModal(null);
        });
    } else if (attackModal < attacks.length) {
      rtdb
        .ref(`character_attacks/${selectedCharacter}/${attackModal}`)
        .set(values)
        .then(() => {
          attackForm.resetFields();
          setAttackModal(null);
        });
    }
  };

  const onRollAttack = (e, attack) => {
    e.stopPropagation();
    rollAttack(rtdb, campaign, character, attack);
  };

  const onEdit = (i) => {
    attackForm.setFieldsValue(attacks[i]);
    setAttackModal(i);
  };

  const onDelete = (i) => {
    rtdb.ref(`character_attacks/${selectedCharacter}/${i}`).remove();
  };

  const validateDamage = (_, value) => {
    const dice = parseDice(value);
    if (!dice) return Promise.reject(new Error('Invalid dice string'));
    return Promise.resolve();
  };

  const renderAttacksList = () => {
    const sorted = sortList(attacks, sort);
    const getIndex = (attack) => attacks.indexOf(attack);
    return (
      <Collapse>
        {sorted.map(
          (attack, i) =>
            attack && (
              <Collapse.Panel
                header={
                  <div className="AttackHeader">
                    <div className="AttackName">{attack.attackName}</div>
                    <div className="AttackBonus">{withSign(attack.hitBonus)}</div>
                    <div className="AttackDamage">{attack.damage}</div>
                    <div className="AttackDamageType">{attack.damageType}</div>
                    <Button icon={<FaDiceD20 />} onClick={(e) => onRollAttack(e, attack)}>
                      Roll attack
                    </Button>
                  </div>
                }
                key={i}
              >
                <div className="AttackContent">
                  {attack.description && <div className="AttackDescription">{attack.description}</div>}
                  <Button type="link" onClick={() => onEdit(getIndex(attack))}>
                    Edit
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this attack?"
                    onConfirm={() => onDelete(getIndex(attack))}
                    okText="Delete"
                    cancelText="Cancel"
                  >
                    <Button type="link">Delete</Button>
                  </Popconfirm>
                </div>
              </Collapse.Panel>
            )
        )}
      </Collapse>
    );
  };

  const renderAttackModal = () => {
    return (
      <Modal
        title={attackModal === 'new' ? 'New attack' : 'Edit attack'}
        footer={null}
        centered
        visible={attackModal !== null}
        onCancel={() => {
          attackForm.resetFields();
          setAttackModal(null);
        }}
      >
        <div className="AttackModalContent">
          <Form name="attack" form={attackForm} onFinish={onSubmit}>
            <Form.Item
              label="Attack name"
              name="attackName"
              initialValue=""
              rules={[{ required: true, message: 'Enter a name' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Bonus to hit"
              name="hitBonus"
              initialValue=""
              rules={[{ required: true, message: 'Enter a bonus' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Damage"
              name="damage"
              initialValue=""
              rules={[{ required: true, message: 'Enter damage dice' }, { validator: validateDamage }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Damage type"
              name="damageType"
              initialValue=""
              rules={[{ required: true, message: 'Enter a damage type' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" initialValue="">
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <div className="ModalButtonWrapper">
                <Button size="large" htmlType="submit">
                  {attackModal === 'new' ? 'Create' : 'Save'}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  };

  return (
    <div className="Attacks">
      <SortMenu options={ATTACK_SORT_OPTIONS} sort={sort} setSort={setSort} />
      <div className="AttacksList">
        {attacks.length ? renderAttacksList() : <div className="Empty">You don't have any attacks yet</div>}
      </div>
      <div className="ButtonWrapper">
        <Button
          type="link"
          size="large"
          className="NewButton"
          icon={<BsPlusCircle />}
          onClick={() => setAttackModal('new')}
        >
          New attack
        </Button>
      </div>
      {renderAttackModal()}
    </div>
  );
};

export default CharacterAttacks;
