import React from 'react';
import { Button } from 'antd';
import { useAtom } from 'jotai';
import { useParams, useHistory } from 'react-router-dom';

import { userAtom, selectedCampaignAtom } from '../../utils/atoms';
import './DMInvite.css';

const DMInvite = ({ rtdb, auth }) => {
  const [user] = useAtom(userAtom);
  const [, setCampaign] = useAtom(selectedCampaignAtom);
  const [dmInviteCode, setDmInviteCode] = React.useState();
  const [submitted, setSubmitted] = React.useState(false);

  const { id } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    rtdb
      .ref('meta/dmInvite')
      .get()
      .then((dmInviteEntry) => {
        if (dmInviteEntry.exists()) {
          setDmInviteCode(dmInviteEntry.val());
        } else {
          setDmInviteCode(null);
        }
      });
  }, [rtdb]);

  const goToCampaigns = () => {
    setCampaign(null);
    history.push('/');
  };

  const onAcceptInvite = async () => {
    setSubmitted(true);
    await rtdb.ref(`users/${auth.user.uid}/canCreateCampaign`).set(true);
    goToCampaigns();
  };

  return (
    <div className="DMInvite">
      {user && user.canCreateCampaign ? (
        <div>
          <div>You can already create campaigns</div>
          <Button onClick={goToCampaigns}>Go to campaigns</Button>
        </div>
      ) : id && id === dmInviteCode ? (
        <div>
          <div>You have been invited to be a DM and create your own campaign(s)</div>
          <Button onClick={onAcceptInvite} disabled={submitted}>
            Accept invite
          </Button>
        </div>
      ) : (
        dmInviteCode !== undefined && (
          <div>
            <div>This invite code is expired or invalid</div>
            <Button onClick={goToCampaigns}>Go back</Button>
          </div>
        )
      )}
    </div>
  );
};

export default DMInvite;
