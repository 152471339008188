import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { useAtom } from 'jotai';

import { selectedCampaignAtom } from '../../utils/atoms';
import Login from '../Login';
import './Invite.css';

const Invite = ({ rtdb, auth, ...rest }) => {
  const { id } = useParams();
  const history = useHistory();
  const [, setCampaign] = useAtom(selectedCampaignAtom);
  const [campaignData, setCampaignData] = React.useState();
  const [invites, setInvites] = React.useState();
  const [submitted, setSubmitted] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);

  React.useEffect(() => {
    if (!auth.user) return;
    const fetchCampaignData = async () => {
      const campaignEntry = await rtdb.ref(`campaigns/${id}`).get();
      if (campaignEntry.exists()) {
        setCampaignData(campaignEntry.val());
      }
    };
    fetchCampaignData();
  }, [rtdb, id, auth]);

  React.useEffect(() => {
    if (!auth.user) return;
    rtdb.ref(`users/${auth.user.uid}/campaignInvites`).on('value', (snapshot) => {
      if (snapshot.exists()) {
        setInvites(snapshot.val());
      } else {
        setInvites([]);
      }
    });

    return () => {
      rtdb.ref(`users/${auth.user.uid}/campaignInvites`).off();
    };
  }, [auth, rtdb]);

  const onJoin = () => {
    setSubmitted(true);
    rtdb.ref(`users/${auth.user.uid}/campaignInvites/${invites.length}`).set(id);
    rtdb.ref(`campaign_players/${id}`).push(auth.user.displayName || 'Unknown');
  };

  const onJoinAsGuest = () => {
    setSubmitted(true);
    auth.signInAnonymously().then(() => {
      setCampaign(id);
      history.push('/');
    });
  };

  const onGoToCampaign = () => {
    setCampaign(id);
    history.push('/');
  };

  const childProps = {
    ...rest,
    auth,
    rtdb,
  };

  return (
    <div className="Invite">
      {auth.user && campaignData && invites && (
        <>
          <h1>{campaignData.name}</h1>
          <h3>has invited you</h3>
          {invites.includes(id) ? (
            <>
              <Button size="large" disabled>
                You have joined this campaign
              </Button>
              <Button size="large" onClick={onGoToCampaign}>
                Go to campaign
              </Button>
            </>
          ) : (
            <Button size="large" onClick={onJoin} disabled={submitted}>
              Join Campaign
            </Button>
          )}
        </>
      )}
      {!auth.user && (
        <>
          <h1>You have been invited to join a campaign</h1>
          <h3>but you are not logged in</h3>
          <Button size="large" onClick={() => setShowLogin(true)}>
            Log in
          </Button>
          <Button size="large" onClick={onJoinAsGuest} disabled={submitted}>
            Join as a guest
          </Button>
          <Modal title="Log in" footer={null} visible={showLogin} onCancel={() => setShowLogin(false)}>
            <Login {...childProps} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default Invite;
