import React from 'react';
import { Modal, Button } from 'antd';
import {
  GiDiceTwentyFacesTwenty,
  GiD12,
  GiD10,
  GiDiceEightFacesEight,
  GiPerspectiveDiceSix,
  GiD4,
  GiCrownCoin,
} from 'react-icons/gi';

import { parseDice, rollDice } from '../utils/dice';
import './DiceSelector.css';

const DiceSelector = ({ visible, setVisible, sendRoll }) => {
  const [d20, setD20] = React.useState(0);
  const [d12, setD12] = React.useState(0);
  const [d10, setD10] = React.useState(0);
  const [d8, setD8] = React.useState(0);
  const [d6, setD6] = React.useState(0);
  const [d4, setD4] = React.useState(0);
  const [d2, setD2] = React.useState(0);
  const [bonus, setBonus] = React.useState(0);

  const onClose = () => {
    setVisible(false);
    setD20(0);
    setD12(0);
    setD10(0);
    setD8(0);
    setD6(0);
    setD4(0);
    setD2(0);
    setBonus(0);
  };

  const onRoll = () => {
    let diceStr = '';
    if (d20) diceStr += `${d20}d20 `;
    if (d12) diceStr += `${d12}d12 `;
    if (d10) diceStr += `${d10}d10 `;
    if (d8) diceStr += `${d8}d8 `;
    if (d6) diceStr += `${d6}d6 `;
    if (d4) diceStr += `${d4}d4 `;
    if (d2) diceStr += `${d2}d2 `;
    if (bonus) diceStr += `${bonus}`;
    if (diceStr) {
      const diceArray = parseDice(diceStr.trim());
      const result = rollDice(diceArray);
      sendRoll(result);
    }
  };

  const renderRow = (label, count, setCount, icon) => {
    return (
      <div className="Row">
        <div className="Label">
          {count} {label}
        </div>
        {icon}
        <div className="Buttons">
          <Button disabled={count === 0} onClick={() => setCount(count - 1)}>
            -
          </Button>
          <Button onClick={() => setCount(count + 1)}>+</Button>
        </div>
      </div>
    );
  };
  return (
    <Modal visible={visible} onCancel={onClose} footer={null} centered>
      <div className="DiceSelector">
        {renderRow('D20', d20, setD20, <GiDiceTwentyFacesTwenty />)}
        {renderRow('D12', d12, setD12, <GiD12 />)}
        {renderRow('D10', d10, setD10, <GiD10 />)}
        {renderRow('D8', d8, setD8, <GiDiceEightFacesEight />)}
        {renderRow('D6', d6, setD6, <GiPerspectiveDiceSix />)}
        {renderRow('D4', d4, setD4, <GiD4 />)}
        {renderRow('D2', d2, setD2, <GiCrownCoin />)}
        <div className="Row">
          <div className="BonusLabel">
            {bonus < 0 ? '' : '+'}
            {bonus} Bonus
          </div>
          <div className="Buttons">
            <Button onClick={() => setBonus(bonus - 1)}>-</Button>
            <Button onClick={() => setBonus(bonus + 1)}>+</Button>
          </div>
        </div>
        <Button size="large" className="RollButton" onClick={onRoll}>
          Roll
        </Button>
      </div>
    </Modal>
  );
};

export default DiceSelector;
