import React from 'react';
import { map } from 'lodash';
import { useAtom } from 'jotai';
import { Button, List, Avatar, Input, Popconfirm } from 'antd';
import { BsPlusCircle } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

import SortMenu from '../../components/SortMenu';
import { CHARACTER_SORT_OPTIONS, sortList } from '../../utils/sort';
import { userAtom, characterListAtom, dmSelectedCharacterAtom, isDMAtom } from '../../utils/atoms';
import { delay } from '../../utils/utils';
import { URLS } from '../../config';
import './CharacterSelect.css';

const CharacterSelect = ({ rtdb, auth }) => {
  const [user] = useAtom(userAtom);
  const [characterList] = useAtom(characterListAtom);
  const [isDM] = useAtom(isDMAtom);
  const [, setDMSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const [filter, setFilter] = React.useState();
  const [sort, setSort] = React.useState(CHARACTER_SORT_OPTIONS[0]);

  const onNewCharacter = async () => {
    const pushed = await rtdb.ref('characters').push({ name: 'New Adventurer', type: 'player' });
    const characters = user.characters || [];
    const userUpdate = {
      characters: [...characters, pushed.key],
    };
    if (!isDM) userUpdate.selectedCharacter = pushed.key;
    else setDMSelectedCharacter(pushed.key);
    rtdb.ref(`users/${auth.user.uid}`).update(userUpdate);
  };

  const onSelectCharacter = (i) => {
    if (isDM) {
      setDMSelectedCharacter(user.characters[i]);
    } else {
      rtdb.ref(`users/${auth.user.uid}/selectedCharacter`).set(user.characters[i]);
    }
  };

  const onDeleteCharacter = (i) => {
    if (characterList[i] && window.confirm('Really really?')) {
      const { id } = characterList[i];
      rtdb.ref(`users/${auth.user.uid}/characters`).set(user.characters.filter((c) => c !== id));
      rtdb.ref(`characters/${id}`).remove();
    }
  };

  const filterCharacters = (charArray) => {
    if (!charArray || !filter) return charArray;
    return charArray.filter((m) => m.name.toLowerCase().includes(filter.toLowerCase()));
  };

  const renderCharacterList = () => {
    const charArray = map(characterList, (m, id) => ({ ...m, id }));
    const filtered = filterCharacters(charArray);
    const sorted = sortList(filtered, sort);
    return (
      <div className="List">
        <div className="CharacterSearch">
          <Input
            allowClear
            placeholder="Type here to filter characters by name"
            onChange={delay((e) => {
              setFilter(e.target.value);
            }, 250)}
          />
        </div>
        <SortMenu options={CHARACTER_SORT_OPTIONS} sort={sort} setSort={setSort} />
        {sorted && sorted.length ? (
          <List
            itemLayout="horizontal"
            dataSource={sorted}
            renderItem={(character) => {
              return (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar size={80} src={character.image ? `${URLS.thumbs128}/${character.image}` : ''} />}
                    title={character.name}
                    description={
                      <div className="Col">
                        <div className="Row">
                          {character.classLevel && <div className="RowItem">{character.classLevel}</div>}
                          {character.race && <div className="RowItem">{character.race}</div>}
                        </div>
                        <div className="Row">
                          <Button className="SelectButton" size="large" onClick={() => onSelectCharacter(character.id)}>
                            Select
                          </Button>
                          <Popconfirm
                            title={`Are you sure you want to delete ${character.name}?`}
                            okText="Delete"
                            cancelText="Cancel"
                            onConfirm={() => onDeleteCharacter(character.id)}
                          >
                            <Button className="DeleteButton" size="large" icon={<FaTrash />}></Button>
                          </Popconfirm>
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        ) : (
          <i>You have no characters yet</i>
        )}
      </div>
    );
  };

  return (
    <div className="CharacterSelect">
      <h1>Select a character</h1>
      {renderCharacterList()}
      <Button type="link" size="large" icon={<BsPlusCircle />} onClick={onNewCharacter}>
        Start a new character
      </Button>
    </div>
  );
};

export default CharacterSelect;
