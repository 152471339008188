import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { Button, Popover, Dropdown, Menu, Tooltip, Modal, Form, InputNumber, Badge, Avatar, Popconfirm } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useDrag } from 'react-dnd';
import { useAtom } from 'jotai';
import {
  GiHearts,
  GiCheckedShield,
  GiHeartPlus,
  GiHeartMinus,
  GiTemporaryShield,
  GiHearingDisabled,
  GiTiredEye,
  GiScreaming,
  GiCurledTentacle,
  GiBackPain,
  GiNinjaHead,
  GiSkeletonInside,
  GiStoneBlock,
  GiPoisonBottle,
  GiHalfBodyCrawling,
  GiImprisoned,
  GiSurprised,
  GiCrossedBones,
  GiBleedingWound,
  GiChoppedSkull,
  GiMinions,
} from 'react-icons/gi';
import { BsFillEmojiHeartEyesFill, BsCheck } from 'react-icons/bs';
import { FaLowVision, FaDizzy, FaDiceD20 } from 'react-icons/fa';
import { ImEyeBlocked } from 'react-icons/im';

import { URLS } from '../../config';
import {
  userAtom,
  isDMAtom,
  dropObjectAtom,
  selectedCampaignAtom,
  dmSelectedCharacterAtom,
  characterAtom,
} from '../../utils/atoms';
import { capitalizeFirst, withSign } from '../../utils/utils';
import { parseDice, rollDice } from '../../utils/dice';
import { sendChat } from '../../utils/db';
import './MapObject.css';

const PopoverButton = ({ object, width, height, onSelect }) => {
  return (
    <Popover
      overlayClassName="MapObjectPopover"
      placement="top"
      content={<div className="PopoverContent">{object.name}</div>}
      getPopupContainer={() => document.getElementById('mapGrid')}
    >
      <Badge count={object.hidden ? <ImEyeBlocked style={{ color: 'gold', fontSize: '1.5em' }} /> : 0} offset={[-5, 5]}>
        <Avatar
          className="ObjectButton"
          onClick={onSelect}
          onDragStart={onSelect}
          src={object.image ? `${URLS.thumbs64}/${object.image}` : ''}
          size={Math.min(Number(width), Number(height))}
          style={{ opacity: object.hidden ? 0.25 : 1 }}
        />
      </Badge>
    </Popover>
  );
};

const TooltipButton = ({ title, icon, onClick }) => (
  <Tooltip placement="bottom" title={title}>
    <Button className="TooltipButton" icon={icon} onClick={onClick} />
  </Tooltip>
);

const MapObject = ({
  rtdb,
  auth,
  object,
  x,
  y,
  width,
  height,
  animationObjects,
  setAnimationObjects,
  popoverObjects,
  setObjectIndex,
  removeObject,
  updateObject,
}) => {
  const [user] = useAtom(userAtom);
  const [isDM] = useAtom(isDMAtom);
  const [, setCharacter] = useAtom(characterAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [, setDropObject] = useAtom(dropObjectAtom);
  const [dmSelectedCharacter, setDMSelectedCharacter] = useAtom(dmSelectedCharacterAtom);

  const [showPopover, setShowPopover] = React.useState(false);
  const [showChooserPopover, setShowChooserPopover] = React.useState(false);
  const [healthModal, setHealthModal] = React.useState();
  const [npcModal, setNpcModal] = React.useState();
  const [healthForm] = Form.useForm();

  // set up drag zone
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'obj',
      item: object,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [object]
  );

  const hp = Number(object.hp);
  const maxhp = Number(object.maxhp);
  const isBloodied = maxhp && hp < maxhp / 2;
  const isUnconscious = maxhp && hp <= 0;
  const isDead = maxhp && hp <= -maxhp;

  const isAllowedMinion = object.type === 'minion' && object.ownerCharacterId === user.selectedCharacter;
  const isAllowedBasicNPC = isDM && object.type === 'basic';
  const hasObjectPermissions =
    isDM || object.characterId === user.selectedCharacter || object.ownerCharacterId === user.selectedCharacter;
  const isDMCharacter = object.type === 'npc' && object.characterId;

  const avatarSize = Math.min(Number(width), Number(height));
  const avatarImage = object.image
    ? avatarSize <= 64
      ? `${URLS.thumbs64}/${object.image}`
      : `${URLS.thumbs128}/${object.image}`
    : '';

  const duration = 200;
  let dx = 0,
    dy = 0;

  const prev = animationObjects[object.id];
  if (prev && prev.x !== undefined && prev.y !== undefined && width && height) {
    dx = (prev.x - x) * width;
    dy = (prev.y - y) * height;
  }

  const defaultStyle = {
    transition: `transform ${duration}ms ease-in, opacity ${duration}ms ease`,
    transform: `translate(${dx}px,${dy}px)`,
    opacity: 0.25,
  };

  const transitionStyles = {
    entering: { transform: `translate(${dx}px,${dy}px)`, opacity: 0.25 },
    entered: { transform: 'translate(0px,0px)', opacity: 1 },
  };

  let buttonClass = 'ObjectButton';
  if (object.hidden) buttonClass += ' Hidden';

  const onDragStart = () => {
    setShowPopover(false);
    setShowChooserPopover(false);
  };

  const onClick = () => {
    if (isAllowedMinion || isAllowedBasicNPC) setNpcModal(true);
    if (isDM) {
      if (isDMCharacter) {
        setDMSelectedCharacter(object.id);
      } else {
        setDMSelectedCharacter();
        setCharacter();
      }
    }
  };

  const onModifyHealth = (values) => {
    let hp = Number(object.hp);
    let temphp = Number(object.temphp) || 0;
    const maxhp = Number(object.maxhp);
    const entered = Number(values.health);

    switch (healthModal) {
      case 'set':
        hp = Math.min(entered, maxhp);
        break;
      case 'inc':
        hp = Math.min(hp + entered, maxhp);
        break;
      case 'dec':
        if (temphp) {
          if (entered <= temphp) {
            temphp -= entered;
          } else {
            hp -= entered - temphp;
            temphp = 0;
          }
        } else {
          hp -= entered;
        }
        break;
      case 'temp':
        temphp += entered;
        break;
      default:
    }

    hp = `${hp}`;
    temphp = `${temphp}`;

    if (hp !== object.hp || temphp !== object.temphp) {
      if (object.characterId) {
        rtdb.ref(`characters/${object.characterId}`).update({ hp, temphp });
        if (object.characterId !== user.selectedCharacter && object.characterId !== dmSelectedCharacter) {
          updateObject(x, y, object.id, { hp, temphp });
        }
      } else {
        updateObject(x, y, object.id, { hp, temphp });
      }
    }

    setHealthModal(undefined);
    healthForm.resetFields();
  };

  const toggleHidden = () => {
    updateObject(x, y, object.id, { hidden: !object.hidden });
  };

  const toggleStatus = (condition) => {
    let statuses = object.statuses || [];
    if (object.statuses && object.statuses.includes(condition)) statuses = statuses.filter((s) => s !== condition);
    else statuses = [...statuses, condition];
    updateObject(x, y, object.id, { statuses });
  };

  const onRollStat = (bonus, stat) => {
    const dice = parseDice(`1d20${bonus === 0 ? '' : ` ${bonus}`}`);
    const result = rollDice(dice);
    sendChat(rtdb, campaign, object, {
      type: 'stat',
      stat,
      result,
      bonus: withSign(bonus),
    });
  };

  const mainPopoverContent = (
    <div className="PopoverContent ObjectPopoverContent">
      <div className="Name">{object.name}</div>
      {object.title && <div className="Title">{object.title}</div>}
      {isAllowedMinion && <div className="Conditions">Your minion</div>}
      {object.statuses && object.statuses.length && (
        <div className="Conditions">{object.statuses.map((s) => capitalizeFirst(s)).join(', ')}</div>
      )}
      {isDead ? (
        <div className="Conditions">Dead</div>
      ) : isUnconscious ? (
        <div className="Conditions">Unconscious</div>
      ) : isBloodied ? (
        <div className="Conditions">Bloodied</div>
      ) : null}
      {hasObjectPermissions && (
        <>
          <div className="Stats">
            {!!object.maxhp && (
              <div className="Health">
                <div className="Icon">
                  <GiHearts />
                </div>
                <div className="Values">
                  <div className="Top">
                    {object.hp}
                    {object.temphp && Number(object.temphp) ? ` (+${object.temphp})` : ''}
                  </div>
                  <div className="Bottom">{object.maxhp}</div>
                </div>
              </div>
            )}
            {!!object.ac && (
              <div className="AC">
                <div className="Icon">
                  <GiCheckedShield />
                </div>
                <div className="Values">
                  <div className="Top">AC</div>
                  <div className="Bottom">{object.ac}</div>
                </div>
              </div>
            )}
          </div>
          {!!object.maxhp && (
            <div className="Buttons">
              <TooltipButton
                title="Set HP"
                icon={<GiHearts />}
                onClick={() => {
                  setShowPopover(false);
                  setHealthModal('set');
                }}
              />
              <TooltipButton
                title="Increase HP"
                icon={<GiHeartPlus />}
                onClick={() => {
                  setShowPopover(false);
                  setHealthModal('inc');
                }}
              />
              <TooltipButton
                title="Decrease HP"
                icon={<GiHeartMinus />}
                onClick={() => {
                  setShowPopover(false);
                  setHealthModal('dec');
                }}
              />
              <TooltipButton
                title="Add temporary HP"
                icon={
                  <GiTemporaryShield
                    onClick={() => {
                      setShowPopover(false);
                      setHealthModal('temp');
                    }}
                  />
                }
              />
            </div>
          )}
          {isDM && !!object.maxhp && object.hp <= 0 && object.hp > -object.maxhp && (
            <div className="Execute">
              <Button
                className="WideButton"
                type="primary"
                danger
                icon={<GiChoppedSkull />}
                onClick={() => {
                  updateObject(x, y, object.id, { hp: -object.maxhp, temphp: 0 });
                }}
              >
                Execute
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );

  const withObjectPopover = (children) => {
    return (
      <Popover
        overlayClassName="MapObjectPopover"
        placement="bottom"
        content={mainPopoverContent}
        getPopupContainer={() => document.getElementById('mapGrid')}
        visible={showPopover}
        onVisibleChange={(visible) => setShowPopover(visible)}
      >
        {children}
      </Popover>
    );
  };

  const withChooserPopover = (children) => (
    <Popover
      overlayClassName="MapObjectPopover"
      placement="top"
      content={
        <div className="PopoverContent">
          {popoverObjects.map((o, i) => (
            <PopoverButton
              key={`popover-${o.id}`}
              object={o}
              width={width}
              height={height}
              onSelect={() => {
                setObjectIndex(i);
                setShowChooserPopover(false);
              }}
            />
          ))}
        </div>
      }
      visible={showChooserPopover}
      onVisibleChange={(visible) => setShowChooserPopover(visible)}
      getPopupContainer={() => document.getElementById('mapGrid')}
    >
      {children}
    </Popover>
  );

  const getConditionMenuItem = (condition) => (
    <div className="ConditionMenuItem">
      {object.statuses && object.statuses.includes(condition) && <BsCheck />}
      {capitalizeFirst(condition)}
    </div>
  );

  const withContextMenu = (children) => {
    const options = [];

    if (isDM) {
      options.push({
        label: object.hidden ? 'Reveal to players' : 'Hide from players',
        onClick: () => {
          toggleHidden();
        },
      });

      options.push({
        label: 'Conditions',
        options: [
          {
            label: 'Clear all',
            onClick: () => updateObject(x, y, object.id, { statuses: [] }),
          },
          {
            label: getConditionMenuItem('blinded'),
            onClick: () => toggleStatus('blinded'),
          },
          {
            label: getConditionMenuItem('charmed'),
            onClick: () => toggleStatus('charmed'),
          },
          {
            label: getConditionMenuItem('deafened'),
            onClick: () => toggleStatus('deafened'),
          },
          {
            label: getConditionMenuItem('exhausted'),
            onClick: () => toggleStatus('exhausted'),
          },
          {
            label: getConditionMenuItem('frightened'),
            onClick: () => toggleStatus('frightened'),
          },
          {
            label: getConditionMenuItem('grappled'),
            onClick: () => toggleStatus('grappled'),
          },
          {
            label: getConditionMenuItem('incapacitated'),
            onClick: () => toggleStatus('incapacitated'),
          },
          {
            label: getConditionMenuItem('invisible'),
            onClick: () => toggleStatus('invisible'),
          },
          {
            label: getConditionMenuItem('paralyzed'),
            onClick: () => toggleStatus('paralyzed'),
          },
          {
            label: getConditionMenuItem('petrified'),
            onClick: () => toggleStatus('petrified'),
          },
          {
            label: getConditionMenuItem('poisoned'),
            onClick: () => toggleStatus('poisoned'),
          },
          {
            label: getConditionMenuItem('prone'),
            onClick: () => toggleStatus('prone'),
          },
          {
            label: getConditionMenuItem('restrained'),
            onClick: () => toggleStatus('restrained'),
          },
          {
            label: getConditionMenuItem('stunned'),
            onClick: () => toggleStatus('stunned'),
          },
          {
            label: getConditionMenuItem('unconscious'),
            onClick: () => toggleStatus('unconscious'),
          },
          {
            label: 'Clear all',
            onClick: () => updateObject(x, y, object.id, { statuses: [] }),
          },
        ],
      });

      options.push({
        label: 'Make a copy',
        onClick: () => {
          setDropObject({
            ...object,
            id: uuidv4(),
          });
        },
      });
    }

    if (isDM || object.characterId === user.selectedCharacter || object.ownerCharacterId === user.selectedCharacter) {
      options.push({
        label: 'Remove',
        confirm: true,
        confirmTitle: `Are you sure you want to remove ${object.name}?`,
        confirmButton: 'Remove',
        onClick: () => {
          removeObject(x, y, object.id);
        },
      });
    }

    const menu = options.length ? (
      <Menu>
        {options.map((o, i) => {
          if (o.options)
            return (
              <Menu.SubMenu key={`${i}`} title={o.label}>
                {o.options.map((so, j) => (
                  <Menu.Item key={`sub${j}`} onClick={so.onClick}>
                    {so.label}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          if (o.confirm)
            return (
              <Popconfirm
                key={`pop${i}`}
                title={o.confirmTitle}
                onConfirm={o.onClick}
                okText={o.confirmButton}
                cancelText="Cancel"
              >
                <Menu.Item key={`${i}`}>{o.label}</Menu.Item>
              </Popconfirm>
            );
          return (
            <Menu.Item key={`${i}`} onClick={o.onClick}>
              {o.label}
            </Menu.Item>
          );
        })}
      </Menu>
    ) : (
      <div />
    );
    return (
      <Dropdown overlay={menu} trigger={['contextMenu']}>
        {children}
      </Dropdown>
    );
  };

  const withBadge = (children) => {
    const badge =
      popoverObjects && popoverObjects.length > 1 ? (
        popoverObjects.length
      ) : object.hidden ? (
        <ImEyeBlocked style={{ color: 'gold', fontSize: '1.5em' }} />
      ) : (
        0
      );
    return (
      <Badge count={badge} offset={[-5, 5]}>
        {children}
      </Badge>
    );
  };

  const getStatusIcon = (status, angle) => {
    const commonStyle = { transform: `rotate(${angle}rad)` };
    switch (status) {
      case 'blinded':
        return <FaLowVision style={{ ...commonStyle, color: 'darkorange', fontSize: `${0.425 * avatarSize}px` }} />;
      case 'charmed':
        return (
          <BsFillEmojiHeartEyesFill style={{ ...commonStyle, color: 'magenta', fontSize: `${0.45 * avatarSize}px` }} />
        );
      case 'deafened':
        return (
          <GiHearingDisabled style={{ ...commonStyle, color: 'darkorange', fontSize: `${0.45 * avatarSize}px` }} />
        );
      case 'exhausted':
        return <GiTiredEye style={{ ...commonStyle, color: 'lavender', fontSize: `${0.525 * avatarSize}px` }} />;
      case 'frightened':
        return <GiScreaming style={{ ...commonStyle, color: 'magenta', fontSize: `${0.475 * avatarSize}px` }} />;
      case 'grappled':
        return <GiCurledTentacle style={{ ...commonStyle, color: 'yellow', fontSize: `${0.45 * avatarSize}px` }} />;
      case 'incapacitated':
        return <GiBackPain style={{ ...commonStyle, color: 'yellow', fontSize: `${0.475 * avatarSize}px` }} />;
      case 'invisible':
        return <GiNinjaHead style={{ ...commonStyle, color: 'whitesmoke', fontSize: `${0.45 * avatarSize}px` }} />;
      case 'paralyzed':
        return <GiSkeletonInside style={{ ...commonStyle, color: 'yellow', fontSize: `${0.475 * avatarSize}px` }} />;
      case 'petrified':
        return <GiStoneBlock style={{ ...commonStyle, color: 'lightgrey', fontSize: `${0.45 * avatarSize}px` }} />;
      case 'poisoned':
        return <GiPoisonBottle style={{ ...commonStyle, color: 'lawngreen', fontSize: `${0.525 * avatarSize}px` }} />;
      case 'prone':
        return <GiHalfBodyCrawling style={{ ...commonStyle, color: 'yellow', fontSize: `${0.475 * avatarSize}px` }} />;
      case 'restrained':
        return <GiImprisoned style={{ ...commonStyle, color: 'yellow', fontSize: `${0.475 * avatarSize}px` }} />;
      case 'stunned':
        return <GiSurprised style={{ ...commonStyle, color: 'yellow', fontSize: `${0.475 * avatarSize}px` }} />;
      case 'unconscious':
        return <FaDizzy style={{ ...commonStyle, color: 'orangered', fontSize: `${0.45 * avatarSize}px` }} />;
      default:
        return null;
    }
  };

  const renderStatuses = () => {
    if (!object.statuses || !object.statuses.length) return null;
    const rx = 360 / object.statuses.length;
    const statuses = object.statuses.map((s, i) => {
      const h = avatarSize * 0.7;
      const angle = i * rx * (Math.PI / 180);
      const sin = Math.sin(angle);
      const cos = Math.cos(angle);
      const dx = Math.round(h * sin);
      const dy = Math.round(h * cos);
      return (
        <div key={s} className="Status" style={{ transform: `translate(${dx}px, ${dy}px)` }}>
          <div className="StatusInner">{getStatusIcon(s, angle)}</div>
        </div>
      );
    });
    return <div className="StatusOverlay">{statuses}</div>;
  };

  const renderHealthStatus = () => {
    if (!object.maxhp) return null;
    if (!isBloodied && !isUnconscious && !isDead) return null;
    let className = 'HealthStatusOverlay';
    if (isDead || isUnconscious) className += ' Background';
    return (
      <div className={className}>
        {isDead ? (
          <GiCrossedBones style={{ color: 'whitesmoke', fontSize: `${0.9 * avatarSize}px` }} />
        ) : isUnconscious ? (
          <FaDizzy style={{ color: 'orangered', fontSize: `${0.6 * avatarSize}px` }} />
        ) : (
          <GiBleedingWound style={{ color: 'red', fontSize: `${0.75 * avatarSize}px` }} />
        )}
      </div>
    );
  };

  const renderMinionOverlay = () => {
    if (!isAllowedMinion) return null;
    return (
      <div className="MinionOverlay">
        <GiMinions style={{ fontSize: `${0.4 * avatarSize}px` }} />
      </div>
    );
  };

  let objectClassName = 'MapObject';
  if (object.size) {
    const sizeLowerCase = object.size.toLowerCase();
    if (sizeLowerCase.startsWith('t')) objectClassName += ' Tiny';
    else if (sizeLowerCase.startsWith('s')) objectClassName += ' Small';
    else if (sizeLowerCase.startsWith('l')) objectClassName += ' Large';
    else if (sizeLowerCase.startsWith('h')) objectClassName += ' Huge';
    else if (sizeLowerCase.startsWith('g')) objectClassName += ' Gargantuan';
  }

  const content = (
    <div className={objectClassName}>
      {withContextMenu(
        popoverObjects
          ? withChooserPopover(
              withObjectPopover(
                withBadge(
                  <Avatar
                    className={buttonClass}
                    ref={drag}
                    onDragStart={onDragStart}
                    onClick={onClick}
                    src={avatarImage}
                    size={avatarSize}
                    style={{ opacity: isDragging ? 0.25 : 1 }}
                  />
                )
              )
            )
          : withObjectPopover(
              withBadge(
                <Avatar
                  className={buttonClass}
                  ref={drag}
                  onDragStart={onDragStart}
                  onClick={onClick}
                  src={avatarImage}
                  size={avatarSize}
                  style={{ opacity: isDragging ? 0.25 : 1 }}
                />
              )
            )
      )}
      {renderHealthStatus()}
      {renderStatuses()}
      {renderMinionOverlay()}
    </div>
  );

  const renderHealthModal = () => {
    const getTitle = () => {
      switch (healthModal) {
        case 'set':
          return 'Set health';
        case 'inc':
          return 'Increase health';
        case 'dec':
          return 'Decrease health';
        case 'temp':
          return 'Add temporary health';
        default:
          return '';
      }
    };
    const getLabel = () => {
      switch (healthModal) {
        case 'set':
          return 'Set HP to';
        case 'inc':
          return 'Increase HP by';
        case 'dec':
          return 'Decrease HP by';
        case 'temp':
          return 'Add temporary HP';
        default:
          return '';
      }
    };
    return (
      <Modal
        centered
        destroyOnClose
        width={320}
        footer={null}
        visible={!!healthModal}
        onCancel={() => {
          setHealthModal(undefined);
          healthForm.resetFields();
        }}
      >
        <Form name="healthForm" form={healthForm} onFinish={onModifyHealth}>
          <div className="HealthModalContent">
            <Form.Item label="Current health">
              {object.hp}
              {object.temphp ? ` (+${object.temphp})` : ''} / {object.maxhp}
            </Form.Item>
            <div className="Spacer" />
            <Form.Item label={getLabel()} name="health" rules={[{ required: true, message: 'Enter a number' }]}>
              <InputNumber autoFocus />
            </Form.Item>
            <div className="ModalButtonWrapper">
              <Button size="large" htmlType="submit">
                {getTitle()}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  };

  const renderRollButton = (label, stat) => {
    const bonus = Number(object[`${stat}Bonus`]) || 0;
    return (
      <Button className="RollButton" type="link" onClick={() => onRollStat(bonus, label)}>
        <div className="RollButtonContent">
          <FaDiceD20 />
          {label} ({withSign(bonus)})
        </div>
      </Button>
    );
  };

  const renderNpcModal = () => {
    return (
      <Modal
        centered
        footer={null}
        visible={!!npcModal}
        title={object.name}
        onCancel={() => {
          setNpcModal(undefined);
        }}
      >
        <div className="NpcMapModalContent">
          <div className="Row">
            <Avatar shape="square" size={110} src={object.image ? `${URLS.thumbs128}/${object.image}` : ''} />
            <div className="Col">
              <div className="Row">
                {renderRollButton('Strength', 'str')}
                {renderRollButton('Dexterity', 'dex')}
                {renderRollButton('Constitution', 'con')}
              </div>
              <div className="Row">
                {renderRollButton('Intelligence', 'int')}
                {renderRollButton('Wisdom', 'wis')}
                {renderRollButton('Charisma', 'cha')}
              </div>
            </div>
          </div>
          {object.otherInfo && (
            <div className="Row">
              <div className="OtherInfo">{object.otherInfo}</div>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  return (
    <>
      {renderHealthModal()}
      {renderNpcModal()}
      <TransitionGroup>
        <Transition
          key={object.id}
          appear={!!dx || !!dy}
          exit={false}
          timeout={duration}
          onEntered={() => {
            if (animationObjects[object.id]) {
              const newAnimObjects = { ...animationObjects };
              delete newAnimObjects[object.id];
              setAnimationObjects(newAnimObjects);
            }
          }}
        >
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              {content}
            </div>
          )}
        </Transition>
      </TransitionGroup>
    </>
  );
};

export default MapObject;
