import React from 'react';
import { useAtom } from 'jotai';

import Map from './Map/Map';
import Campaign from './Campaign/Campaign';
import Character from './Character/Character';
import Journal from './Journal';
import Knowledge from './Knowledge';
import { selectedCampaignAtom, campaignAtom, pageAtom, isDMAtom } from '../utils/atoms';
import './PageSection.css';

const PageSection = ({ rtdb, auth, ...rest }) => {
  const [page] = useAtom(pageAtom);
  const [campaign] = useAtom(selectedCampaignAtom);
  const [, setCampaignData] = useAtom(campaignAtom);
  const [, setIsDM] = useAtom(isDMAtom);

  const Page = (props) => {
    return (
      <div className="Page" style={props.page !== props.name ? { display: 'none' } : {}}>
        {props.children}
      </div>
    );
  };

  React.useEffect(() => {
    rtdb.ref(`campaigns/${campaign}`).on('value', (campaignEntry) => {
      if (campaignEntry.exists()) {
        const data = campaignEntry.val();
        setCampaignData(data);
        if (data.dmUsers && data.dmUsers.some((user) => user === auth.user.uid)) {
          setIsDM(true);
        }
      }
    });

    return () => {
      rtdb.ref(`campaigns/${campaign}`).off();
    };
  }, [rtdb, auth, campaign, setCampaignData, setIsDM]);

  const childProps = {
    ...rest,
    rtdb,
    auth,
  };

  return (
    <div className="PageSection" key="PageSection">
      <Page name="map" page={page}>
        <Map {...childProps} />
      </Page>
      <Page name="character" page={page}>
        <Character {...childProps} />
      </Page>
      <Page name="journal" page={page}>
        <Journal {...childProps} />
      </Page>
      <Page name="knowledge" page={page}>
        <Knowledge {...childProps} />
      </Page>
      <Page name="campaign" page={page}>
        <Campaign {...childProps} />
      </Page>
    </div>
  );
};

export default PageSection;
