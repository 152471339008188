import React from 'react';

import TopButtons from '../components/TopButtons';
import PageSection from '../pages/PageSection';
import Chat from '../components/Chat';
import './MainLayout.css';

const MainLayout = (props) => {
  const [hideChat, setHideChat] = React.useState(false);
  return (
    <div className="MainLayout">
      <div className="TopBar">
        <TopButtons {...props} />
      </div>
      <div className="Page">
        <div className={hideChat ? 'LeftNoChat' : 'Left'}>
          <PageSection {...props} />
        </div>
        <div className={hideChat ? 'RightHidden' : 'Right'}>
          <Chat {...props} hideChat={hideChat} setHideChat={setHideChat} />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
