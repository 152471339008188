import { Form, Input, Button } from 'antd';

import './Login.css';

const Login = ({ auth, fb, rtdb, setUser }) => {
  const onFinish = (values) => {
    // if (this.state.newUser) {
    //   auth
    //     .createUserWithEmailAndPassword(values.email, values.password)
    //     .then(() => this.finalizeNewUser(values));
    // } else {
    return auth.signInWithEmailAndPassword(values.email, values.password);
    // .then(async userCredential => {
    //   const { user } = userCredential;
    //   const userEntry = await rtdb.ref(`users/${user.uid}`).get();
    //   if (userEntry.exists()) {
    //     setUser(userEntry.val());
    //   } else {
    //     setUser({});
    //   }
    // });
    // }
  };

  // const finalizeNewUser = values => {
  //   fb.auth().currentUser.updateProfile({
  //     displayName: values.displayName || 'Anonymous'
  //   });
  //   fb.auth().currentUser.sendEmailVerification();
  // };

  // const anonymousLogin = () => {
  //   auth.signInAnonymously().catch(() => {
  //     // log.info(err);
  //   });
  // };

  return (
    <div className="Login">
      <Form
        name="login"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Enter your email' }]}>
          <Input />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Enter your password' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button htmlType="submit">Log in</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
